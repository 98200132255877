export const ESTRAININGELEMENT = {
    "EMPHASIS_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_strength.gif",
        "text": "Enfoque en fuerza",
        "description": "Cuando veas este símbolo deberías correr con resistencia alta a una cadencia de 50 a 70 RPM. El terreno accidentado o montañoso es ideal para esto."
    },
    "EMPHASIS_CLIMBING": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_climbing.gif",
        "text": "Montaña",
        "description": "Entrenamiento en terreno montañoso. Incluyendo subidas en tu entrenamiento, te acostumbrarás a esta carga específica y desarrollarás tu fuerza dentro del movimiento específico."
    },
    "EMPHASIS_CADENCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_cadence.gif",
        "text": "Cadencia Alta",
        "description": "Ejercicios de velocidad. Intente hacer los intervalos programados o, idealmente, todo el entrenamiento a una cadencia que se sienta \"incómodamente\" alta. Si esto es demasiado extenuante, alterne los intervalos de alta cadencia con la recuperación. La alta frecuencia desarrolla sus habilidades de coordinación y es la base para un aumento en la potencia de salida / velocidad. Ciclistas: 100-120 rpm (revoluciones por minuto), más aún por períodos cortos. Corredores:> 90 pasos por minuto."
    },
    "TECHNIQUE_FOCUS": {
        "image": "https://2peak.com/images/peak_icons/training_content/technique_focus.gif",
        "text": "Técnica",
        "description": "El entrenamiento técnico aumenta su eficiencia motora. Ejecute el movimiento conscientemente y concéntrese en segmentos individuales lentamente. Haga que la calidad del movimiento sea el objetivo principal del entrenamiento."
    },
    "STRETCH": {
        "image": "https://2peak.com/images/peak_icons/training_content/stretch.gif",
        "text": "estiramiento",
        "description": "Estirarse después del entrenamiento mejora y acorta la recuperación."
    },
    "FARTLEK": {
        "image": "https://2peak.com/images/peak_icons/training_content/fartlek.gif",
        "text": "Fartlek",
        "description": "Entrenamiento de forma libre (Fartlek significa Juegos de velocidad) en el que 'juegas' en el paisaje a diferentes intensidades y trabajas a la velocidad que desees. Los intervalos libres se alternan con la resistencia básica. El terreno montañoso con muchos cambios de dirección es ideal: fuerce el ritmo cuesta arriba, acelere cuesta abajo y, de lo contrario, solo navegue."
    },
    "PYRAMID": {
        "image": "https://2peak.com/images/peak_icons/training_content/pyramid.gif",
        "text": "Pirámide",
        "description": "Secuencia de intervalos - con aumento - luego duración decreciente"
    },
    "PULL_BUOY": {
        "image": "https://2peak.com/images/peak_icons/training_content/pull_buoy.gif",
        "text": "Pull buoy",
        "description": "Concéntrese en los brazos usando la boya para sostener las piernas."
    },
    "PADDLES": {
        "image": "https://2peak.com/images/peak_icons/training_content/paddles.gif",
        "text": "Palas",
        "description": "Ejercicios de fuerza para los brazos con paletas, principalmente en combinación con la boya para la estabilización. El objetivo aquí es aumentar la resistencia en la mano y así tener una idea de 'agarrar' y mover el agua con cada golpe. La velocidad no es más alta de lo habitual: los brazos funcionan a una frecuencia más baja."
    },
    "KICK_BOARD": {
        "image": "https://2peak.com/images/peak_icons/training_content/kick_board.gif",
        "text": "Pies",
        "description": "Ejercicios para las piernas con la tabla como ayuda flotante para la parte superior del cuerpo. Se usa tanto en posición boca abajo como en posición supina. Concéntrate en patear desde la cadera."
    },
    "WETSUIT": {
        "image": "https://2peak.com/images/peak_icons/training_content/wetsuit.gif",
        "text": "Neopreno",
        "description": "Entrenamiento con un traje de neopreno cuando sea posible en aguas abiertas. Para familiarizarse con la mayor flotabilidad y mejorar la orientación en el agua."
    },
    "SPRINT_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_climb.gif",
        "text": "Subidas",
        "description": "Este ejercicio de sprint mejora tu máximo. fuerza y aceleración"
    },
    "SPRINT_DESCEND": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_descend.gif",
        "text": "Bajadas",
        "description": "Usa la energía potencial de la pendiente para aumentar la aceleración. Mejora tu velocidad máxima y máxima y cadencia. El gradiente te obliga a girar más rápido de lo normal. Comience en un gradiente moderado."
    },
    "SPINT_DESCEND_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/spint_descend_climb.gif",
        "text": "Sprint en subida",
        "description": "Este ejercicio se ejecuta en una pendiente descendente donde aceleras a tu velocidad máxima. Cuando vuelva a hacer la transición cuesta arriba, deje su cadena en el plato grande e intente mantener la velocidad y la cadencia, incluso cuando la carga aumenta y las piernas duelen. Esto mejora su tolerancia láctica y su fuerza de voluntad. Los ciclistas de montaña se benefician particularmente de esto en senderos sencillos, salidas de carreras y escaladas técnicas. Los corredores de carretera pueden manejar mejor esos contrapesos dolorosos que ocurren en descensos largos. Se entrena la capacidad de amortiguar el ácido láctico durante las aceleraciones."
    },
    "TRACKTRAINING": {
        "image": "https://2peak.com/images/peak_icons/training_content/tracktraining.gif",
        "text": "Entrenamiento en pista",
        "description": "La pista es ideal para entrenamientos de intervalos intensivos, ya que puede trabajar con distancias precisas. Los ejercicios técnicos también se realizan mejor aquí, aprovechando la superficie blanda o el césped (mejor) cuando estén disponibles."
    },
    "RUNNING_TECHNIQUE": {
        "image": "https://2peak.com/images/peak_icons/training_content/running_technique.gif",
        "text": "Entrenamiento de velocidad",
        "description": "Los ejercicios de carrera se utilizan para ejercitar la coordinación neuromuscular y la capacidad estática / mecánica. La inclusión de ejercicios de carrera en su rutina de entrenamiento regular conduce a un estilo de carrera más dinámico y eficiente."
    },
    "SOFTSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/softsurface.gif",
        "text": "Superficie blanda",
        "description": "Correr con frecuencia sobre superficies suaves y desiguales hace que muchos músculos menores de los pies y las piernas ganen fuerza, lo que a su vez ayuda a reducir las lesiones. También correr en superficies blandas y correr es menos estresante para su cuerpo, lo que a su vez ayuda a una recuperación rápida."
    },
    "HARDSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/hardsurface.gif",
        "text": "Superficie dura",
        "description": "Correr sobre una superficie dura ayuda a tu cuerpo a adaptarse al estrés en las carreras en asfalto."
    },
    "OPEN_WATER": {
        "image": "https://2peak.com/images/peak_icons/training_content/open_water.gif",
        "text": "Aguas abiertas",
        "description": "Nadar en aguas abiertas con o sin traje de neopreno. Como triatleta, debes practicar natación en aguas abiertas para perfeccionar tus habilidades de orientación y estar tranquilo sin esa línea negra de la piscina ..."
    },
    "CIRCUIT_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_strength.gif",
        "text": "Circuito de fuerza",
        "description": "Ejercicios de fuerza aeróbica en forma de circuito. Los ejercicios seleccionados (por ejemplo, una secuencia de sentadillas, extensión de piernas y flexiones de piernas) se alternan con un ejercicio principal de traducción deportiva. Los ejercicios se realizan con un peso muy bajo pero con 30-40 repeticiones sin pausa entre ejercicios. La traducción en el deporte principal es de 3-4 minutos de trabajo de resistencia en la bicicleta estacionaria o en la cinta de correr con intensidad Z2-Z3. Después del último circuito, pase 10 minutos en la bicicleta estacionaria, girando a alta cadencia: <br> <br> Ejemplo: 10 'girando en Z2 a 90 rpm + 10' ejercicios de fuerza del núcleo + 4 circuitos seguidos de una carrera en cinta rodante de 3 ' con intensidad Z2-3 + 5-10 'ejercicios de fuerza central + 10' bicicleta giratoria en Z2 a 90-100 rpm."
    },
    "CIRCUIT_ENDURANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_endurance.gif",
        "text": "Circuito de resistencia",
        "description": "Esta forma de entrenamiento de fuerza del circuito, utiliza su peso corporal para los ejercicios. Combine la coordinación con el trabajo de fuerza aeróbica utilizando ejercicios simples, como flexiones, saltos con mínimo contacto con el suelo, saltos alargados, sentadillas, etc. o use la descripción en> Tutoriales en el menú> Herramientas. El ejercicio se rota en cada minuto completo. <br> <br> Ejemplo: 6 x (ejercicio de 30 segundos / recuperación de 30 segundos): un circuito completo se repite 4-6 veces."
    },
    "TORSO": {
        "image": "https://2peak.com/images/peak_icons/training_content/torso.gif",
        "text": "Fuerza Principal",
        "description": "Para evitar el desequilibrio muscular, se deben entrenar los grupos musculares del tronco central."
    },
    "LANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/lance.gif",
        "text": "agencia alta en subidas",
        "description": "Avance rápido: entrenamiento de escalada con soporte para el brazo."
    },
    "SKIING_XC_1_1": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_1.gif",
        "text": "Esqui de fondo",
        "description": "1 : 1"
    },
    "SKIING_XC_1_2_ASYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_asymmetric.gif",
        "text": "Esqui",
        "description": "1: 2 asimétrico"
    },
    "SKIING_XC_1_2_SYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_symmetric.gif",
        "text": "Esqui",
        "description": "1: 2 simétrico"
    },
    "MP_TEST": {
        "image": "https://2peak.com/images/peak_icons/training_content/mp_test.gif",
        "text": "Test Fitness",
        "description": "Hoy es un día ideal para una prueba de esfuerzo. Si decide renunciar a la prueba, entrene en la zona de resistencia base durante la duración propuesta."
    },
    "RESPIRATION": {
        "image": "https://2peak.com/images/peak_icons/training_content/respiration.gif",
        "text": "Respiración",
        "description": "Ejercicios especiales para mejorar sus músculos respiratorios (se requiere apoyo técnico)."
    },
    "MENTAL": {
        "image": "https://2peak.com/images/peak_icons/training_content/mental.gif",
        "text": "Mental",
        "description": "Entrenamiento mental. Una vez que esté físicamente listo, es la fuerza de voluntad la que marca la diferencia. El cerebro es uno de nuestros \"músculos\" más fuertes e importantes y necesita tiempo y entrenamiento para mejorar: fuerza de voluntad, capacidad intelectual y emociones."
    }
}