import { convertLengthToJson, getLengthSummary, Length, LengthUnit } from "./Length"

export interface IntervalPart {
  length: Length
  zone: string
  specificZoneValue?: number|Length
  type?: string
}

export interface Interval {
  work: IntervalPart
  rest: IntervalPart
  comment: string
}
export interface Block {
  discriminator: "IM-A-BLOCK"
  addDuration: number
  sets: number
  reps: number
  intervals: Interval[]
  afterRest: Length
  betweenSetsRest: Length
}

export function convertZoneToJson(z: string): string {
  if (z === "Z4") {
    return z + "_HIGH"
  }
  return z
}

export function convertIntervalPartToJson(ip: IntervalPart) {
  return {
    length: convertLengthToJson(ip.length),
    zone: convertZoneToJson(ip.zone),
    specificZoneValue: ip.specificZoneValue,
    type: ip.type,
  }
}

export function convertIntervalToJson(interval: Interval) {
  return {
    work: convertIntervalPartToJson(interval.work),
    rest: convertIntervalPartToJson(interval.rest),
    comment: interval.comment,
  }
}

export function convertBlockToJson(block: Block) {
  return {
    sets: block.sets,
    reps: block.reps,
    intervals: block.intervals.map(it => convertIntervalToJson(it)),
    afterRest: convertLengthToJson(block.afterRest),
    betweenSetsRest: convertLengthToJson(block.betweenSetsRest),
  }
}

export function getBlockSummary(block: Block) {
  let summary = ""

  if (block.sets > 1) {
    summary += `${block.sets}x`
  }

  if (block.reps == 0)
    summary += `1(`
  else
    summary += `${block.reps}(`


  for (let i = 0; i < block.intervals.length; i++) {
    summary += `${getLengthSummary(block.intervals[i].work.length)}`
    if (!block.intervals[i].rest.type) {
        summary += `@${block.intervals[i].work.zone} `
      if (block.reps != 0)
        summary += ` | `
    } else {
      if (block.intervals[i].rest.type[0] === "R") {
        summary += `@${getLengthSummary(<Length> block.intervals[i].work.specificZoneValue)}`
      }
        summary += ` | ${block.intervals[i].rest.type[0]}:`
    }
    if (block.reps != 0)
      summary += `${getLengthSummary(block.intervals[i].rest.length)}`
    if (block.intervals[i].rest.zone !== `Z0` && block.intervals[i].rest.zone && block.reps != 0) {
      summary += `@${block.intervals[i].rest.zone}`
    }

    if (i < block.intervals.length - 1) {
      summary += " | "
    }
  }

  return `${summary})`
}
