import { Component, OnInit, OnDestroy } from "@angular/core"
import { StorageService } from "../_services/storage/storage.service"
import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import { AlertController } from '@ionic/angular';
import { ApiService } from "../_services/api/api.service"
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-sections',
  templateUrl: './my-sections.page.html',
  styleUrls: ['./my-sections.page.scss'],
})
export class MySectionsPage implements OnInit, OnDestroy {

  
  user: any
  loading = false
  mySectionArr:any = [];
  leaderboardArr:any = [];

  isMysection:boolean = true;
  isLeaderborad:boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private storage: StorageService,
    private sanitizer: DomSanitizer,
    public alertController: AlertController,
  ) {

  }

  ngOnInit() {
    //this.dummySection();
  }

  async ionViewWillEnter(){
    this.user = await this.storage.getUser();
    if(this.user){
      this.getLeaderboard()
      this.getAthleteSections();
    }
  }

  dummySection(){
    this.api.getSectionDetail(43007).then(res => {
      console.log("respois is", res)
    })
    .catch(err => {
      this.loading = false;
      console.log("Something wrong.")
    })
  }

  getAthleteSections(){
    this.loading = true;
    this.api.getAthleteSection().then(res => {
      this.loading = false;
      this.mySectionArr = res;
    })
    .catch(err => {
      this.loading = false;
      console.log("Something wrong.")
    })
  }

  getLeaderboard(){
    this.loading = true;
    this.api.getLeaderboard().then(res => {
      this.loading = false;
      this.leaderboardArr = res;
      //console.log("this.leaderboard Arr is", res)
      /*if(this.leaderboardArr){
         let startCords = [parseFloat(this.leaderboardArr.start_latitude), parseFloat(this.leaderboardArr.start_longitude)]
        let endCords = [parseFloat(this.sectionDetail[0].end_latitude), parseFloat(this.sectionDetail[0].end_longitude)]
        this.loadMap(startCords, endCords, this.sectionDetail[0].coordinates_array);
      }*/
    })
    .catch(err => {
      this.loading = false;
      console.log("Something wrong.")
    })
  }



  segmentChanged(val){
    if(val=='isMysection'){
      this.isMysection = true;
      this.isLeaderborad = false;
    }else{
      this.isMysection = false;
      this.isLeaderborad = true;
    }
  }

  mySectionDetail(id){
    this.router.navigate([`/my-sections/detail/${id}`]);
  }

  ngOnDestroy() {
    //this.map.remove();
  }

}
