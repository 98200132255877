import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router"
import { CoachPage } from './coach.page';

const routes: Routes = [
  {
    path: '',
    component: CoachPage
  },
  { 
    path: 'coach/planning',
    loadChildren: () => import('./planning/planning.module').then(res => res.PlanningPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class CoachPageRoutingModule {}
