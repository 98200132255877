export const DETRAININGELEMENT = {
    "EMPHASIS_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_strength.gif",
        "text": "Kraftorientiert",
        "description": "Wenn dieses Zeichen erscheint, sollte eine größere Übersetzung bei einer Trittfrequenz von 40 bis 60 Umdrehungen pro Minute gewählt werden. Hügelige oder bergige Strecken bieten sich besonders an."
    },
    "EMPHASIS_CLIMBING": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_climbing.gif",
        "text": "Am Berg",
        "description": "Training im bergigen Gelände. Dies dient der Gewöhnung an diese Form der Belastung und bringt einen höheren Kraftanteil mit sich."
    },
    "EMPHASIS_CADENCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_cadence.gif",
        "text": "Frequenzorientiert",
        "description": "Schnelligkeitstraining. Absolvieren Sie das angezeigte Intervall, oder noch besser das gesamte Training mit erhöhter Tritt- oder Schrittfrequenz. Ist dies zu anstregend, wechseln sie Schnelligkeitsintervalle mit Erholungsphasen. Schnelligkeit ist die Grundlage für eine Leistungsverbesserung im Spitzenbereich. Zielbereich Radfahrer: 100-120 U/min, kurzfristig auch mehr. Zielbereich Läufer: >90 Schritte pro Minute."
    },
    "TECHNIQUE_FOCUS": {
        "image": "https://2peak.com/images/peak_icons/training_content/technique_focus.gif",
        "text": "Technik",
        "description": "Techniktraining erhöht die Bewegungseffizienz. Führen Sie Bewegungen sehr bewusst und abschnittsweise auch verlangsamt aus, um ein Gefühl für die korrekte Ausführung zu erlangen. Machen Sie die Qualität der Bewegung zum Fokus der Trainingseinheit."
    },
    "STRETCH": {
        "image": "https://2peak.com/images/peak_icons/training_content/stretch.gif",
        "text": "Stretching",
        "description": "Stretching nach dem Training verbessert bzw verkürzt die Regenerationszeit"
    },
    "FARTLEK": {
        "image": "https://2peak.com/images/peak_icons/training_content/fartlek.gif",
        "text": "FARTLEK",
        "description": "Freie Trainingsform, Spiel mit der Geschwindigkeit, bei der Gelände, Lust und Laune das Tempo vorgeben. Frei gesetzte Intervalle wechseln sich mit Grundlagentempo ab. Ideal in coupiertem Gelände: bergauf mit Kraft forcieren, bergab schnell und sonst locker laufen."
    },
    "PYRAMID": {
        "image": "https://2peak.com/images/peak_icons/training_content/pyramid.gif",
        "text": "PYRAMID",
        "description": "Block von Intervallen mit zunehmender und anschließend wieder abnehmender Länge."
    },
    "PULL_BUOY": {
        "image": "https://2peak.com/images/peak_icons/training_content/pull_buoy.gif",
        "text": "Pull Buoy",
        "description": "Gezieltes Armtraining mit der Auftriebshilfe für die Beine."
    },
    "PADDLES": {
        "image": "https://2peak.com/images/peak_icons/training_content/paddles.gif",
        "text": "Paddles",
        "description": "Paddles sind Krafttraining für die Arme - meistens im Kombination mit den Pull Buoys. Ziel ist den Widerstand für die Arme zu erhöhen und ein Gefühl dafür zu bekommen, möglichst viel Wasser mit jedem Zug zu `packen`. Die Geschwindigkeit ist nicht höher als sonst, weil die Arme mit geringerer Frequenz arbeiten."
    },
    "KICK_BOARD": {
        "image": "https://2peak.com/images/peak_icons/training_content/kick_board.gif",
        "text": "Kick Board",
        "description": "Gezieltes Beintraining mit der Auftriebshilfe für den Oberkörper. Wird in Bauch- und Rückenlage verwendet. Ziel ist den Beinschlag aus der Hüfte zu erlernen."
    },
    "WETSUIT": {
        "image": "https://2peak.com/images/peak_icons/training_content/wetsuit.gif",
        "text": "Neo",
        "description": "Training mit Neoprenanzug, idealerweise im offenen Gewässer. Dient der Gewöhnung an den Neo und der Orientierung draußen."
    },
    "SPRINT_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_climb.gif",
        "text": "Bergauf Sprint",
        "description": "Der Bergauf Sprint betont die Maximalkraft und steigert die Beschleunigungsfähigkeit."
    },
    "SPRINT_DESCEND": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_descend.gif",
        "text": "Bergab Sprint",
        "description": "Der Bergab Sprint nutzt die passive Beschleunigung durch das Gefälle und erzwingt die schnellere Ausführung von Bewegungen. Beginnen Sie mit leichten Gefällen. "
    },
    "SPINT_DESCEND_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/spint_descend_climb.gif",
        "text": "Bergauf Sprint",
        "description": "Gut in Bergsenken möglich, vor denen voll beschleunigt wird. Geht es wieder bergauf, Kette auf dem großen Gang stehen lassen. Versuchen Sie, die hohe Geschwindigkeit und Trittfrequenz möglichst lange beizubehalten, auch wenn der Widerstand steigt und die Beine zu schmerzen beginnen. Das schult die Laktattoleranz und Willensstärke. Insbesondere Mountainbiker profitieren davon auf Singletrails und technischen Anstiegen. Straßenfahrer hilft diese Übung bei Gegenanstiegen."
    },
    "TRACKTRAINING": {
        "image": "https://2peak.com/images/peak_icons/training_content/tracktraining.gif",
        "text": "Bahntraining",
        "description": "Die 400m Rundbahn eignet sich hervorragend für intensive Intervalltrainings, da die Distanzen korrekt abgemessen sind. Auch Laufschule/Lauf ABC können hervorragend auf der weichen Rundbahn gemacht werden."
    },
    "RUNNING_TECHNIQUE": {
        "image": "https://2peak.com/images/peak_icons/training_content/running_technique.gif",
        "text": "Laufschule",
        "description": "Durch das Lauf ABC werden mit gezielten koordinativen und technischen Übungen die mechanischen und koordinativen Fähigkeiten im Laufen gefördert. Mittels regelmässiger Laufschule im Training wird ein dynamischer und effizienter Laufstil erreicht."
    },
    "SOFTSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/softsurface.gif",
        "text": "weicher Boden",
        "description": "Durch das Laufen auf weichem unebenen Untergrund werden die Fuss- und Beinmuskulatur besser ausgebildet und die Gelenke gleichzeitig geschont."
    },
    "HARDSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/hardsurface.gif",
        "text": "harter Boden",
        "description": "Durch das Laufen auf hartem Untergrund wird der Körper auf die wettkampfmässige Belastung vorbereitet, welche oft auf dem Asphalt stattfinden."
    },
    "OPEN_WATER": {
        "image": "https://2peak.com/images/peak_icons/training_content/open_water.gif",
        "text": "offenes Wasser",
        "description": "Schwimmen im offenen Gewässer mit oder ohne Neoprenanzug. Jeder Triathlet sollte regelmässig im offenen Wasser schwimmen, um seinen Orientierungssinn zu schulen und seine Sicherheit im offenen Gewässer zu finden."
    },
    "CIRCUIT_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_strength.gif",
        "text": "Kraft Circuit",
        "description": "Kraftausdauer Training in Form eines Circuit Rundgangs mit wenigen Uebungen (z.B. Kniebeugen, Beinbeuger, Beinstrecker) und direkten Umsetzen nach jedem Durchgang. Die Uebungen werden jeweils mit wenig Gewicht und 30-40 Wiederholungen ohne Pause zwischen den einzelnen Geräten gemacht. Die direkte Umsetzung des Kraftreizes erfolgt für 3-4min. auf dem Ergometerrad oder auf dem Laufband mit einer Intensität von GA1-GA2. Nach dem letzten Circuit Durchgang sollten mindestens 10min auf dem Ergometerrad mit hoher Trittfrequenz ausgefahren werden:<br><br>Beispiel: 10min GA1, TF 90 einfahren + 10' Rumpfkräftigung + 4 Circuit Durchgänge mit anschließendem 3min GA1-2, TF 100 umsetzen + 5 -10min. Rumpfkräftigung + 10min GA1, TF 90-100 ausfahren"
    },
    "CIRCUIT_ENDURANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_endurance.gif",
        "text": "Ausdauer Circuit",
        "description": "Diese Form des Circuit Trainings unterscheidet sich vor allem darin, dass alle Übungen mit dem eigenen Körpergewicht gemacht werden. Es sollen vor allem Kraftausdauer und Koordination mit einfachen Übungen trainiert werden. Übungen wie z.B. Liegestützen, Hüpfen an Ort mit kurzem Bodenkontakt, Rumpfkräftigung, Strecksprünge, etc.  oder Übungen auf dem Ball (siehe Bildstrecke unter > Anleitungen im Menü > Tools), gehören in diese Übungsform. Die Übung wechselt immer auf die volle Minute.<br><br>Beispiel: 6x (30sec Belastung / 30sec Erholung) – dieser Circuit wird 4-6x wiederholt."
    },
    "TORSO": {
        "image": "https://2peak.com/images/peak_icons/training_content/torso.gif",
        "text": "Rumpftraining",
        "description": "Zum Ausgleich von muskulären Dysbalancen ist ein ausgewogenes Training der Rumpfmuskulatur notwendig."
    },
    "LANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/lance.gif",
        "text": "Lance-Stil Spinning",
        "description": "Wiegetritt mit Armstützhaltung"
    },
    "SKIING_XC_1_1": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_1.gif",
        "text": "Langlauf 1:1",
        "description": "1 :1 "
    },
    "SKIING_XC_1_2_ASYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_asymmetric.gif",
        "text": "Langlauf 1:2 ASYMMETRISCH",
        "description": "1 : 2 Asymmetric"
    },
    "SKIING_XC_1_2_SYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_symmetric.gif",
        "text": "Langlauf 1:2 SYMMETRIC",
        "description": "1 : 2 symmetric"
    },
    "MP_TEST": {
        "image": "https://2peak.com/images/peak_icons/training_content/mp_test.gif",
        "text": "Leistungstest",
        "description": "Heute ist ein geeigneter Tag für einen Leistungstest. Falls die Option nicht gewählt wird, bitte im Grundlagenbereich über die vorgeschlagene Dauer trainieren."
    },
    "RESPIRATION": {
        "image": "https://2peak.com/images/peak_icons/training_content/respiration.gif",
        "text": "Atmung",
        "description": "Atmungstraining unter Umständen unter Beihilfe von Hilfsgeräte (Spirotiger etc.) nur unter Anleitung eines Coach vergeben."
    },
    "MENTAL": {
        "image": "https://2peak.com/images/peak_icons/training_content/mental.gif",
        "text": "Mental",
        "description": "Die positive Beeinflussung psychischer Leistungsfaktoren verbessert die Befindlichkeit des Sportlers in Hinblick auf die optimale Leistungsfähigkeit. Intellektuelle Fähigkeiten, Handlungspläne und emotionale Befindlichkeiten lassen sich gezielt optimieren."
    }
}