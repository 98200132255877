export const ITZONE = {
    "Z1": "intensità più bassa  ",
    "Z2_LOW": "intensità bassa ",
    "Z2": "intensità bassa ",
    "Z2_HIGH": "intensità bassa ",
    "Z3": "intensità media",
    "Z4_LOW": "intensità alta ",
    "Z4_HIGH": "intensità alta ",
    "Z5": "intensità massima",
    "Z5_SHORT": "intensità massima"
}