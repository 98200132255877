import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MySectionsPageRoutingModule } from './my-sections-routing.module';
import { MySectionsPage } from './my-sections.page';
import { TranslateModule } from "@ngx-translate/core"
import { ContentLoaderModule } from "@netbasal/ngx-content-loader"
import { CoachHeaderBarComponentModule } from './../coach/coach-header-bar/coach-header.module';
import { PageLoaderComponent } from "./../page-loader/page-loader.component";
import { PageLoaderComponentModule } from "./../page-loader/page-loader.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ContentLoaderModule,
    PageLoaderComponentModule,
    CoachHeaderBarComponentModule,
    MySectionsPageRoutingModule
  ],
  declarations: [MySectionsPage],
  entryComponents:[PageLoaderComponent]
})
export class MySectionsPageModule {}
