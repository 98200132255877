export const FRZONE = {
    "Z1": "intensité la plus faible (très facile)",
    "Z2_LOW": "intensité faible (facile)",
    "Z2": "intensité faible (facile)",
    "Z2_HIGH": "intensité faible (facile)",
    "Z3": "intensité moyenne",
    "Z4_LOW": "intensité haute",
    "Z4_HIGH": "intensité haute",
    "Z5": "intensité la plus élevée",
    "Z5_SHORT": "intensité la plus élevée"
}