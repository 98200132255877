import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core"
import { NavController, MenuController,  Events,} from "@ionic/angular"
import { ApiService } from "../../_services/api/api.service"
import { StorageService } from "../../_services/storage/storage.service"
import { Router } from "@angular/router"
import { AuthService } from './../../_services/auth/auth.service';

@Component({
  selector: 'app-coach-menu',
  templateUrl: './coach-menu.component.html',
  styleUrls: ['./coach-menu.component.scss'],
})
export class CoachMenuComponent implements OnInit, OnDestroy {

  @Input() user: any;

  groupArr: any = [];
  showLevel1 = null;
  showLevel2 = null;
  isLoaded:boolean = false;

  constructor(
    public navCtrl: NavController,
    private translate: TranslateService,
    private _auth: AuthService,
    private _router: Router,
    private events: Events,
    private api: ApiService,
    private storage: StorageService,
    private menu: MenuController,
    public translateService: TranslateService,
  ) { 

    this.events.subscribe('userLogin:current', async (data) => {
      if(data){
        this.user = data;
        this.fetchGroup();
      }
    });

    this.events.subscribe('coachMenu:updated', async (data) => {
      if(data){
        this.user = data;
        this.fetchGroup();
      }
    });

  }

  async ngOnInit() { 

    /* will only work for pwa separated app 
      if(document.getElementById('custom-menu-coach'))
        document.getElementById('custom-menu-coach').style.display = 'block';
    */
     
    if(this.checkTokenExist()){
      this.fetchGroup()
    }
  }

  async checkTokenExist(){
    await this.storage.getCredentials().then( cred => {
      if(cred && !this._auth.getAccessToken()){
        this._auth.updateToken(cred);
      }
    });
  }


  fetchGroup(){
    this.isLoaded = true;
    this.api.fetchGroups(this.user.team).then(res => {
      if(res && res['groups']){
        this.groupArr = res['groups'];
      }
      this.isLoaded = false;
    })
    .catch(err => {
      this.isLoaded = false;
      console.log("Something wrong.")
    })
  }

  async switchUserRole(){
    localStorage.setItem('userRole', 'coachToTraineeView');
    await this.storage.getMainCredentials().then( cred => {
      if(cred){
        this._auth.updateToken(cred);
        this.storage.removeMainCredentials();
      }  
    });

    await this.storage.getMainUser().then( user => {
      if(user){ 
        this.storage.setUser(user);
        this.storage.removeMainUser();
      }
    });

    await this.storage.getMainUser().then( user => {
      if(!user)
      this.events.publish('userRole:changed', true);
    })
  }
    
  async switchUser(user){
    localStorage.setItem("userRole", "trainee")
    await this.storage.getMainUser().then(res => {
      if(!res){
        this.storage.getUser().then( mainUser => {
          this.storage.setMainUser(mainUser);
        });
      }
    });
    await this.storage.getMainCredentials().then(resMainCred => {
      if(!resMainCred){
        this.storage.getCredentials().then( cred => {
          let setCred;
          if(typeof cred=='object')
            setCred = cred;
          else
            setCred = JSON.parse(cred);
          
            this.storage.setMainCredentials(setCred);
        });
      }
    });
    
    await this.storage.setCredentials(user.token)
    await this.storage.setUser(user)
    await this._auth.updateToken(user.token);
    this.menu.close();
    setTimeout(() =>{
      this.events.publish('switchUser:changedFromMenu', user);
    })
  }

  toggleLevel1(idx) {
    if (this.isLevel1Shown(idx)) {
      this.showLevel1 = null;
    } else {
      this.showLevel1 = idx;
    }
  };
  
  toggleLevel2(idx) {
    if (this.isLevel2Shown(idx)) {
      this.showLevel1 = null;
      this.showLevel2 = null;
    } else {
      this.showLevel1 = idx;
      this.showLevel2 = idx;
    }
  };

  goToGroup(){
    this._router.navigateByUrl('/athlete?createGroup=true')
    this.menu.close();
  }

  isLevel1Shown(idx) {
    return this.showLevel1 === idx;
  };
  
  isLevel2Shown(idx) {
    return this.showLevel2 === idx;
  };

  normalMenu(url){
    this._router.navigate([`/${url}`])
    this.menu.close();
  }


  getSecondaryOpacityIfNotActive(url: string, url2: string = url): string {
    if (url === this._router.routerState.snapshot.url || url2 === this._router.routerState.snapshot.url) {
      return "primary-text-on-dark bold"
    }
    return "secondary-text-on-dark"
  }

  routerChange(url){
    //this.events.publish('isNetwork:online');
    this._router.navigateByUrl(url)
  }

  ngOnDestroy(){
    this.events.unsubscribe('userLogin:current');
    this.events.unsubscribe('coachMenu:updated');
  }


}
