import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CoachHeaderBarComponent } from './coach-header-bar.component';
import { TranslateModule } from "@ngx-translate/core"


@NgModule({
  imports: [ CommonModule, IonicModule, TranslateModule],
  declarations: [CoachHeaderBarComponent],
  exports: [CoachHeaderBarComponent]
})
export class CoachHeaderBarComponentModule {} 