import { Component, OnDestroy } from "@angular/core"
import { Router, NavigationEnd } from "@angular/router"
import { OneSignal } from "@ionic-native/onesignal/ngx"
import { NavController, ActionSheetController, LoadingController, MenuController, Platform, Events, ToastController, AlertController} from "@ionic/angular"
import { TranslateService } from "@ngx-translate/core"
import * as moment from "moment"
import { NetworkService } from './_services/api/network.service';
import { APP_NAME, VERSION, WO_CREATOR_DATE, BEATING_2P_OPTIONS, oneSignalCred, SHORT_NAME} from "./constants"
import { LogWorkoutPage } from "./training-plan/log-workout/log-workout.page"
import { User } from "./_interfaces/User"
import { ApiService } from "./_services/api/api.service"
import { AppleWatchService } from "./_services/apple-watch/apple-watch.service"
import { AuthService } from "./_services/auth/auth.service"
import { StorageService } from "./_services/storage/storage.service"
import { Keyboard } from "@ionic-native/keyboard/ngx"
import { setLightStatusBar, setDarkStatusBar, decodeParams, openChat, activateTrial, isWOCreatorAppOnlyUtil } from "./_utils/utils"
import { fromEvent } from 'rxjs';
import { Plugins, Capacitor } from "@capacitor/core"
// import { BrowserTab } from "@ionic-native/browser-tab/ngx"
import { HttpClient } from "@angular/common/http"

declare var sswc: any
const { Browser } = Plugins;


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  providers: [Keyboard]
})
export class AppComponent implements OnDestroy {

  navLinksArray = [];// store route links as the user navigates the app

  public appPages
  currentUrl: string
  isLoggedIn = true
  isNotification: Boolean = false;
  user: User = null
  calculating = false
  loginUser: any = [];
  isTrainerMenu: boolean = false;
  isKnowledgeBase: boolean = true;
  loginUserRole: any;
  needsRecalculation = false
  checkedHide = true
  isWOCreatorAppOnlyVar = true
  isNetworkAvailable:Boolean = true;
  isIap = true
  foreground = false
  newrace = false
  plt: string

  openChat = openChat
  activateTrial = activateTrial

  // set up hardware back button event.
   lastTimeBackPress = 0;
   timePeriodToExit = 2000;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private router: Router,
    public navCtrl: NavController,
    private apiService: ApiService,
    private storageService: StorageService,
    private menu: MenuController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private appleWatchService: AppleWatchService,
    private auth: AuthService,
    public toastController: ToastController,
    public networkService: NetworkService,
    private oneSignal: OneSignal,
    private actionSheetCtrl: ActionSheetController,
    // private browserTab: BrowserTab,
    private http: HttpClient,
    private keyboard: Keyboard,
    private events: Events,
    public translateService: TranslateService,

  ) {
    this.checkTokenExist();
    this.redirectionBasedOnUser();
    this.initializeApp()
    //get notification length
    this.events.subscribe('notificationLen:changed', (notifyLen) => {
      this.isNotification = (notifyLen>0)?true:false;
    });

    //WO creator only
    this.events.subscribe('isWOCreatorAppOnlyVar:changed', (isWOCreatorAppOnlyVar2) => {
        this.isWOCreatorAppOnlyVar = isWOCreatorAppOnlyVar2;
    });

    this.events.subscribe('newUser:subscribed', (data) => {
      if(data.id){
        this.loginUser = data;
        this.setConditionalMenu(data.roles)          
      }
    });
    

    this.events.subscribe('userRole:changed', async(data) => {
      if(data){
        this.isTrainerMenu = false;
        await this.storageService.getUser().then(async res => {
          this.loginUser = res;
          this.roleBasedRedirection(res.roles);
        });

        await this.conditioanalKbShow();
      }
    });

    this.events.subscribe('newRole:trainer', async (data) => {
      if(data){
        await this.storageService.getUser().then(async loginUser => {
          this.loginUser = loginUser;
          this.isTrainerMenu = true;
          this.router.navigate(['/dashboard'])
        });
      }
    });

    this.events.subscribe('viewUser:workout', async (user) => {
      if(user){
          this.loginUser = user;
          this.isTrainerMenu = false;
          await this.conditioanalKbShow();
          this.loginUserRole = "trainee"
          this.router.navigate(['/training-plan'])
      }
    });


    //iap
    this.events.subscribe('isIap:changed', (isIap2) => {
        this.isIap = isIap2;
    });
    
    //back in foreground
    this.events.subscribe('foreground:changed', (foreground) => {
        this.foreground = foreground;
    });

    this.router.events.subscribe(event =>{
      const url = this.router.url
      if (event instanceof NavigationEnd) {
        const isCurrentUrlSaved = this.navLinksArray.find((item) => {return item === url});
        if (!isCurrentUrlSaved)
          this.navLinksArray.push(url);
      }
    })

    this.hardwareBackButton()

    this.getUser();
    
  }


  async checkTokenExist(){
    await this.storageService.getCredentials().then( async cred => {
      if(cred && !this.auth.getAccessToken()){
        this.auth.updateToken(cred);
        await this.storageService.getUser().then(user => {
          this.storageService.setUser(user)
        })
      }
    });
  }



  async redirectionBasedOnUser(){
    await this.storageService.getUser().then(async user => {
      if(user){
        var index = await user.roles.findIndex( (item, index) => {
          return item=='trainer';
        });
        if(index===1){
          localStorage.setItem('userRole', 'coach')
          this.router.navigate(['/dashboard']);
        }
      }
    })   
    
  }

  async roleBasedRedirection(roles){
    var index = await roles.findIndex( (item, index) => {
      return item=='trainer';
    })
    if(index===-1){
      this.loginUserRole = 'trainee';
    }else{
      let mainUser =  await this.storageService.getMainUser();
      if(mainUser)
        this.loginUserRole = 'trainee';
      else
        this.loginUserRole = 'trainer';
    }

    this.router.navigate(['/home'])
  }



  async getUser(){
    this.user = await this.storageService.getUser();
    if(this.user){
      this.loginUser = this.user;
      await this.setConditionalMenu(this.loginUser.roles)  
    }
  }

  async switchUserRole(){
    await this.storageService.getMainCredentials().then( cred => {
      if(cred){
        this.auth.updateToken(cred);
        this.storageService.removeMainCredentials();
      }  
    });

    await this.storageService.getMainUser().then( user => {
      if(user){ 
        this.storageService.setUser(user);
        this.storageService.removeMainUser();
      }
    });

    await this.storageService.getMainUser().then( user => {
      if(!user){
        localStorage.setItem('userRole', 'coach');
        this.isTrainerMenu = true;
        this.router.navigate(['/dashboard']);
      }
    })
  }

  async setConditionalMenu(roles){
      
      var index = await roles.findIndex( (item, index) => {
        return item=='trainer';
      })
      if(index===-1){
        this.loginUserRole = 'trainee';
        this.isTrainerMenu = false;
        this.conditioanalKbShow();
      }else{
        this.loginUserRole = 'trainer';
        this.isTrainerMenu = true;
      }
  }

  async conditioanalKbShow(){
    let isMainCredAvail = await this.storageService.getMainCredentials();
    if(isMainCredAvail)
      this.isKnowledgeBase = false;
    else
      this.isKnowledgeBase = true;
  }


  ngOnDestroy() {
    this.events.unsubscribe('userLogin:current');
    this.events.unsubscribe('network:online');
    this.events.unsubscribe('network:offline');
    this.events.unsubscribe('isNetwork:online');
    this.events.unsubscribe('isNetwork:offline');
    this.events.unsubscribe('userRole:changed');
    this.events.unsubscribe('userLogin:logout');
    this.events.unsubscribe('newRole:trainer');
    this.events.unsubscribe('viewUser:workout');
  }

  checkNetwork(){
    this.networkService.initializeNetworkEvents();
    this.events.subscribe('network:offline', () => {
      this.isNetworkAvailable = false;
      setTimeout(() => {
        this.presentAlert();
      }, 1000)
      this.router.navigate(["/network-unavailable"])
    });
    this.events.subscribe('network:online', () => {
      this.isNetworkAvailable = true;
      const net_back = this.translateService.get("INTERNET_BACK").toPromise()
      this.router.navigate(["/home"])
    });
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000
    });
    toast.present();
  }

  async presentAlert(){
  }

  async confirmationForRace(){
    const header = await this.translateService.get("TYPE_OF_RACE_TITLE").toPromise()
    const message = await this.translateService.get("TYPE_OF_RACE").toPromise()
    const virtualRace = await this.translateService.get("VIRTUAL_RACE").toPromise()
    const normalRace = await this.translateService.get("NORMAL_RACE").toPromise()
    const alertP = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: normalRace,
          handler: async () => {
            localStorage.setItem('raceTypeGoal', 'normal')
            this.router.navigate(["races-goals/plan-race"])
          }
        },
        {
          text: virtualRace,
          cssClass: "secondary",
          handler: async () => {
           localStorage.setItem('raceTypeGoal', 'virtual')
           this.router.navigate(["races-goals/plan-race"])
          }
        }
      ]
    })
    await alertP.present()
  }

  newRace(){
    //this.confirmationForRace();
    localStorage.setItem('raceTypeGoal', 'virtual')
    this.router.navigate(["races-goals/plan-race"])
  }

  routerChange(url){
    if(this.isNetworkAvailable){
      this.events.publish('isNetwork:online');
      this.navCtrl.navigateRoot(url)
    }else{
      this.events.publish('isNetwork:offline');
      this.router.navigate(["/network-unavailable"])
    }
  }

  async cacheDataAlert(){
    const header = await this.translateService.get("ALERT_TITLE").toPromise()
    const message = await this.translateService.get("ALERT_DESCRIPTION").toPromise()
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: [
        {
          text: "ok",
          role: "cancel",
        },
      ]
    })
    alert.present()
  }


  initializeApp() {
    
    // Cordova plugins
    this.platform.ready().then(async () => {
      //set user visited couter
      this.storageService.setUserAppVisit()
      //watch network for a disconnection
      this.checkNetwork();

      if(this.platform.is('cordova')){
        this.plt = "cordova"
        this.platform.pause.subscribe(() => {
          this.checkNetwork();
        });

        this.platform.resume.subscribe(() => {
          this.checkNetwork();
        });
      }

      if (Capacitor.platform === "ios") {
          this.keyboard.hideFormAccessoryBar(false)
          this.plt = "ios"
      }
      
      if (Capacitor.platform === "android") {
        this.plt = "android"
      }

      // Apple Watch
      //if (this.platform.is("ios")) {
      if (Capacitor.platform === "ios") {
        this.appleWatchService.init(sswc)
      }
      
      this.translate.addLangs(["de", "en", "es", "fr", "it"])
      this.translate.setDefaultLang("en")
      const browserLang = this.translate.getBrowserLang()
      let lang = browserLang.match(/de|en|es|fr|it/) ? browserLang : "en"
      this.translate.use(lang)
      moment.locale(lang)


      // Checking if user here, or getting default language
      this.user = await this.storageService.getUser()
      this.isWOCreatorAppOnlyVar = isWOCreatorAppOnlyUtil(this.user,this.events)
      this.storageService.getUserObservable().subscribe(user => this.user = user)
      if (this.user) {
        lang = this.user.language
        this.translate.use(lang)
        moment.locale(lang)
      }


      //check version after new update
      var ver = await this.storageService.getVersion()
      if(ver == undefined || ver == null || ver == 0 || ver < VERSION) {
        /* Remove required storage variable */
        localStorage.removeItem('pwoModal');
        localStorage.removeItem('isUserVisitFirstTime');
        localStorage.removeItem('visitCount');
        localStorage.removeItem('isFirstLogin');
        await this.storageService.removeRegisteringUser();
        await this.auth.logout(true,false)
      }
      

      this.auth.storageLoad().then(() => {
        if (!this.auth.isAuthenticated) {
          this.auth.logout()
          setDarkStatusBar()
        } else {
          setLightStatusBar()
        }
      })

      if (Capacitor.isPluginAvailable("SplashScreen")) {
        Plugins.SplashScreen.hide()
      }

      if (Capacitor.platform === "android") {
        const glu = await Plugins.App.getLaunchUrl()
        if (glu.url.indexOf("social/access_token") > -1) {
          this.processSocialAndro(glu.url)
        }
      }

      if (Capacitor.isPluginAvailable("App")) {
        Plugins.App.addListener("appUrlOpen", data => {
          if (data.url.indexOf("social/?") > -1) {
            this.processSocial(data.url)
          }
        })
      }

      this.oneSignal.startInit(oneSignalCred.app_id, oneSignalCred.google_project_id)
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert)

      if (this.user) {
        this.oneSignal.sendTags({valid_until: this.user.valid_until, main_activity_type: this.user.main_activity_type, package: this.user.package, days_to_peak: this.user.days_to_peak, last_logged_activity: this.user.last_logged_activity, sync_set_up: this.user.sync_set_up, multisport:this.user.multisport, pid: this.user.id})
      }


      /* Notification section */
      this.oneSignal.handleNotificationOpened().subscribe((jsonData) => {
        if (jsonData.notification.payload.additionalData) {
          // wo reminder push
          if (jsonData.notification.payload.additionalData.docuid) {
            const params = { "id": jsonData.notification.payload.additionalData.docuid }
            this.router.navigate(["/training-plan/activity-details"], { queryParams: params })
          }
          // add race push
          if (jsonData.notification.payload.additionalData.race) {
            this.router.navigate(["/races-goals"])
          }
          // add sync push
          if (jsonData.notification.payload.additionalData.sync) {
            this.router.navigate(["/connected-services"])
          }
          // add TM push
          if (jsonData.notification.payload.additionalData.tm) {
            this.router.navigate(["/training-meter"])
          }
        }
      })
      this.oneSignal.endInit()

      this.storageService.getDashboardObservable().subscribe(dashboard => {
        if (!dashboard) {
          return
        }
        if (!dashboard.img) {
          return
        }
        this.needsRecalculation = dashboard.img === "new_calc.jpg"
      })
    })

    if (this.platform.is('cordova')) {
        this.setPlatformListener();
    }
  }

  setPlatformListener() {
    this.platform.pause.subscribe(() => {
    });

    this.platform.resume.subscribe(() => {
      this.events.publish('foreground:changed', true)
      if(this.router['routerState'].snapshot.url == "/training-plan" || this.router['routerState'].snapshot.url == "/home" || this.router['routerState'].snapshot.url == "/account/battery" || this.router['routerState'].snapshot.url == ""){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/home"]));
      }
    });
  }

  async activateTrialfct() {
    if(this.isNetworkAvailable){
      await activateTrial(this.apiService, this.loadingCtrl)
      this.events.publish('isWOCreatorAppOnlyVar:changed', false);
      this.router.navigate(["/home"])
    }else{
      this.router.navigate(["/network-unavailable"])
    }
  }

  logWorkout(id: number = 0, type: string = LogWorkoutPage.ACTIVITY) {
    const params = {
      id,
      date: moment().format("YYYY-MM-DD"),
      type,
    }
    this.events.publish('routechanged:addWorkoutPlan', true);
    this.router.navigate(["/training-plan/log-workout"], { queryParams: params })
  }

  logWorkoutNew() {
    const params = {
      date: moment().toISOString(),
      type: "activity"
    }
    this.events.publish('routechanged:addWorkoutPlan', true);
    this.router.navigate(["/training-plan/plan-workout"], { queryParams: params })
  }

  async addWorkout() {
    if(this.isNetworkAvailable){
      const params = {
        date: moment().toISOString(),
      }
      this.events.publish('routechanged:addWorkoutPlan', true);
      this.router.navigate(["/training-plan/plan-workout"], { queryParams: params })
    }else{
      this.router.navigate(["/network-unavailable"])
    }
  }

  async logSomething() {
    if(this.isNetworkAvailable){
      const logStr = await this.translate.get("ENTER_WORKOUT").toPromise() 
      const actStr = await this.translate.get("ACTIVITY_PLAN").toPromise()
      const raceStr = await this.translate.get("SPORT_RACE").toPromise()
      const noTrainingStr = await this.translate.get("NO_TRAINING").toPromise()
      const createStr = await this.translate.get("CREATE_WORKOUT").toPromise()

      const actionSheet = await this.actionSheetCtrl.create({
        header: logStr + "...",
        buttons: [
          {
            text: actStr,
            handler: () => {
              this.logWorkoutNew()
            }
          },
          {
            text: noTrainingStr,
            handler: () => {
              this.logWorkout(0, LogWorkoutPage.NO_TRAINING)
            }
          },
          {
            text: createStr,
            handler: () => {
              this.addWorkout()
            }
          },
        ],
      })
      await actionSheet.present()
    }else{
      this.router.navigate(["/network-unavailable"])
    }
  }

  getSecondaryOpacityIfNotActive(url: string, url2: string = url): string {
    if (url === this.router.routerState.snapshot.url || url2 === this.router.routerState.snapshot.url) {
      return "primary-text-on-dark bold"
    }
    return "secondary-text-on-dark"
  }

  openHelp() {
    this.router.navigate(["/help"])
  }

  openSettings() {
    this.router.navigate(["/settings"])
  }

  /* async redirectNavigation(roles){

    var index = await roles.findIndex( (item, index) => {
      return item=='trainer';
    })
    if(index===-1){
      this.router.navigate(["/home"]);
    }else{
      this.router.navigate(["/dashboard"]);
    }
  } */

  async redirectNavigation(roles){
    var index = await roles.findIndex( (item, index) => {
      return item=='trainer';
    })
    if(index===-1){
      localStorage.setItem('userRole', 'trainee')
      this.router.navigate(["/home"]);
    }else{
      localStorage.setItem('userRole', 'coach')
      this.router.navigate(["/dashboard"]);
    }
  }

  async processSocial(scheme: string) {
    if (!scheme) {
      return
    }
    if (scheme.indexOf("?") === -1) {
      return
    }
    // const beating2P = await this.loadingCtrl.create(BEATING_2P_OPTIONS)
    const {
      access_token,
      expires_in,
      refresh_token,
      scope,
      token_type,
    } = decodeParams(scheme.split("?")[1])


    await this.auth.handleAuthDataRes({ access_token, expires_in, refresh_token, scope, token_type })
    await this.apiService.fetchUser()
    await this.apiService.fetchBattery()
    await this.apiService.fetchActivityTypes(this.translate.currentLang)
    await this.apiService.fetchZoneNames(this.translate.currentLang)
    await this.apiService.fetchTrainingZones()
    await this.apiService.fetchTrainingElement(this.translate.currentLang)
    // beating2P.dismiss()
    await Browser.close()
    setLightStatusBar()
    this.storageService.getUser().then(res => {
      if(res){
        this.events.publish('newUser:subscribed', res);
        this.events.publish('userLogin:current', res);
        this.redirectNavigation(res.roles);
      }
      
    }) 
    //this.router.navigate(["/home"],{ queryParams: { to: 1 } })
  }

  async processSocialAndro(scheme: string) {
    if (!scheme) {
      return
    }
    if (scheme.indexOf("/social/") === -1) {
      return
    }
    const beating2P = await this.loadingCtrl.create(BEATING_2P_OPTIONS)
    const {
      access_token,
      expires_in,
      refresh_token,
      scope,
      token_type,
    } = decodeParams(scheme.split("/social/")[1].split("#Intent")[0])

    await this.auth.handleAuthDataRes({ access_token, expires_in, refresh_token, scope, token_type })
    await this.apiService.fetchUser()
    await this.apiService.fetchBattery()
    await this.apiService.fetchActivityTypes(this.translate.currentLang)
    await this.apiService.fetchZoneNames(this.translate.currentLang)

    // setLightStatusBar()
    await this.apiService.fetchTrainingZones()
    await this.apiService.fetchTrainingElement(this.translate.currentLang)
    beating2P.dismiss()
    setLightStatusBar()
    this.storageService.getUser().then(res => {
      if(res){
        this.events.publish('newUser:subscribed', res);
        this.events.publish('userLogin:current', res);
        this.redirectNavigation(res.roles);
      }
      
    }) 
    //this.router.navigate(["/home"],{ queryParams: { to: 1 } })
  }

  isPlanExpired() {
    return moment(this.user.valid_until).isBefore(moment())
  }

  async goToShop() {
    if (this.user.aid[SHORT_NAME] < VERSION) {
      this.router.navigate(["/shop"])
    }else {
      this.router.navigate(["/shop/iap"])
    }
  }

  async runCalculation() {

    if(this.isNetworkAvailable){

      if(this.isPlanExpired()) {
        const header = await this.translateService.get("PLAN_HAS_EXPIRED").toPromise()
        const content = await this.translateService.get("PLAN_HAS_EXPIRED_LINE2").toPromise()
        const shop = await this.translateService.get("OPEN_SHOP").toPromise()

        const alert = await this.alertCtrl.create({
          header: header,
          message: content,
          buttons: [
           {
              text: shop,
              handler: () => {
                this.goToShop();
              }
            }
          ]
        });
    
        await alert.present();
        
      }
      else {
        this.calculating = true
        let calc_error = false
        const beating2P = await this.loadingCtrl.create(BEATING_2P_OPTIONS)
        await beating2P.present()
  
        await this.apiService.runCalculation()
      
        if (String(calc_error) == 'true') {
          const header = await this.translateService.get("WAIT_RECAL").toPromise()
          const ok = await this.translateService.get("OK").toPromise()

          const alert = await this.alertCtrl.create({
            header: header,
            buttons: [
            {
                text: ok,
                handler: () => {
                }
              }
            ]
          });
          this.calculating = false
          beating2P.dismiss()
          await alert.present();
        } else {
        
        await this.apiService.fetchUser().then(res => {
          this.menu.close();

          if(calc_error != true)
            this.router.navigate(["/training-plan"])
        })
        
        
        this.calculating = false
        beating2P.dismiss()
        } 
      }
    } else {
      this.router.navigate(["/network-unavailable"])
    }
  }

  isTrial() {
    if (!this.user) {
      return false
    }
    else if (this.user.package_name == "Trial-Version" || this.user.package_name == "Basic Fitness"){
      return true
    }
    else {
      return false
    }
  }

  async notification() {
    if(this.isNetworkAvailable){
      this.router.navigate(["/notification"])
      this.menu.close()
    }else{
      this.router.navigate(["/network-unavailable"])
    }
  }

  isTester() {
    if(this.loginUser.id==3 || this.loginUser.id==55){
      return true
    }else
      return false
  }

  hardwareBackButton(){
    const event = fromEvent(document, 'ionBackButton');
    event.subscribe(async () => {
        console.log('hardware back button triggered')
        if (this.navLinksArray.length > 1){
          this.navLinksArray.pop();
          const index = this.navLinksArray.length + 1;
          const url = this.navLinksArray[index];
          this.router.navigate([url])
        }
    })
  }
}
