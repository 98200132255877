import { NgModule } from "@angular/core"
import { RouterModule, Routes, PreloadingStrategy, PreloadAllModules } from "@angular/router"
import { AuthGuard } from "./auth.guard"
import { LoginGuard } from "./login.guard"

const routes: Routes = [
  {
    path: "",
    redirectTo: "login/slides",
    pathMatch: "full",
  },
  {
    path: "login/slides",
    loadChildren: "../login/slides/slides.module#SlidesPageModule",
    canActivate: [LoginGuard],
    data: {
      preload: true
    },
  },
  {
    path: "signup/coach",
    loadChildren: () => import('./../signup/coach/signup.module').then( m => m.SignupPageModule),
  },

  {
    path: "home",
    loadChildren: "../home/home.module#HomePageModule",
    canActivate: [AuthGuard],
  },

  {
    path: "dashboard",
    loadChildren: "../coach/coach-dashboard/coach-dashboard.module#CoachDashboardModule",
    canActivate: [AuthGuard],
  },
 
  {
    path: "notification",
    loadChildren: "../notification/notification.module#NotificationPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "account",
    loadChildren: "../account/account.module#AccountPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "account/battery",
    loadChildren: "../account/battery/battery.module#BatteryPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-plan",
    loadChildren: "../training-plan/training-plan.module#TrainingPlanPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-plan/plan-workout",
    loadChildren: "../training-plan/plan-workout/plan-workout.module#PlanWorkoutPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-plan/log-workout",
    loadChildren: "../training-plan/log-workout/log-workout.module#LogWorkoutPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-plan/log-race",
    loadChildren: "../training-plan/log-race/log-race.module#LogRacePageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-plan/activity-details",
    loadChildren: "../training-plan/activity-details/activity-details.module#ActivityDetailsPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "races-goals",
    loadChildren: "../races-goals/races-goals.module#RacesGoalsPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "races-goals/plan-race",
    loadChildren: "../races-goals/plan-race/plan-race.module#PlanRacePageModule",
    canActivate: [AuthGuard],
  },
  // {
  //   path: "races-goals/details",
  //   loadChildren: "../races-goals/details/race-detail.module#RaceDetailPageModule",
  //   canActivate: [AuthGuard],
  // },
  {
    path: "races-goals/details",
    loadChildren: "../races-goals/details/race-detail.module#RaceDetailPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "races-goals/ranking",
    loadChildren: "../races-goals/ranking/ranking.module#RankingPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "races-goals/participants",
    loadChildren: "../races-goals/participants/participants.module#ParticipantsPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "weekplan",
    loadChildren: "../weekplan/weekplan.module#WeekplanPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "weekplan/plan-weekplan-entry",
    loadChildren: "../weekplan/plan-weekplan-entry/plan-weekplan-entry.module#PlanWeekplanEntryPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-zones",
    loadChildren: "../training-zones/training-zones.module#TrainingZonesPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-zones/edit-training-zones",
    loadChildren: "../training-zones/edit-training-zones/edit-training-zones.module#EditTrainingZonesPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-zones/swimtime",
    loadChildren: "../training-zones/swimtime/swimtime.module#SwimtimePageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "training-meter",
    loadChildren: "../training-meter/training-meter.module#TrainingMeterPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "connected-services",
    loadChildren: "../connected-services/connected-services.module#ConnectedServicesPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    loadChildren: "../settings/settings.module#SettingsPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "help",
    loadChildren: "../help/help.module#HelpPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "kb",
    loadChildren: "../kb/kb.module#KbPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "shop",
    loadChildren: "../shop/shop.module#ShopPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "my-sections",
    loadChildren: () => import('../my-sections/my-sections.module').then(m => m.MySectionsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "statistics",
    loadChildren: "../statistics/statistics.module#StatisticsPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "performance",
    loadChildren: "../performance/performance.module#PerformancePageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "network-unavailable",
    loadChildren: "../disconnect/disconnect.module#DisconnectPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "shop/iap",
    loadChildren: "../shop/iap/iap.module#IapPageModule",
    canActivate: [AuthGuard],
  },

  {
    path: "athlete",
    loadChildren: "../coach/athlete/athlete.module#AthletePageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "workout-library",
    loadChildren: "../coach/workout-library/workout-library.module#WorkoutLibraryModule",
    canActivate: [AuthGuard],
  },

  {
    path: "login",
    loadChildren: "../login/login.module#LoginPageModule",
    canActivate: [LoginGuard],
  },
  {
    path: "login/setup",
    loadChildren: "../login/setup/setup.module#SetupPageModule",
    canActivate: [LoginGuard],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
