import { Injectable } from '@angular/core';
//import { Network } from '@ionic-native/network';
import { AlertController, Events } from "@ionic/angular"

import { Network } from '@ionic-native/network/ngx'

export enum ConnectionStatusEnum  {
    Online,
    Offline
}

@Injectable({
  providedIn: "root"
})
export class NetworkService {


    previousStatus;

    constructor(public alertCtrl: AlertController, private network: Network, public eventCtrl: Events) {
      this.previousStatus = ConnectionStatusEnum.Online;
    }

    public initializeNetworkEvents(): void {

        this.network.onDisconnect().subscribe(() => {
            this.eventCtrl.publish('network:offline');
        });

        this.network.onConnect().subscribe(() => {
            this.eventCtrl.publish('network:online');
        });
    }

}
