export interface Length {
    value: number
    unit: LengthUnit
}

export enum LengthUnit {
    meters = "m",
    seconds = "s",
}

export function convertLengthToJson(length: Length) {
    if (!length) {
        return {
            value: 0,
            unit: LengthUnit.meters,
        }
    }
    return {
        value: length.value,
        unit: length.unit,
    }
}

export function getLengthSummary(length: Length) {
    const { value, unit } = length

    if (unit === LengthUnit.meters) {
        return `${value} m`
    }

    if (value < 60) {
        return `${value}'`
    }
    if (value < 3600) {
        //return `${Math.floor(value / 60)}'${make2Digits(value - 60 * Math.floor(value / 60))}`
        if(make2Digits(value - 60 * Math.floor(value / 60)) != "00")
            return `${Math.floor(value / 60)}min ${make2Digits(value - 60 * Math.floor(value / 60))}s`
        else
            return `${Math.floor(value / 60)}min`
    }

    const hours = Math.floor(value / 3600)
    const minutes = Math.floor((value - hours * 3600) / 60)

    let duration = `${hours}h${make2Digits(minutes)}` 

    const seconds = value - 3600 * hours - 60 * minutes

    if (seconds > 0) {
        duration += `\`${make2Digits(seconds)}`
    }

    return duration
}

function make2Digits(v: number) {
    return `${v}`.padStart(2, "0")
}
