export const DEZONE = {
    "Z1": "geringste Intensität",
    "Z2_LOW": "leich. Belastung",
    "Z2": "leich. Belastung",
    "Z2_HIGH": "leich. Belastung",
    "Z3": "intensivere Belastung",
    "Z4_LOW": "starke Belastung",
    "Z4_HIGH": "starke Belastung",
    "Z5": "volle Belastung",
    "Z5_SHORT": "volle Belastung"
  }