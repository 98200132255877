import { ModuleWithProviders, NgModule } from "@angular/core"
import { Device } from "@ionic-native/device/ngx"
import { FileTransfer } from "@ionic-native/file-transfer/ngx"
import { File } from "@ionic-native/file/ngx"
import { AppleWatchService } from "./apple-watch.service"

@NgModule({
  imports: [],
  providers: [
    Device,
    File,
    FileTransfer,
  ],
})
export class AppleWatchModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppleWatchModule,
      providers: [
        AppleWatchService,
      ],
    }
  }
}
