export const FRPHASES = {
    "Start": "début",
    "Regeneration": "Récupération",
    "Intensity": "Intensité",
    "Endurance": "Endurance",
    "Endurance before training camp": "Endurance avant un stage d'entraînement",
    "Regeneration before training camp": "Régénération avant un stage d'entraînement",
    "Training camp": "Stage d'entraînement",
    "Regeneration after training camp": "Régénération après un stage d'entraînement",
    "Regeneration before competition 1": "Régénération avant la compétition no. 1",
    "Endurance before competition": "Endurance avant une compétition ",
    "Intensity before competition": "Intensité avant une compétition ",
    "Regeneration before competition 2": "Régénération avant la compétition no. 2",
    "3 Days before competition": "Compétition J-3",
    "2 Days before competition": "Compétition J-2",
    "1 Day before competition": "Compétition J-1",
    "Competition": "Compétition",
    "Regeneration after competition 1": "Régénération après la compétition no. 1",
    "Regeneration after competition 2": "Régénération après la compétition no. 2",
    "Illness": "Problème de santé",
    "Regeneration after illness": "Régénération après un problème de santé"
}