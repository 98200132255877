import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { AuthService } from "../_services/auth/auth.service"

@Injectable({ providedIn: "root" })
export class LoginGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.url.length > 0) {
            if ((!this.authService.isAuthenticated() || this.authService.isNotReady) && route.url[0].path === "login") {
                return true
            }
        }
        this.router.navigateByUrl("/home")
    }
}
