export const ENACTIVITYTYPE = {
    "Cycling": {
        "image": "/images/activity_icons/Cycling.svg",
        "text": "Cycling",
        "texti": "bike",
        "transid": 2019,
        "race_types": [{
                "text": "10 Km run",
                "race_type": "10r"
            },
            {
                "text": "5 Km run",
                "race_type": "5r"
            },
            {
                "text": "Mountain run",
                "race_type": "hr"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Cross Country Run",
                "race_type": "tr"
            },
            {
                "text": "Half Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Criterium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "MTB Marathon (> 3h)",
                "race_type": "mtb_mt"
            },
            {
                "text": "Organized Ride (> 3hrs)",
                "race_type": "rtf"
            },
            {
                "text": "City run",
                "race_type": "cr"
            },
            {
                "text": "Cycling road-marathon (>3hrs)",
                "race_type": "road_mt"
            },
            {
                "text": "Pavement Run",
                "race_type": "sr"
            },
            {
                "text": "Road Race",
                "race_type": "rr"
            },
            {
                "text": "Ultra distance run",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distance Cycling",
                "race_type": "ultra"
            },
            {
                "text": "Time Trial",
                "race_type": "tt"
            }
        ]
    },
    "Multisport_spinning": {
        "image": "/images/activity_icons/Multisport_spinning.svg",
        "text": "Brick-Spinning",
        "texti": "brick_spinning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Multisport": {
        "image": "/images/activity_icons/Multisport.svg",
        "text": "multisport",
        "texti": "multisport",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Competition": {
        "image": "/images/activity_icons/race.svg",
        "text": "RACE",
        "texti": "RACE",
        "race_types": []
    },
    "No Time": {
        "image": "/images/activity_icons/No Time.svg",
        "text": "No time",
        "texti": "NO_TIME",
        "race_types": []
    },
    "None": {
        "image": "/images/activity_icons/None.svg",
        "text": "none",
        "texti": "none",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Triathlon": {
        "image": "/images/activity_icons/Triathlon.svg",
        "text": "Triathlon",
        "texti": "triathlon",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [{
                "text": "Iron Man",
                "race_type": "im"
            },
            {
                "text": "Half Iron Man",
                "race_type": "h_im"
            },
            {
                "text": "Olympic distance",
                "race_type": "olympic"
            },
            {
                "text": "Sprint distance",
                "race_type": "sprint"
            },
            {
                "text": "Short distance triathlon",
                "race_type": "t_kd"
            },
            {
                "text": "Medium distance triathlon",
                "race_type": "t_md"
            },
            {
                "text": "Long distance triathlon",
                "race_type": "t_ld"
            },
            {
                "text": "Half Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "Mountain Bike Marathon",
                "race_type": "mtb_mt"
            },
            {
                "text": "Cycling road-marathon (>3hrs)",
                "race_type": "road_mt"
            },
            {
                "text": "10 Km run",
                "race_type": "10r"
            },
            {
                "text": "Pavement Run",
                "race_type": "sr"
            },
            {
                "text": "Cross Country Run",
                "race_type": "tr"
            },
            {
                "text": "5 Km run",
                "race_type": "5r"
            },
            {
                "text": "City run",
                "race_type": "cr"
            },
            {
                "text": "Track event",
                "race_type": "bw"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "Road Race",
                "race_type": "rr"
            },
            {
                "text": "Organized Ride",
                "race_type": "rtf"
            },
            {
                "text": "Time Trial",
                "race_type": "tt"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Mountain run",
                "race_type": "hr"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Ultra Distance Triathlon",
                "race_type": "ultra_tri"
            },
            {
                "text": "Ultra Distance Cycling",
                "race_type": "ultra"
            },
            {
                "text": "Ultra distance run",
                "race_type": "ultra_run"
            }
        ]
    },
    "Travel Day": {
        "image": "/images/activity_icons/Travel Day.svg",
        "text": "Travelday",
        "texti": "TravelDay",
        "race_types": []
    },
    "No Training": {
        "image": "/images/activity_icons/No Training.svg",
        "text": "No training/Recovery",
        "texti": "no_training",
        "race_types": []
    },
    "Duathlon": {
        "image": "/images/activity_icons/Duathlon.svg",
        "text": "Duathlon",
        "texti": "duathlon",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [{
                "text": "10 Km run",
                "race_type": "10r"
            },
            {
                "text": "5 Km run",
                "race_type": "5r"
            },
            {
                "text": "Track event",
                "race_type": "bw"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Short distance duathlon",
                "race_type": "d_kd"
            },
            {
                "text": "Long distance duathlon",
                "race_type": "d_ld"
            },
            {
                "text": "Medium distance duathlon",
                "race_type": "d_md"
            },
            {
                "text": "Cross Country Run",
                "race_type": "tr"
            },
            {
                "text": "Half Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Criterium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "Mountain Bike Marathon",
                "race_type": "mtb_mt"
            },
            {
                "text": "Organized Ride",
                "race_type": "rtf"
            },
            {
                "text": "City run",
                "race_type": "cr"
            },
            {
                "text": "Cycling road-marathon (>3hrs)",
                "race_type": "road_mt"
            },
            {
                "text": "Pavement Run",
                "race_type": "sr"
            },
            {
                "text": "Road Race",
                "race_type": "rr"
            },
            {
                "text": "Ultra distance run",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distance Cycling",
                "race_type": "ultra"
            },
            {
                "text": "Ultra Distance Duathlon",
                "race_type": "ultra_dua"
            },
            {
                "text": "Time Trial",
                "race_type": "tt"
            }
        ]
    },
    "Long Cycling": {
        "image": "/images/activity_icons/Long Cycling.svg",
        "text": "Long-Bike",
        "texti": "LongCycling",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Mountain Biking": {
        "image": "/images/activity_icons/Mountain Biking.svg",
        "text": "Mountain Bike",
        "texti": "mtb",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Spinning": {
        "image": "/images/activity_icons/Spinning.svg",
        "text": "Indoor Cycling",
        "texti": "spinning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Running": {
        "image": "/images/activity_icons/Running.svg",
        "text": "Running",
        "texti": "run",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [{
                "text": "10 Km run",
                "race_type": "10r"
            },
            {
                "text": "5 Km run",
                "race_type": "5r"
            },
            {
                "text": "Mountain run",
                "race_type": "hr"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Cross Country Run",
                "race_type": "tr"
            },
            {
                "text": "Half Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Criterium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "MTB Marathon (> 3h)",
                "race_type": "mtb_mt"
            },
            {
                "text": "Organized Ride (> 3hrs)",
                "race_type": "rtf"
            },
            {
                "text": "City run",
                "race_type": "cr"
            },
            {
                "text": "Cycling road-marathon (>3hrs)",
                "race_type": "road_mt"
            },
            {
                "text": "Pavement Run",
                "race_type": "sr"
            },
            {
                "text": "Road Race",
                "race_type": "rr"
            },
            {
                "text": "test marathon",
                "race_type": "t_mt"
            },
            {
                "text": "Ultra distance run",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distance Cycling",
                "race_type": "ultra"
            },
            {
                "text": "Time Trial",
                "race_type": "tt"
            }
        ]
    },
    "Long Running": {
        "image": "/images/activity_icons/Long Running.svg",
        "text": "Long-Run",
        "texti": "LongRunning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Trail Running": {
        "image": "/images/activity_icons/Trail Running.svg",
        "text": "Trail Running",
        "texti": "TrailRunning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Treadmill": {
        "image": "/images/activity_icons/Treadmill.svg",
        "text": "Treadmill",
        "texti": "Treadmill",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Orienteering": {
        "image": "/images/activity_icons/Orienteering.svg",
        "text": "Orienteering",
        "texti": "Orienteering",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Swimming": {
        "image": "/images/activity_icons/Swimming.svg",
        "text": "Swimming",
        "texti": "swim",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Open Water Swimming": {
        "image": "/images/activity_icons/Open Water Swimming.svg",
        "text": "Open Water Swim",
        "texti": "OpenWaterSwimming",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Joker": {
        "image": "/images/activity_icons/Joker.svg",
        "text": "2PEAK chooses",
        "texti": "joker",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Gym": {
        "image": "/images/activity_icons/Gym.svg",
        "text": "Strength training",
        "texti": "iron",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Yoga": {
        "image": "/images/activity_icons/Yoga.svg",
        "text": "Yoga/Pilates",
        "texti": "Yoga",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Gym Equipment": {
        "image": "/images/activity_icons/Gym Equipment.svg",
        "text": "Gym Equipment",
        "texti": "GymEquipment",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Indoor Rowing": {
        "image": "/images/activity_icons/Indoor Rowing.svg",
        "text": "Indoor-Rowing",
        "texti": "IndoorRowing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "XC Skiing": {
        "image": "/images/activity_icons/XC Skiing.svg",
        "text": "XC-Skiing",
        "texti": "skiing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [{
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Half Marathon",
                "race_type": "l_h_mt"
            },
            {
                "text": "Short Distance",
                "race_type": "l_kd"
            },
            {
                "text": "Marathon",
                "race_type": "l_mt"
            },
            {
                "text": "Sprint Distance",
                "race_type": "l_sd"
            },
            {
                "text": "Ultra distance run",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distance Cycling",
                "race_type": "ultra"
            },
            {
                "text": "Ultra Distance x-Skiing",
                "race_type": "ultra_skiing"
            }
        ]
    },
    "Roller Skiing": {
        "image": "/images/activity_icons/Roller Skiing.svg",
        "text": "Roller-Skiing",
        "texti": "RollerSkiing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Ski Touring": {
        "image": "/images/activity_icons/Ski Touring.svg",
        "text": "Ski-Touring",
        "texti": "SkiTouring",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Snow Shoes": {
        "image": "/images/activity_icons/Snow Shoes.svg",
        "text": "Snow Shoes",
        "texti": "SnowShoes",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Sup": {
        "image": "/images/activity_icons/Sup.svg",
        "text": "Stand Up Paddling",
        "texti": "Sup",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Nordic Walking": {
        "image": "/images/activity_icons/Nordic Walking.svg",
        "text": "Nordic Walking",
        "texti": "nw",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Illness": {
        "image": "/images/activity_icons/Illness.svg",
        "text": "Illness",
        "texti": "Illness",
        "race_types": []
    },
    "Walking": {
        "image": "/images/activity_icons/Walking.svg",
        "text": "Brisk Walking",
        "texti": "Walking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Alpine Hiking": {
        "image": "/images/activity_icons/Alpine Hiking.svg",
        "text": "Alpine Speed-Hiking",
        "texti": "AlpineHiking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Hiking": {
        "image": "/images/activity_icons/Hiking.svg",
        "text": "Hiking",
        "texti": "Hiking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Skating": {
        "image": "/images/activity_icons/Skating.svg",
        "text": "Inline Skating",
        "texti": "skate",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Ice Skating": {
        "image": "/images/activity_icons/Ice Skating.svg",
        "text": "Ice Skating",
        "texti": "IceSkating",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Other": {
        "image": "/images/activity_icons/Other.svg",
        "text": "Other Sports",
        "texti": "athlet",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Brick Cycling-Running": {
        "image": "/images/activity_icons/brick.svg",
        "text": "BRICK",
        "texti": "brick",
        "race_types": []
    },
    "Brick Spinning-Running": {
        "image": "/images/activity_icons/brick_spinning.svg",
        "text": "BRICK",
        "texti": "brick_spinning",
        "race_types": []
    }
}