export const ITACTIVITYTYPE = {
"Cycling": {
    "image": "/images/activity_icons/Cycling.svg",
    "text": "Ciclismo",
    "texti": "bike",
    "transid": 2019,
    "race_types": [
        {
            "text": "Corsa 10km",
            "race_type": "10r"
        },
        {
            "text": "Corsa 5km",
            "race_type": "5r"
        },
        {
            "text": "Corsa in montagna",
            "race_type": "hr"
        },
        {
            "text": "Salita < 10min",
            "race_type": "section10"
        },
        {
            "text": "Salita < 30min",
            "race_type": "section30"
        },
        {
            "text": "Salita < 60min",
            "race_type": "section60"
        },
        {
            "text": "Ciclocross",
            "race_type": "cross"
        },
        {
            "text": "Percorso sterrato",
            "race_type": "tr"
        },
        {
            "text": "Mezza Maratona",
            "race_type": "h_mt"
        },
        {
            "text": "Criterium",
            "race_type": "crit"
        },
        {
            "text": "Maratona",
            "race_type": "mt"
        },
        {
            "text": "Cross Country MTB",
            "race_type": "cc"
        },
        {
            "text": "Maratona MT (>3h)",
            "race_type": "mtb_mt"
        },
        {
            "text": "Corsa ciclistica (> 3h)",
            "race_type": "rtf"
        },
        {
            "text": "Corsa in città",
            "race_type": "cr"
        },
        {
            "text": "Gran Fondo (>3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Percorso su pavé",
            "race_type": "sr"
        },
        {
            "text": "Gara su Strada",
            "race_type": "rr"
        },
        {
            "text": "Corsa ultra distanza ",
            "race_type": "ultra_run"
        },
        {
            "text": "Ultra Distanza Bici",
            "race_type": "ultra"
        },
        {
            "text": "Cronometro",
            "race_type": "tt"
        }
    ]
},
"Multisport_spinning": {
    "image": "/images/activity_icons/Multisport_spinning.svg",
    "text": "Accoppiato rulli",
    "texti": "brick_spinning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Multisport": {
    "image": "/images/activity_icons/Multisport.svg",
    "text": "multisport",
    "texti": "multisport",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Competition": {
    "image": "/images/activity_icons/race.svg",
    "text": "GARA",
    "texti": "RACE",
    "race_types": []
},
"No Time": {
    "image": "/images/activity_icons/No Time.svg",
    "text": "Non ho tempo",
    "texti": "NO_TIME",
    "race_types": []
},
"None": {
    "image": "/images/activity_icons/None.svg",
    "text": "nessuno",
    "texti": "none",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Triathlon": {
    "image": "/images/activity_icons/Triathlon.svg",
    "text": "Triathlon",
    "texti": "triathlon",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Iron Man",
            "race_type": "im"
        },
        {
            "text": "Mezzo Iron Man",
            "race_type": "h_im"
        },
        {
            "text": "Distanza Olimpica",
            "race_type": "olympic"
        },
        {
            "text": "Distanza in velocità",
            "race_type": "sprint"
        },
        {
            "text": "Triathlon sulla corta distanza",
            "race_type": "t_kd"
        },
        {
            "text": "Triathlon sulla media distanza",
            "race_type": "t_md"
        },
        {
            "text": "Triathlon sulla lunga distanza",
            "race_type": "t_ld"
        },
        {
            "text": "Mezza Maratona",
            "race_type": "h_mt"
        },
        {
            "text": "Maratona",
            "race_type": "mt"
        },
        {
            "text": "Maratona Mtb",
            "race_type": "mtb_mt"
        },
        {
            "text": "Gran Fondo (>3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Corsa 10km",
            "race_type": "10r"
        },
        {
            "text": "Percorso su pavé",
            "race_type": "sr"
        },
        {
            "text": "Percorso sterrato",
            "race_type": "tr"
        },
        {
            "text": "Corsa 5km",
            "race_type": "5r"
        },
        {
            "text": "Corsa in città",
            "race_type": "cr"
        },
        {
            "text": "Pista",
            "race_type": "bw"
        },
        {
            "text": "Cross Country MTB",
            "race_type": "cc"
        },
        {
            "text": "Gara su Strada",
            "race_type": "rr"
        },
        {
            "text": "Corsa",
            "race_type": "rtf"
        },
        {
            "text": "Cronometro",
            "race_type": "tt"
        },
        {
            "text": "Ciclocross",
            "race_type": "cross"
        },
        {
            "text": "Corsa in montagna",
            "race_type": "hr"
        },
        {
            "text": "Salita < 10min",
            "race_type": "section10"
        },
        {
            "text": "Salita < 30min",
            "race_type": "section30"
        },
        {
            "text": "Salita < 60min",
            "race_type": "section60"
        },
        {
            "text": "Ultra Distance Triathlon",
            "race_type": "ultra_tri"
        },
        {
            "text": "Ultra Distanza Bici",
            "race_type": "ultra"
        },
        {
            "text": "Corsa ultra distanza ",
            "race_type": "ultra_run"
        }
    ]
},
"Travel Day": {
    "image": "/images/activity_icons/Travel Day.svg",
    "text": "Viaggio",
    "texti": "TravelDay",
    "race_types": []
},
"No Training": {
    "image": "/images/activity_icons/No Training.svg",
    "text": "No training/Recovery",
    "texti": "no_training",
    "race_types": []
},
"Duathlon": {
    "image": "/images/activity_icons/Duathlon.svg",
    "text": "Duathlon",
    "texti": "duathlon",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Corsa 10km",
            "race_type": "10r"
        },
        {
            "text": "Corsa 5km",
            "race_type": "5r"
        },
        {
            "text": "Pista",
            "race_type": "bw"
        },
        {
            "text": "Salita < 10min",
            "race_type": "section10"
        },
        {
            "text": "Salita < 30min",
            "race_type": "section30"
        },
        {
            "text": "Salita < 60min",
            "race_type": "section60"
        },
        {
            "text": "Ciclocross",
            "race_type": "cross"
        },
        {
            "text": "Duathlon sulla corta distanza",
            "race_type": "d_kd"
        },
        {
            "text": "Duathlon sulla lunga distanza",
            "race_type": "d_ld"
        },
        {
            "text": "Duathlon sulla media distanza",
            "race_type": "d_md"
        },
        {
            "text": "Percorso sterrato",
            "race_type": "tr"
        },
        {
            "text": "Mezza Maratona",
            "race_type": "h_mt"
        },
        {
            "text": "Criterium",
            "race_type": "crit"
        },
        {
            "text": "Maratona",
            "race_type": "mt"
        },
        {
            "text": "Cross Country MTB",
            "race_type": "cc"
        },
        {
            "text": "Maratona Mtb",
            "race_type": "mtb_mt"
        },
        {
            "text": "Corsa",
            "race_type": "rtf"
        },
        {
            "text": "Corsa in città",
            "race_type": "cr"
        },
        {
            "text": "Gran Fondo (>3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Percorso su pavé",
            "race_type": "sr"
        },
        {
            "text": "Gara su Strada",
            "race_type": "rr"
        },
        {
            "text": "Corsa ultra distanza ",
            "race_type": "ultra_run"
        },
        {
            "text": "Ultra Distanza Bici",
            "race_type": "ultra"
        },
        {
            "text": "Ultra Distance Duathlon",
            "race_type": "ultra_dua"
        },
        {
            "text": "Cronometro",
            "race_type": "tt"
        }
    ]
},
"Long Cycling": {
    "image": "/images/activity_icons/Long Cycling.svg",
    "text": "Long-Bike",
    "texti": "LongCycling",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Mountain Biking": {
    "image": "/images/activity_icons/Mountain Biking.svg",
    "text": "Mountain Bike",
    "texti": "mtb",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Spinning": {
    "image": "/images/activity_icons/Spinning.svg",
    "text": "Spinning/Rulli",
    "texti": "spinning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Running": {
    "image": "/images/activity_icons/Running.svg",
    "text": "Podismo",
    "texti": "run",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Corsa 10km",
            "race_type": "10r"
        },
        {
            "text": "Corsa 5km",
            "race_type": "5r"
        },
        {
            "text": "Corsa in montagna",
            "race_type": "hr"
        },
        {
            "text": "Salita < 10min",
            "race_type": "section10"
        },
        {
            "text": "Salita < 30min",
            "race_type": "section30"
        },
        {
            "text": "Salita < 60min",
            "race_type": "section60"
        },
        {
            "text": "Ciclocross",
            "race_type": "cross"
        },
        {
            "text": "Percorso sterrato",
            "race_type": "tr"
        },
        {
            "text": "Mezza Maratona",
            "race_type": "h_mt"
        },
        {
            "text": "Criterium",
            "race_type": "crit"
        },
        {
            "text": "Maratona",
            "race_type": "mt"
        },
        {
            "text": "Cross Country MTB",
            "race_type": "cc"
        },
        {
            "text": "Maratona MT (>3h)",
            "race_type": "mtb_mt"
        },
        {
            "text": "Corsa ciclistica (> 3h)",
            "race_type": "rtf"
        },
        {
            "text": "Corsa in città",
            "race_type": "cr"
        },
        {
            "text": "Gran Fondo (>3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Percorso su pavé",
            "race_type": "sr"
        },
        {
            "text": "Gara su Strada",
            "race_type": "rr"
        },
        {
            "text": "Maratona test",
            "race_type": "t_mt"
        },
        {
            "text": "Corsa ultra distanza ",
            "race_type": "ultra_run"
        },
        {
            "text": "Ultra Distanza Bici",
            "race_type": "ultra"
        },
        {
            "text": "Cronometro",
            "race_type": "tt"
        }
    ]
},
"Long Running": {
    "image": "/images/activity_icons/Long Running.svg",
    "text": "Long-Run",
    "texti": "LongRunning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Trail Running": {
    "image": "/images/activity_icons/Trail Running.svg",
    "text": "Corsa Trail",
    "texti": "TrailRunning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Treadmill": {
    "image": "/images/activity_icons/Treadmill.svg",
    "text": "Tapis Roulant",
    "texti": "Treadmill",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Orienteering": {
    "image": "/images/activity_icons/Orienteering.svg",
    "text": "Corsa di orientamento",
    "texti": "Orienteering",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Swimming": {
    "image": "/images/activity_icons/Swimming.svg",
    "text": "Nuoto",
    "texti": "swim",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Open Water Swimming": {
    "image": "/images/activity_icons/Open Water Swimming.svg",
    "text": "Nuoto in acque libere",
    "texti": "OpenWaterSwimming",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Joker": {
    "image": "/images/activity_icons/Joker.svg",
    "text": "2PEAK sceglie",
    "texti": "joker",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Gym": {
    "image": "/images/activity_icons/Gym.svg",
    "text": "Allenamento Forza",
    "texti": "iron",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Yoga": {
    "image": "/images/activity_icons/Yoga.svg",
    "text": "Yoga/Pilates",
    "texti": "Yoga",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Gym Equipment": {
    "image": "/images/activity_icons/Gym Equipment.svg",
    "text": "Atrezzi Cardio Fitness",
    "texti": "GymEquipment",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Indoor Rowing": {
    "image": "/images/activity_icons/Indoor Rowing.svg",
    "text": "Canottaggio indoor",
    "texti": "IndoorRowing",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"XC Skiing": {
    "image": "/images/activity_icons/XC Skiing.svg",
    "text": "Sci",
    "texti": "skiing",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Salita < 10min",
            "race_type": "section10"
        },
        {
            "text": "Salita < 30min",
            "race_type": "section30"
        },
        {
            "text": "Salita < 60min",
            "race_type": "section60"
        },
        {
            "text": "Mezza Maratona",
            "race_type": "l_h_mt"
        },
        {
            "text": "Corta distanza",
            "race_type": "l_kd"
        },
        {
            "text": "Maratona",
            "race_type": "l_mt"
        },
        {
            "text": "Distanza Sprint",
            "race_type": "l_sd"
        },
        {
            "text": "Corsa ultra distanza ",
            "race_type": "ultra_run"
        },
        {
            "text": "Ultra Distanza Bici",
            "race_type": "ultra"
        },
        {
            "text": "Ultra Distanza Sci di fondo",
            "race_type": "ultra_skiing"
        }
    ]
},
"Roller Skiing": {
    "image": "/images/activity_icons/Roller Skiing.svg",
    "text": "Sci fondo rotelle",
    "texti": "RollerSkiing",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Ski Touring": {
    "image": "/images/activity_icons/Ski Touring.svg",
    "text": "Sci alpinismo",
    "texti": "SkiTouring",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Snow Shoes": {
    "image": "/images/activity_icons/Snow Shoes.svg",
    "text": "Racchetta Neve",
    "texti": "SnowShoes",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Sup": {
    "image": "/images/activity_icons/Sup.svg",
    "text": "Stand Up Paddling",
    "texti": "Sup",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Nordic Walking": {
    "image": "/images/activity_icons/Nordic Walking.svg",
    "text": "Camminata Nordica",
    "texti": "nw",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Illness": {
    "image": "/images/activity_icons/Illness.svg",
    "text": "Problemi di salute",
    "texti": "Illness",
    "race_types": []
},
"Walking": {
    "image": "/images/activity_icons/Walking.svg",
    "text": "Walking",
    "texti": "Walking",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Alpine Hiking": {
    "image": "/images/activity_icons/Alpine Hiking.svg",
    "text": "Hiking Alpino",
    "texti": "AlpineHiking",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Hiking": {
    "image": "/images/activity_icons/Hiking.svg",
    "text": "Escursionismo",
    "texti": "Hiking",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Skating": {
    "image": "/images/activity_icons/Skating.svg",
    "text": "Inline Skating",
    "texti": "skate",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Ice Skating": {
    "image": "/images/activity_icons/Ice Skating.svg",
    "text": "Pattinaggio su ghiaccio",
    "texti": "IceSkating",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Other": {
    "image": "/images/activity_icons/Other.svg",
    "text": "Atletica/Altri Sport",
    "texti": "athlet",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Brick Cycling-Running": {
    "image": "/images/activity_icons/brick.svg",
    "text": "COMBINATO",
    "texti": "brick",
    "race_types": []
},
"Brick Spinning-Running": {
    "image": "/images/activity_icons/brick_spinning.svg",
    "text": "COMBINATO",
    "texti": "brick_spinning",
    "race_types": []
}
}