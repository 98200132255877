export const ENTRAININGELEMENT = {
    "EMPHASIS_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_strength.gif",
        "text": "emphasis on strength",
        "description": "When you see this symbol, select a big gear and hold a cadence of 50 to max. 70 rpm. Rolling hill courses or steady climbs are especially suited to this."
    },
    "EMPHASIS_CLIMBING": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_climbing.gif",
        "text": "climbing",
        "description": "Training on hilly terrain. Including climbs in your training, you will get used to this specific load and develop your strength within the specific motion.  "
    },
    "EMPHASIS_CADENCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_cadence.gif",
        "text": "high cadence",
        "description": "Speed drills. Try to do the scheduled intervals or ideally the whole workout at a cadence which feels \"uncomfortably\" high. If this is  too strenuous, alternate high cadence intervals with recovery. High frequency develops your coordination skills  and is the basis for an increase in power output/speed. Cyclists: 100-120 rpm (revs per minute)- more still for short periods. Runners: > 90 steps per minute."
    },
    "TECHNIQUE_FOCUS": {
        "image": "https://2peak.com/images/peak_icons/training_content/technique_focus.gif",
        "text": "technique",
        "description": "Technique training increases your motoric efficiency. Execute the motion consciously and concentrate on individual segments in slow motion. Make the quality of the motion the main objective of the workout.  "
    },
    "STRETCH": {
        "image": "https://2peak.com/images/peak_icons/training_content/stretch.gif",
        "text": "stretching",
        "description": "Stretching after the workout improves and shortens recovery."
    },
    "FARTLEK": {
        "image": "https://2peak.com/images/peak_icons/training_content/fartlek.gif",
        "text": "Fartlek",
        "description": "Free form workout (Fartlek means Speed Play) in which you `play` in the landscape at different intensities and work at whatever speed you feel like. Free intervals alternate with basic endurance. Hilly terrain with lots of changes of direction is ideal - force the pace uphill, speed up downhill and otherwise just cruise."
    },
    "PYRAMID": {
        "image": "https://2peak.com/images/peak_icons/training_content/pyramid.gif",
        "text": "Pyramid",
        "description": "Interval sequence - with increasing - then decreasing duration"
    },
    "PULL_BUOY": {
        "image": "https://2peak.com/images/peak_icons/training_content/pull_buoy.gif",
        "text": "pull buoy",
        "description": "Focus on the arms using the buoy to support the legs."
    },
    "PADDLES": {
        "image": "https://2peak.com/images/peak_icons/training_content/paddles.gif",
        "text": "paddles",
        "description": "Strength exercises for the arms with paddles - mostly in combination with the buoy for stabilization.  The goal here is to increase the resistance on the hand and thus get a feel for `seizing` and shifting the water with each stroke. The speed is no higher then usual - the arms work at a lower frequency."
    },
    "KICK_BOARD": {
        "image": "https://2peak.com/images/peak_icons/training_content/kick_board.gif",
        "text": "kick board",
        "description": "Leg drills with the kick board as floating aid for the upper body. Used in both face down and supine position. Concentrate on kicking from the hip."
    },
    "WETSUIT": {
        "image": "https://2peak.com/images/peak_icons/training_content/wetsuit.gif",
        "text": "wetsuit",
        "description": "Training with a wetsuit when possible in open water. For familiarization with the higher buoyancy and improving orientation in the water."
    },
    "SPRINT_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_climb.gif",
        "text": "sprint uphill",
        "description": "This sprint exercise improves your max. strength and acceleration.  "
    },
    "SPRINT_DESCEND": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_descend.gif",
        "text": "sprint downhill",
        "description": "Use the potential energy of the slope to boost acceleration. Improves your top speed and max. cadence. The gradient forces you to spin faster than normal, schooling motoric ability.  Start on a moderate gradient."
    },
    "SPINT_DESCEND_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/spint_descend_climb.gif",
        "text": "uphill sprint",
        "description": "This exercise is executed in a downhill dip where you accelerate to your max speed. When transitioning back uphill again, leave your chain on the big ring and try to maintain speed and cadence - even when the load increases and the legs hurt. This improves your lactic tolerance and will power. Mountain bikers particularly profit from this on singletrails, race starts and technical climbs. Road racers can better handle those painful counter-climbs which occur on long descents. The ability to buffer lactic acid during accelerations is trained."
    },
    "TRACKTRAINING": {
        "image": "https://2peak.com/images/peak_icons/training_content/tracktraining.gif",
        "text": "track training",
        "description": "The track is ideal for intensive interval workouts, since you can work with precise distances. Technical drills are also best done here, taking advantage of the soft surface or grass (better) when available ."
    },
    "RUNNING_TECHNIQUE": {
        "image": "https://2peak.com/images/peak_icons/training_content/running_technique.gif",
        "text": "running drills",
        "description": "Running drills are used to exercise neuro-muscular coordination and static/mechanical ability. Including running drills in your regular training routine leads to a more dynamic and efficient running style."
    },
    "SOFTSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/softsurface.gif",
        "text": "soft surface",
        "description": "Running frequently on soft and uneven surfaces causes the many minor foot and leg muscles to gain strength, which in turn helps to reduce injury. Also running on soft surfaces and trail-running is less stressful on your body, which in turn helps fast recovery."
    },
    "HARDSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/hardsurface.gif",
        "text": "hard surface",
        "description": "Running on a hard surface helps your body to adapt to the stresses in races on tarmac. "
    },
    "OPEN_WATER": {
        "image": "https://2peak.com/images/peak_icons/training_content/open_water.gif",
        "text": "open water",
        "description": "Swimming in open water with or without wetsuit. As a triathlete you should practice open water swimming to hone your orientation skills and be at ease without that black line from the pool..."
    },
    "CIRCUIT_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_strength.gif",
        "text": "strength circuit",
        "description": "Aerobic strength exercises in circuit form.<br>Selected exercises (for example a sequence of squats, leg extension and leg curls) alternate with a main sport translation exercise.  The exercises are performed at a very low weight but with 30-40 repetitions with no pause between exercises. Translation in the main sport is 3-4 minutes of endurance work on the stationary bicycle or treadmill at Z2-Z3 intensity. After the last Circuit, spend 10 minutes on the stationary bicycle, spinning at high cadence:<br><br>Example: 10' spinning in Z2 at 90 rpm + 10' core strength exercises + 4 Circuits followed by a 3' treadmill run at Z2-3 intensity + 5-10' core strength exercises + 10' spinning  bicycle in Z2 at 90-100 rpm."
    },
    "CIRCUIT_ENDURANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_endurance.gif",
        "text": "endurance circuit",
        "description": "This  form of Circuit strength training, utilises your body weight for the exercises. Combine coordination with aerobic strength work using simple drills, such as push-ups, skipping with minimal floor contact, elongated jumps, sit-ups etc. or use the description under > Tutorials in the menu > Tools. The exercise is rotated on each full minute. <br><br>Example: 6 x (30 sec exercise / 30 sec recovery) - a full Circuit is repeated 4-6 times."
    },
    "TORSO": {
        "image": "https://2peak.com/images/peak_icons/training_content/torso.gif",
        "text": "core strength",
        "description": "To prevent muscular disbalance the core trunk muscle-groups must be trained."
    },
    "LANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/lance.gif",
        "text": "Lance-style spinning climbing",
        "description": "Fast-Forward: climbing workout with arm support."
    },
    "SKIING_XC_1_1": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_1.gif",
        "text": "skiing XC 1-1",
        "description": "1 : 1"
    },
    "SKIING_XC_1_2_ASYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_asymmetric.gif",
        "text": "skiing XC 1-2 asymmetric",
        "description": "1 : 2 Asymmetric"
    },
    "SKIING_XC_1_2_SYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_symmetric.gif",
        "text": "skiing XC 1-2 symmetric",
        "description": "1 : 2 Symmetric"
    },
    "MP_TEST": {
        "image": "https://2peak.com/images/peak_icons/training_content/mp_test.gif",
        "text": "fitness test",
        "description": "Today is an ideal day for a form test. If you decide to forgo the test, train in the base endurance zone for the proposed duration."
    },
    "RESPIRATION": {
        "image": "https://2peak.com/images/peak_icons/training_content/respiration.gif",
        "text": "respiration",
        "description": "Special exercises to improve your respiratory muscles (coaching support required). "
    },
    "MENTAL": {
        "image": "https://2peak.com/images/peak_icons/training_content/mental.gif",
        "text": "mental",
        "description": "Mental training. Once you are physically ready, it is willpower that makes the difference. The brain is one of our strongest and most important \"muscles\" and one that needs time and training to improve: willpower, intellectual ability and emotions."
    }
}