export const DEACTIVITYTYPE = {
    "Cycling": {
        "image": "/images/activity_icons/Cycling.svg",
        "text": "Rad",
        "texti": "bike", 
        "transid": 2019,
        "race_types": [
            {
                "text": "10 km Lauf",
                "race_type": "10r"
            },
            {
                "text": "5 km Lauf",
                "race_type": "5r"
            },
            {
                "text": "Berglauf",
                "race_type": "hr"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cross",
                "race_type": "cross"
            },
            {
                "text": "Geländelauf",
                "race_type": "tr"
            },
            {
                "text": "Halbmarathon",
                "race_type": "h_mt"
            },
            {
                "text": "Kriterium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "MTB Marathon (> 3h)",
                "race_type": "mtb_mt"
            },
            {
                "text": "RTF (> 3h)",
                "race_type": "rtf"
            },
            {
                "text": "Stadtlauf",
                "race_type": "cr"
            },
            {
                "text": "Strassen Marathon (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Strassenlauf",
                "race_type": "sr"
            },
            {
                "text": "Strassenrennen",
                "race_type": "rr"
            },
            {
                "text": "Lauf Ultra Distanz",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distanz Rad",
                "race_type": "ultra"
            },
            {
                "text": "Zeitfahren/Bergzeitfahren",
                "race_type": "tt"
            }
        ]
    },
    "Multisport_spinning": {
        "image": "/images/activity_icons/Multisport_spinning.svg",
        "text": "Koppel-Standrad",
        "texti": "brick_spinning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Multisport": {
        "image": "/images/activity_icons/Multisport.svg",
        "text": "multisport",
        "texti": "multisport",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Competition": {
        "image": "/images/activity_icons/race.svg",
        "text": "Wettkampf",
        "texti": "RACE",
        "race_types": []
    },
    "No Time": {
        "image": "/images/activity_icons/No Time.svg",
        "text": "Keine Zeit",
        "texti": "NO_TIME",
        "race_types": []
    },
    "None": {
        "image": "/images/activity_icons/None.svg",
        "text": "none",
        "texti": "none",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Triathlon": {
        "image": "/images/activity_icons/Triathlon.svg",
        "text": "Triathlon",
        "texti": "triathlon",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "Iron Man",
                "race_type": "im"
            },
            {
                "text": "Half Iron Man",
                "race_type": "h_im"
            },
            {
                "text": "Olympic",
                "race_type": "olympic"
            },
            {
                "text": "Sprint",
                "race_type": "sprint"
            },
            {
                "text": "Triathlon Kurzdistanz",
                "race_type": "t_kd"
            },
            {
                "text": "Triathlon Mitteldistanz",
                "race_type": "t_md"
            },
            {
                "text": "Triathlon Langdistanz",
                "race_type": "t_ld"
            },
            {
                "text": "Halbmarathon",
                "race_type": "h_mt"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Marathon",
                "race_type": "mtb_mt"
            },
            {
                "text": "Strassen Marathon (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "10 km Lauf",
                "race_type": "10r"
            },
            {
                "text": "Strassenlauf",
                "race_type": "sr"
            },
            {
                "text": "Geländelauf",
                "race_type": "tr"
            },
            {
                "text": "5 km Lauf",
                "race_type": "5r"
            },
            {
                "text": "Stadtlauf",
                "race_type": "cr"
            },
            {
                "text": "Bahnwettkampf",
                "race_type": "bw"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "Strassenrennen",
                "race_type": "rr"
            },
            {
                "text": "RTF",
                "race_type": "rtf"
            },
            {
                "text": "Zeitfahren/Bergzeitfahren",
                "race_type": "tt"
            },
            {
                "text": "Cross",
                "race_type": "cross"
            },
            {
                "text": "Berglauf",
                "race_type": "hr"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Ultra Distance Triathlon",
                "race_type": "ultra_tri"
            },
            {
                "text": "Ultra Distanz Rad",
                "race_type": "ultra"
            },
            {
                "text": "Lauf Ultra Distanz",
                "race_type": "ultra_run"
            }
        ]
    },
    "Travel Day": {
        "image": "/images/activity_icons/Travel Day.svg",
        "text": "Reisetag",
        "texti": "TravelDay",
        "race_types": []
    },
    "No Training": {
        "image": "/images/activity_icons/No Training.svg",
        "text": "Kein Training/Ruhetag",
        "texti": "no_training",
        "race_types": []
    },
    "Duathlon": {
        "image": "/images/activity_icons/Duathlon.svg",
        "text": "Duathlon",
        "texti": "duathlon",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "10 km Lauf",
                "race_type": "10r"
            },
            {
                "text": "5 km Lauf",
                "race_type": "5r"
            },
            {
                "text": "Bahnwettkampf",
                "race_type": "bw"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cross",
                "race_type": "cross"
            },
            {
                "text": "Duathlon Kurzdistanz",
                "race_type": "d_kd"
            },
            {
                "text": "Duathlon Langdistanz",
                "race_type": "d_ld"
            },
            {
                "text": "Duathlon Mitteldistanz",
                "race_type": "d_md"
            },
            {
                "text": "Geländelauf",
                "race_type": "tr"
            },
            {
                "text": "Halbmarathon",
                "race_type": "h_mt"
            },
            {
                "text": "Kriterium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "MTB Marathon",
                "race_type": "mtb_mt"
            },
            {
                "text": "RTF",
                "race_type": "rtf"
            },
            {
                "text": "Stadtlauf",
                "race_type": "cr"
            },
            {
                "text": "Strassen Marathon (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Strassenlauf",
                "race_type": "sr"
            },
            {
                "text": "Strassenrennen",
                "race_type": "rr"
            },
            {
                "text": "Lauf Ultra Distanz",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distanz Rad",
                "race_type": "ultra"
            },
            {
                "text": "Ultra Distance Duathlon",
                "race_type": "ultra_dua"
            },
            {
                "text": "Zeitfahren/Bergzeitfahren",
                "race_type": "tt"
            }
        ]
    },
    "Long Cycling": {
        "image": "/images/activity_icons/Long Cycling.svg",
        "text": "Long-Bike",
        "texti": "LongCycling",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Mountain Biking": {
        "image": "/images/activity_icons/Mountain Biking.svg",
        "text": "Mountain Bike",
        "texti": "mtb",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Spinning": {
        "image": "/images/activity_icons/Spinning.svg",
        "text": "Indoor-Rad",
        "texti": "spinning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Running": {
        "image": "/images/activity_icons/Running.svg",
        "text": "Laufen",
        "texti": "run",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "10 km Lauf",
                "race_type": "10r"
            },
            {
                "text": "5 km Lauf",
                "race_type": "5r"
            },
            {
                "text": "Berglauf",
                "race_type": "hr"
            },
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cross",
                "race_type": "cross"
            },
            {
                "text": "Geländelauf",
                "race_type": "tr"
            },
            {
                "text": "Halbmarathon",
                "race_type": "h_mt"
            },
            {
                "text": "Kriterium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "MTB Cross Country",
                "race_type": "cc"
            },
            {
                "text": "MTB Marathon (> 3h)",
                "race_type": "mtb_mt"
            },
            {
                "text": "RTF (> 3h)",
                "race_type": "rtf"
            },
            {
                "text": "Stadtlauf",
                "race_type": "cr"
            },
            {
                "text": "Strassen Marathon (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Strassenlauf",
                "race_type": "sr"
            },
            {
                "text": "Strassenrennen",
                "race_type": "rr"
            },
            {
                "text": "Testmarathon",
                "race_type": "t_mt"
            },
            {
                "text": "Lauf Ultra Distanz",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distanz Rad",
                "race_type": "ultra"
            },
            {
                "text": "Zeitfahren/Bergzeitfahren",
                "race_type": "tt"
            }
        ]
    },
    "Long Running": {
        "image": "/images/activity_icons/Long Running.svg",
        "text": "Long-Run",
        "texti": "LongRunning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Trail Running": {
        "image": "/images/activity_icons/Trail Running.svg",
        "text": "Trail-Running",
        "texti": "TrailRunning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Treadmill": {
        "image": "/images/activity_icons/Treadmill.svg",
        "text": "Laufband",
        "texti": "Treadmill",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Orienteering": {
        "image": "/images/activity_icons/Orienteering.svg",
        "text": "Orientierungslaufen",
        "texti": "Orienteering",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Swimming": {
        "image": "/images/activity_icons/Swimming.svg",
        "text": "Schwimmen",
        "texti": "swim",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Open Water Swimming": {
        "image": "/images/activity_icons/Open Water Swimming.svg",
        "text": "Freiwasserschwimmen",
        "texti": "OpenWaterSwimming",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Joker": {
        "image": "/images/activity_icons/Joker.svg",
        "text": "2PEAK wählt",
        "texti": "joker",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Gym": {
        "image": "/images/activity_icons/Gym.svg",
        "text": "Krafttraining",
        "texti": "iron",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Yoga": {
        "image": "/images/activity_icons/Yoga.svg",
        "text": "Yoga/Pilates",
        "texti": "Yoga",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Gym Equipment": {
        "image": "/images/activity_icons/Gym Equipment.svg",
        "text": "Cardio Geräte",
        "texti": "GymEquipment",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Indoor Rowing": {
        "image": "/images/activity_icons/Indoor Rowing.svg",
        "text": "Indoor Rudern",
        "texti": "IndoorRowing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "XC Skiing": {
        "image": "/images/activity_icons/XC Skiing.svg",
        "text": "Langlaufen",
        "texti": "skiing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "Climb < 10min",
                "race_type": "section10"
            },
            {
                "text": "Climb < 30min",
                "race_type": "section30"
            },
            {
                "text": "Climb < 60min",
                "race_type": "section60"
            },
            {
                "text": "Halbmarathon",
                "race_type": "l_h_mt"
            },
            {
                "text": "Kurzdistanz",
                "race_type": "l_kd"
            },
            {
                "text": "Marathon",
                "race_type": "l_mt"
            },
            {
                "text": "Sprintdistanz",
                "race_type": "l_sd"
            },
            {
                "text": "Lauf Ultra Distanz",
                "race_type": "ultra_run"
            },
            {
                "text": "Ultra Distanz Rad",
                "race_type": "ultra"
            },
            {
                "text": "Ultra Distanz Lang-Lauf",
                "race_type": "ultra_skiing"
            }
        ]
    },
    "Roller Skiing": {
        "image": "/images/activity_icons/Roller Skiing.svg",
        "text": "Roller-Ski",
        "texti": "RollerSkiing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Ski Touring": {
        "image": "/images/activity_icons/Ski Touring.svg",
        "text": "Ski-Touren",
        "texti": "SkiTouring",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Snow Shoes": {
        "image": "/images/activity_icons/Snow Shoes.svg",
        "text": "Schneeschuh-Laufen",
        "texti": "SnowShoes",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Sup": {
        "image": "/images/activity_icons/Sup.svg",
        "text": "Stand Up Paddling",
        "texti": "Sup",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Nordic Walking": {
        "image": "/images/activity_icons/Nordic Walking.svg",
        "text": "Nordic Walking",
        "texti": "nw",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Illness": {
        "image": "/images/activity_icons/Illness.svg",
        "text": "Krankheit",
        "texti": "Illness",
        "race_types": []
    },
    "Walking": {
        "image": "/images/activity_icons/Walking.svg",
        "text": "Sportliches Gehen",
        "texti": "Walking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Alpine Hiking": {
        "image": "/images/activity_icons/Alpine Hiking.svg",
        "text": "Alpines Speed-Hiking",
        "texti": "AlpineHiking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Hiking": {
        "image": "/images/activity_icons/Hiking.svg",
        "text": "Berg-Wandern",
        "texti": "Hiking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Skating": {
        "image": "/images/activity_icons/Skating.svg",
        "text": "Skating",
        "texti": "skate",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Ice Skating": {
        "image": "/images/activity_icons/Ice Skating.svg",
        "text": "Eislaufen",
        "texti": "IceSkating",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Other": {
        "image": "/images/activity_icons/Other.svg",
        "text": "Athletik / Andere Sportarten",
        "texti": "athlet",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Brick Cycling-Running": {
        "image": "/images/activity_icons/brick.svg",
        "text": "Koppeltraining",
        "texti": "brick",
        "race_types": []
    },
    "Brick Spinning-Running": {
        "image": "/images/activity_icons/brick_spinning.svg",
        "text": "Koppeltraining",
        "texti": "brick_spinning",
        "race_types": []
    }
}