export const ITTRAININGELEMENT = {
    "EMPHASIS_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_strength.gif",
        "text": "Enfasi sulla forza",
        "description": "Allenamento di forza - quando vedi questo simbolo dovresti pedalare con un rapporto lungo ad una cadenza da 50 a 70 RPM. Colline o terreni montuosi sono l'ideale. \r\n"
    },
    "EMPHASIS_CLIMBING": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_climbing.gif",
        "text": "salita",
        "description": "Allenamento in salita - Se non includi salite nel tuo allenamento avrai sempre problemi quando gareggerai in ambienti collinari. Le salite devono essere affrontate con un parte del corpo rilassata con un'impugnatura alta o alternativamente fuori sella.(\"danceuse”).\r\n"
    },
    "EMPHASIS_CADENCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_cadence.gif",
        "text": "alta cadenza",
        "description": "Allenamento orientato alla cadenza - Qui dovresti pedalare con un rapporto corto con una cadenza che varia dalle 100 alle 120 RPM. Le alte frequenze allenano il tuo motore e migliorano l'efficienza di pedalata nei periodi di preparazione."
    },
    "TECHNIQUE_FOCUS": {
        "image": "https://2peak.com/images/peak_icons/training_content/technique_focus.gif",
        "text": "tecnico",
        "description": "Skill Training Lavora su certe caratteristiche richieste molto spesso porta a maggiori benefici rispetto a materiali costosi o al semplice \"pedalare al massimo\". Dovresti specificatamente lavorare sul metodo di pedalata, di curva e di frenata."
    },
    "STRETCH": {
        "image": "https://2peak.com/images/peak_icons/training_content/stretch.gif",
        "text": "stretching",
        "description": "Lo stretching dopo l'allenamento migliora e riduce i tempi di recupero. "
    },
    "FARTLEK": {
        "image": "https://2peak.com/images/peak_icons/training_content/fartlek.gif",
        "text": "Fartlek",
        "description": "Fartlek vuol dire cambi di ritmo durante i quali adatti la velocità giocosamente al percorso e qualsiasi ritmo ti senti ad agio. Ripetute libere alternate ad velocità di base con idealmente anche tanti cambi di direzione. Forza oil passo in salita ed in discesa, altrimenti vai tranquillo. "
    },
    "PYRAMID": {
        "image": "https://2peak.com/images/peak_icons/training_content/pyramid.gif",
        "text": "Piramide",
        "description": "Sequenza di ripetute con durata prima ascendente poi discendente. "
    },
    "PULL_BUOY": {
        "image": "https://2peak.com/images/peak_icons/training_content/pull_buoy.gif",
        "text": "Pull buoy",
        "description": "Usa il pull-buoy spostando quindi il carico di lavoro sulle braccia"
    },
    "PADDLES": {
        "image": "https://2peak.com/images/peak_icons/training_content/paddles.gif",
        "text": "pagaie",
        "description": "Lavoro di condizionamento per le braccia usando le palette, normalmente in combinazione con il pull-buoy. L'obiettivo e di aumentare la resistenza sotto la mano e quindi meglio sentire come l'acqua viene spostata con ogni bracciata. Le braccia si muovono più lentamente, ma la velocità rimane per lo più la stessa."
    },
    "KICK_BOARD": {
        "image": "https://2peak.com/images/peak_icons/training_content/kick_board.gif",
        "text": "Tavoletta",
        "description": "Usa la tavola per galleggiamento e poter sprigionare potenza con le gambe. Alterna fra dorso e petto concentrandoti a iniziare il movimento dal bacino"
    },
    "WETSUIT": {
        "image": "https://2peak.com/images/peak_icons/training_content/wetsuit.gif",
        "text": "Muta",
        "description": "Usa la muta se possibile in acqua aperta. Così ti familiarizzi con l'orientamento ed il galleggiamento specifico."
    },
    "SPRINT_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_climb.gif",
        "text": "Sprint in salita",
        "description": "Volata. Qiestp esercizio di sprint migliora la tua forza massima e accelerazione. Mette anche in gioco le braccia e il tronco. "
    },
    "SPRINT_DESCEND": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_descend.gif",
        "text": "Sprint in discesa",
        "description": "Volata in discesa. Prendi vantaggio dall'accelerazione passiva della discesa. Migliora specificatamente la tua cadenza e massima velocità. La discesa tu aiuta a far girare le gambe piu' velocemente di quanto sarebbe possibile ad una bassa potenza di pedalata. La topografia ideale è quella di una discesa dritta, aperta, libera dal traffico pendente fino al 5%.\r\n"
    },
    "SPINT_DESCEND_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/spint_descend_climb.gif",
        "text": "sprint in salita",
        "description": "Volata a parabola. Questo esercizio è effettuato in fondo ad una valle, dove la discesa, dove accelerate ad alta velocità, si trasforma in salita.Lascia la catena sulla corona grande e tenta di mantenere sia la velocità che la cadenza fino a quanto le gambe iniziano a far male. Questo migliora la tua tolleranza al lattato e la forza mentale. I corridori in MTB ricevono grande profitto da questo esercizio sui single trails a salite tecniche mentre i corridori su strada sono in grado di meglio gestire forti contro-salite che spesso si trovano nelle lunghe discese."
    },
    "TRACKTRAINING": {
        "image": "https://2peak.com/images/peak_icons/training_content/tracktraining.gif",
        "text": "allenamento su pista",
        "description": "La pista é il luogo ideale per gli allenamenti intensi, visto che puoi lavorare su distanze ben misurate. Anche esercizi tecnici possono essere fatti qui traendo vantaggio dell'erba o altra superficie soffice."
    },
    "RUNNING_TECHNIQUE": {
        "image": "https://2peak.com/images/peak_icons/training_content/running_technique.gif",
        "text": "esercitazione di corsa",
        "description": "Gli esercizi tecnici aiutano a  migliorare la coordinazione neuro-muscolare. Allenandoli regolarmente rendi il tuo stile di corsa più dinamico e più efficiente."
    },
    "SOFTSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/softsurface.gif",
        "text": "superficie morbida",
        "description": "Correndo frequentemente su superfici soffici e magari irregolari stimola i vari piccoli muscoli nei piedi e nelle gambe a rafforzarsi il che riduce il rischio di infortuni. In più correre sullo sterrato riduce l'impatto il che permette di recuperare più velocemente, "
    },
    "HARDSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/hardsurface.gif",
        "text": "superticie dura",
        "description": "Correre su superfici dure ti abitua ad assorbire i colpi che prendi durante le corse sull'asfalto. "
    },
    "OPEN_WATER": {
        "image": "https://2peak.com/images/peak_icons/training_content/open_water.gif",
        "text": "acque aperte",
        "description": "Nuoto in acqua aperta con o senza muta. Come triatleta dovresti allenarti a nuotare in acque senza riga nera in basso... "
    },
    "CIRCUIT_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_strength.gif",
        "text": "circuito di potenza",
        "description": "Allenamento di resistenza a forma di circuito. <br> Esercizi come squats, ed altri per le gambe vengono alternati al movimento dello sport specifico (corsa, bici etc.) <br>\r\nGli esercizi vengono eseguiti con pesi molto ridotti ma con molte ripetizioni (30-40). La \"traduzione\" nello sport specifico dura invece 3-4 minuti sui rulli o tapis roulant in zona base. \r\n<br>\r\nAlla fine dell'ultimo circuito un'ulteriore 10 minuti di sport specifico. "
    },
    "CIRCUIT_ENDURANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_endurance.gif",
        "text": "Circuito di resistenza",
        "description": "Questo tipo di allenamento a circuito è differente in quanto usa il solo peso del corpo. Forza resistente e coordinazione dovrebbero essere le caratteristiche allenate con semplici esercizi. Esercizi come pressups, skipping statico con minimo contatto col terreno, allenamento del tronco, balzi...sono solo una parte di questo tipo di esercizio. \r\n\r\nEsempio: 4-6 giri, ad ogni stazione  uno sforzo di  30sec (6 ripetute) e 30sec di riposo. \r\n"
    },
    "TORSO": {
        "image": "https://2peak.com/images/peak_icons/training_content/torso.gif",
        "text": "Allenamento torso",
        "description": "Per ovviare a sbilanci muscolari il tronco deve essere allenato specificamente e regolarmente."
    },
    "LANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/lance.gif",
        "text": "Spinning in salita in stile Lance",
        "description": "Salita in Fast_Forward con support pronunciato delle braccia."
    },
    "SKIING_XC_1_1": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_1.gif",
        "text": "sci di fondo 1-1",
        "description": "1 : 1"
    },
    "SKIING_XC_1_2_ASYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_asymmetric.gif",
        "text": "sci di fondo 1-2 asimmetrico",
        "description": "1 : 2 Asymmetrico"
    },
    "SKIING_XC_1_2_SYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_symmetric.gif",
        "text": "sci di fondo 1-2 simmetrico",
        "description": "1 : 2 Symmetrico"
    },
    "MP_TEST": {
        "image": "https://2peak.com/images/peak_icons/training_content/mp_test.gif",
        "text": "Test di forma",
        "description": "Questa icona indica il punto ideale per fare un test di performance. Questi test dovrebbero essere fatti ad intervalli regolari. Se lo scegli, il test rimpiazzerà tutte ripetute schedati in questa giornata. \r\n"
    },
    "RESPIRATION": {
        "image": "https://2peak.com/images/peak_icons/training_content/respiration.gif",
        "text": "respirazione",
        "description": "Esercizi speciali per migliorare la muscolatura per la respirazione. "
    },
    "MENTAL": {
        "image": "https://2peak.com/images/peak_icons/training_content/mental.gif",
        "text": "mentale",
        "description": "Allenamento mentale. Una volta che sei. fisicamente pronto, diventa la forza mentale a frae la differenza. La mente é uno dei nostri \"muscoli\" più forti ed importanti. Ed uno che ha bisogno di allenamento per migliore le capacità intellettuali, emotive e la forza di volontà."
    }
}