export const ITPHASES = {
    "Start": "partenza",
    "Regeneration": "recupero",
    "Intensity": "Intensity phase",
    "Endurance": "Endurance phase",
    "Endurance before training camp": "Endurance before training camp",
    "Regeneration before training camp": "Recovery before training camp",
    "Training camp": "Training camp",
    "Regeneration after training camp": "Recovery after training camp",
    "Regeneration before competition 1": "First recovery before competition ",
    "Endurance before competition": "Endurance block before race",
    "Intensity before competition": "Intensity block before race",
    "Regeneration before competition 2": "Second recovery before competition",
    "3 Days before competition": "3 Days before race",
    "2 Days before competition": "2 Days before race",
    "1 Day before competition": "1 Day before race",
    "Competition": "Competition",
    "Regeneration after competition 1": "First recovery after competition",
    "Regeneration after competition 2": "Second recovery after competition ",
    "Illness": "Illness",
    "Regeneration after illness": "Recovery after illness"
}