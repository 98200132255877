export const ESPHASES = {
    "Start": "Comenzar",
    "Regeneration": "Regeneración",
    "Intensity": "Intensidad",
    "Endurance": "Resistencia",
    "Endurance before training camp": "Resistencia antes del campamento de entrenamiento",
    "Regeneration before training camp": "Regeneración antes del campamento de entrenamiento",
    "Training camp": "Campamento de entrenamiento",
    "Regeneration after training camp": "Regeneración después del campamento de entrenamiento",
    "Regeneration before competition 1": "Regeneración antes de la competición 1 ",
    "Endurance before competition": "Resistencia antes de la competición ",
    "Intensity before competition": "Intensidad antes de la competición ",
    "Regeneration before competition 2": "Regeneración antes de la competición 2",
    "3 Days before competition": "3 días antes de la competición",
    "2 Days before competition": "2 días antes de la competición",
    "1 Day before competition": "1 día antes de la competición",
    "Competition": "Competición",
    "Regeneration after competition 1": "Regeneración después de la competición 1",
    "Regeneration after competition 2": "Regeneración después de la competición 2",
    "Illness": "Enfermedad",
    "Regeneration after illness": "Regeneración después de una enfermedad"
}