import * as moment from "moment"
import { toIso8601String } from "../_utils/utils"
import { Block, convertBlockToJson } from "./Block"
import { convertLengthToJson, Length } from "./Length"

export interface Workout {
  docuid: number
  activity: string
  athlete_code: string
  athlete_id: number
  date: string
  equipement_id:number
  format: string
  warmup: Length
  blocks: Array<Block>
  comment: string
  length: Length
  training_elements?: Array<string>
}

export function convertToApiFormat(workout: Workout, mode: string ): any {
  return {
    "docuid": workout.docuid,
    "duration": workout.length.value, 
    "athlete_code": workout.athlete_code,
    "athlete_id": workout.athlete_id,
    "start_date": toIso8601String(moment(workout.date)),
    "recovery": workout.format === "Recovery",
    "type": workout.activity,
    "warmup": convertLengthToJson(workout.warmup),
    "blocks": workout.blocks.map(it => convertBlockToJson(it)),
    "save_mode": mode,
    "training_elements": workout.training_elements,
    "comment": workout.comment,
    "equipement_id": workout.equipement_id
  }
}
