import { Component, OnInit, OnDestroy} from '@angular/core';
import { ApiService } from "../../_services/api/api.service"
import { StorageService } from "../../_services/storage/storage.service"
import { Router } from "@angular/router"
import { Events } from '@ionic/angular';
import { AuthService } from './../../_services/auth/auth.service';
import { TranslateService } from "@ngx-translate/core"
import { NavController} from "@ionic/angular"

@Component({
  selector: 'app-coach-header-bar',
  templateUrl: './coach-header-bar.component.html',
  styleUrls: ['./coach-header-bar.component.scss'],
})
export class CoachHeaderBarComponent implements OnInit, OnDestroy {

  user: any;
  loginUserRole: string;
  storageRole: string;
  constructor(
    public navCtrl: NavController,
    private translate: TranslateService,
    private _auth: AuthService,
    private _router: Router,
    private events: Events,
    private api: ApiService,
    private storage: StorageService,
  ) { 

    this.events.subscribe('switchUser:changed', (data) => {
      this.subscribeEvent(data);
    })

    this.events.subscribe('switchUser:changedFromMenu', (data) => {
      this.subscribeEvent(data);
    })
    
  }

  subscribeEvent(data){
    if(data){
      if(localStorage.getItem('userRole')){
        this.storageRole = localStorage.getItem('userRole')
      }
      this.user = data;
      this.setConditionalMenu(this.user.roles)
      data['changeStatus'] = true;
      this.events.publish('userRole:changed', data);
    }
  }

  ngOnInit() {

    if(localStorage.getItem('userRole')){
      this.storageRole = localStorage.getItem('userRole')
    }
    this.storage.getMainUser().then( mainUser => {
      if(mainUser){
        this.storage.getUser().then( user => {
          this.user = user;
          if(this.user && this.user.roles)
           this.setConditionalMenu(this.user.roles)
        });
      }else{
        this.user = null;
      }
    });
  }

  async setConditionalMenu(roles){
    var index = await roles.findIndex( (item, index) => {
      return item=='trainer';
    })
    if(index===-1){
      this.loginUserRole = 'trainee';
    }else{
      this.loginUserRole = 'trainer';
    }
}

  async switchUser(){
    localStorage.setItem("userRole", "coach")
    await this.storage.getMainCredentials().then( cred => {
      if(cred){
        this._auth.updateToken(cred);
        this.storage.removeMainCredentials();
      }  
    });

    await this.storage.getMainUser().then( user => {
      if(user){ 
        this.storage.setUser(user);
        this.storage.removeMainUser();
      }
    });

    await this.storage.getMainUser().then( user => {
      if(!user){
        this.events.publish('newRole:trainer', true);
      }
    })

  }

  ionViewWillUnload(){
    this.events.unsubscribe('switchUser:changed');
    this.events.unsubscribe('switchUser:changedFromMenu');
  }

  ngOnDestroy(){
    this.events.unsubscribe('switchUser:changed');
    this.events.unsubscribe('switchUser:changedFromMenu');
  }


}
