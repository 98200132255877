import { HttpClient } from "@angular/common/http"
import { Injectable, OnDestroy } from "@angular/core"
import { Device } from "@ionic-native/device/ngx"
import { FileTransfer, FileTransferObject, FileUploadOptions } from "@ionic-native/file-transfer/ngx"
import { File } from "@ionic-native/file/ngx"
import { ToastController } from "@ionic/angular"
import { User } from "../../_interfaces/User"
import { StorageService } from "../storage/storage.service"
import { ApiService } from "./../api/api.service"
import { Health } from '@ionic-native/health/ngx';


declare var window

@Injectable({
  providedIn: "root"
})
export class AppleWatchService {

  workoutFilesToUpload = []         // list of workout fileEntries which have to be uploaded to 2PEAK Server
  userIdentifier = ""
  bTwoPeakUploadOngoing = false
  platformReady: Boolean = false;


  connectivity: AppleWatchConnectivity
  userToken: string
  user: User

  constructor(
    private api: ApiService,
    private storage: StorageService,
    private toastController: ToastController,
    private http: HttpClient,
    private device: Device,
    private file: File,
    private transfer: FileTransfer,
    private health: Health,
  ) { }

  async init(connectivity: AppleWatchConnectivity): Promise<void> {
    this.user = await this.storage.getUser()
    this.userToken = await this.api.getUserToken()
    this.storage.getUserObservable().subscribe(async (user) => {
      if (!user) {
        this.user = user
        this.userToken = await this.api.getUserToken()
        return
      }
    })

    this.connectivity = connectivity

    this.connectivity.init(
      () => {
        console.log("Session started")
        this.listenToNextMessage()
      },
      () => console.log("Failed to start session"),
    )
  }

  private listenToNextMessage() {
    this.connectivity.messageReceiver(
      message => this.didReceiveMessage(message),
      () => { console.log("message receive failed") }
    )
  }

  private getStatus() {
    let response

    if (true === this.api.isAuthenticated()) {
      // trigger get User Info
      this.getUserInfo()
    } else {
      response = JSON.stringify({ "user_name": "", "is_authenticated": "NO" })
      this.listenToNextMessage()
      this.connectivity.sendMessage(response,
        function () { console.log("success send msg") }, function () { console.log("failure send message") })
    }
  }

  private getUserInfo() {
    let response = JSON.stringify({ "user_name": "", "is_authenticated": "NO" })

    this.storage.getUser().then(user => {
      // always have to call this message again to get info from watch
      const userName = user.firstname + " " + user.lastname

      response = JSON.stringify({ "user_name": userName, "is_authenticated": "YES" })
      this.listenToNextMessage()
      this.connectivity.sendMessage(response, function () {
        console.log("success send msg")
      }, function () { console.log("failure send message") })
      // Upload workout files to 2PEAK server
      this.uploadWorkoutsToTwoPeak()
    }).catch((res) => {
      this.listenToNextMessage()
      this.connectivity.sendMessage(res, function () {
        console.log("success send msg")
      }, function () { console.log("failure send message") })
    })
  }

  private async getStatusExport() {
      console.log("sin getStatusExport")
      const authDataStr = await this.storage.getCredentials()
      console.log("authDataStr "+authDataStr)
      //let response = JSON.stringify(authDataStr)
      let response = authDataStr
      console.log("response "+response)
      this.listenToNextMessage()
      this.connectivity.sendMessage(response,
        function () { console.log("success send msg") }, function () { console.log("failure send message") })
  }

  private requestJsonData(urlInfo) {
    this.requestInfoFromTwoPeak(urlInfo, "")
  }

  private didReceiveMessage(message) {
    const strMessage = <AppleWatchMessage>JSON.parse(message)

    let _strDemoWorkoutType: string

    if ("getStatus" === strMessage.message) {
      this.getStatus()
    } else if ("getStatusExport" === strMessage.message) {
      this.getStatusExport()
    } else if ("getWorkoutSummaryFile" === strMessage.message) {
      this.requestJsonData("https://2peak.com/api/v2/activities/woiq?responseFormat=json_aw")
    } else if ("getDemoWorkoutSummaryFile_Cycling" === strMessage.message) {
      _strDemoWorkoutType = "Cycling"
      this.requestJsonData("https://2peak.com/api/v2/activities/woiq_samples?responseFormat=json_aw&sport=Cycling")
    } else if ("getDemoWorkoutSummaryFile_Running" === strMessage.message) {
      _strDemoWorkoutType = "Running"
      this.requestJsonData("https://2peak.com/api/v2/activities/woiq_samples?responseFormat=json_aw&sport=Running")
    } else if (strMessage.message.includes("2peak_")) {
      const requestStr = "https://2peak.com/api/v2/activities/woiq_content?responseFormat=json&id=" + strMessage.message
      // Old API call
      /*
              if (true === _twoPeakComm.isAuthenticated()) {
                  // Registered User Workout address
                  requestStr = "https://2peak.com/userdata/"+ _twoPeakComm.getUserID() + "/download/" + strMessage.message
              } else {
                  // Demo Workout address
                  requestStr = "https://2peak.com/userdata/314938/download/" + _strDemoWorkoutType + "/" + strMessage.message

              }
      */
      this.requestJsonData(requestStr)
    } else {
      // in other case: do nothing and call message receiver again
      this.listenToNextMessage()
    }

    // ask for healthkit permissions
    this.requestHealthKitRights()
  }

  // request information from 2PEAK server
  private requestInfoFromTwoPeak(urlInfo: string, message) {
    this.api.get(urlInfo).then((response) => {
      // always have to call this message again to get info from watch
      this.listenToNextMessage()
      this.connectivity.sendMessage(JSON.stringify(response),
        function () { console.log("success send msg") }, function () { console.log("failure send message") })
      return response// Print blob content as text
    }, (response) => {
      // always have to call this message again to get info from watch
      this.listenToNextMessage()
      this.connectivity.sendMessage("Fail",
        function () { console.log("success send msg") }, function () { console.log("failure send message") })
      return response.statusText
    })
  }

  // upload workouts to 2PEAK server
  private async uploadWorkoutsToTwoPeak() {
    const refreshedToken = await this.api.getUserToken(true)

    const fd = new FormData()

    // remove file from iPhone
    const removeFile = (fileEntry) => {
      const path = this.file.cacheDirectory

      window.resolveLocalFileSystemURL(path, (dir) => {
        dir.getFile(fileEntry.name, { create: false }, (fe) => {
          fe.remove(() => {
            // The file has been removed succesfully
            console.log(fe.name + " successfully removed from file system")
          }, (error) => {
            // Error deleting the file
            // alert("Remove File: " + fileEntry.name + " cannot be removed from file system!!!")
            console.log(fe.name + " cannot be removed from file system!!!")
          }, () => {
            // The file doesn't exist
            // alert("Remove File: " + fileEntry.name + " does not exist!!!")
            console.log(fe.name + " does not exist!!!")
          })
        })
      })
    }

    // upload FileEntry to given Server URL
    const uploadFile = () => {

      const uploadNextFile = () => {
        // remove file from array
        if (this.workoutFilesToUpload.length > 0) {
          this.workoutFilesToUpload.splice(0, 1)
        }

        // upload further files
        uploadFile()
      }

      if (0 === this.workoutFilesToUpload.length) {
        // nothing to upload anymore
        this.bTwoPeakUploadOngoing = false
        return
      }

      const fileEntry = this.workoutFilesToUpload[0]
      const fileURL = fileEntry.nativeURL
      const uri = encodeURI("https://2peak.com/api/v2/uploads")

      console.log("Upload File " + fileURL + " to " + uri)

      const options: FileUploadOptions = {}

      options.httpMethod = "POST"
      options.fileKey = "file"
      options.fileName = fileURL.substr(fileURL.lastIndexOf("/") + 1)
      options.chunkedMode = false
      options.mimeType = "undefined"

      const params = {}
      params["type"] = "Cycling"

      const headers = {
        "Content-Type": undefined,
        "Authorization": "Bearer " + refreshedToken
      }
      options.params = params
      options.headers = headers

      const onSuccess = (r) => {
        // update successful --> delete file from iPhone and upload next one
        // alert("Successful uploaded file " + fileEntry.nativeURL)
        console.log("Code = " + r.responseCode)
        console.log("Response = " + r.response)
        console.log("Sent = " + r.bytesSent)
        removeFile(fileEntry)
        uploadNextFile()
      }

      const onError = (error) => {
        // upload failed -> delete file from list and upload next one
        // alert("Upload File: An error has occurred: Code = " + error.code + " Source = " + error.source)

        console.log("An error has occurred: Code = " + error.code)
        if (error.http_status) {
          console.log("upload error httpstatus: " + error.http_status)
        }
        console.log("upload error source " + error.source)
        console.log("upload error target " + error.target)
        uploadNextFile()
      }
      const ft: FileTransferObject = this.transfer.create()
      ft.upload(fileURL, uri, options).then(onSuccess).catch(onError)
    }

    // search all workout files and add them to array (if they are not already present)
    const searchWorkoutFiles = (dirEntries) => {
      const dirReader = dirEntries.createReader()
      const getEntries = () => {
        dirReader.readEntries((results) => {
          let fileEntry

          // add all workout files to array
          for (let i = 0; i < results.length; i++) {
            // get item
            fileEntry = results[i]
            if (fileEntry.isFile === true) {
              // Code if its a file
              const name = fileEntry.name // File name
              if (name.includes("2peak.zip")) {
                if (this.workoutFilesToUpload.indexOf(fileEntry) === -1) {
                  this.workoutFilesToUpload.push(fileEntry)
                }
              }
            }
          }

          // trigger upload to 2PEAK (even if no files are there)
          uploadFile()
        }, (_) => {
          // error occurred
          this.bTwoPeakUploadOngoing = false
          console.log("Error when searching for workout log files to be uploaded with error code")
        })
      }
      getEntries()
    }

    if (false === this.bTwoPeakUploadOngoing) {
      this.bTwoPeakUploadOngoing = true

      // trigger workout upload to 2PEAK server
      window.resolveLocalFileSystemURL(this.file.cacheDirectory, searchWorkoutFiles, function (err) {
        this.bTwoPeakUploadOngoing = false
        console.log(err)
      })
    }
  }
  /* 
  private requestHealthKitRights() {
    let requestToWrite = []
    const currentPlatformVersion = parseInt(this.device.version, 10)
    if (currentPlatformVersion < 11) {
      // Ask for health kit permissions for execution workout-out
      requestToWrite = [
        "HKQuantityTypeIdentifierActiveEnergyBurned",
        "HKQuantityTypeIdentifierHeartRate",
        "HKQuantityTypeIdentifierDistanceWalkingRunning",
        "HKWorkoutTypeIdentifier",
        "HKQuantityTypeIdentifierDistanceCycling"
      ]
    } else {
      requestToWrite = [
        "HKQuantityTypeIdentifierActiveEnergyBurned",
        "HKQuantityTypeIdentifierHeartRate",
        "HKQuantityTypeIdentifierDistanceWalkingRunning",
        "HKWorkoutTypeIdentifier",
        "HKWorkoutRouteTypeIdentifier",
        "HKQuantityTypeIdentifierDistanceCycling"
      ]
    }
  
    const requestToRead = [
      "HKQuantityTypeIdentifierActiveEnergyBurned",
      "HKQuantityTypeIdentifierHeartRate",
      "HKQuantityTypeIdentifierDistanceWalkingRunning",
      "HKQuantityTypeIdentifierDistanceCycling"
    ]
  
    window.plugins.healthkit.requestAuthorization(
      {
        readTypes: requestToRead,
        writeTypes: requestToWrite
      },
      function () { console.log("healthkit permission given") },
      function () { console.log("Healthkit permission(s) not given!") }
    )
  } */

  /* private requestHealthKitRights() {

      this.health.isAvailable().then(bool => {
        console.log("available " + bool);
        if (bool) {
          this.platformReady = true;
          this.health.requestAuthorization([
            'distance', 'nutrition',  
            {
              read: ['steps','height', 'weight'],  
              write: ['height', 'weight']  
            }
          ])
          .then(res => console.log("response " + res))
          .catch(e => console.log("error "+e));
        }
      })
      .catch(e => console.log("error "+e));

  } */


  private requestHealthKitRights() {

    console.log("request health ", )
    let requestToWrite = []
    const currentPlatformVersion = parseInt(this.device.version, 10)
    if (currentPlatformVersion < 11) {
      // Ask for health kit permissions for execution workout-out
      requestToWrite = [
        "HKQuantityTypeIdentifierActiveEnergyBurned",
        "HKQuantityTypeIdentifierHeartRate",
        "HKQuantityTypeIdentifierDistanceWalkingRunning",
        "HKWorkoutTypeIdentifier",
        "HKQuantityTypeIdentifierDistanceCycling"
      ]
    } else {
      requestToWrite = [
        "HKQuantityTypeIdentifierActiveEnergyBurned",
        "HKQuantityTypeIdentifierHeartRate",
        "HKQuantityTypeIdentifierDistanceWalkingRunning",
        "HKWorkoutTypeIdentifier",
        "HKWorkoutRouteTypeIdentifier",
        "HKQuantityTypeIdentifierDistanceCycling"
      ]
    }

    const requestToRead = [
      "HKQuantityTypeIdentifierActiveEnergyBurned",
      "HKQuantityTypeIdentifierHeartRate",
      "HKQuantityTypeIdentifierDistanceWalkingRunning",
      "HKQuantityTypeIdentifierDistanceCycling",
      "HKQuantityTypeIdentifierHeight",
      "HKQuantityTypeIdentifierBodyMass",
      "HKCharacteristicTypeIdentifierDateOfBirth",
      "HKWorkoutTypeIdentifier",
      "HKCategoryTypeIdentifierSleepAnalysis",
      "HKQuantityTypeIdentifierDistanceWalkingRunning",
      "HKQuantityTypeIdentifierDistanceCycling"
    ]

    window.plugins.healthkit.requestAuthorization(
      {
        readTypes: requestToRead,
        writeTypes: requestToWrite
      },
      function () { console.log("healthkit permission given") },
      function () { console.log("Healthkit permission(s) not given!") }
    )
  }



}

interface AppleWatchMessage {
  message: string
}

interface AppleWatchConnectivity {
  init: (
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ) => void
  messageReceiver: (
    onNewMessageCallback: (json: string) => void,
    errorCallback: (error: any) => void,
  ) => void
  sendMessage: (
    message: string,
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ) => void
}