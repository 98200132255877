export const ESACTIVITYTYPE = {
"Cycling": {
    "image": "/images/activity_icons/Cycling.svg",
    "text": "Ciclismo",
    "texti": "bike",
    "transid": 2019,
    "race_types": [
        {
            "text": "Carrera de 10 Km",
            "race_type": "10r"
        },
        {
            "text": "Carrera de 5 Km",
            "race_type": "5r"
        },
        {
            "text": "Carrera de montaña",
            "race_type": "hr"
        },
        {
            "text": "Subida < 10min",
            "race_type": "section10"
        },
        {
            "text": "Subida < 30min",
            "race_type": "section30"
        },
        {
            "text": "Subida < 60min",
            "race_type": "section60"
        },
        {
            "text": "Bici-cross",
            "race_type": "cross"
        },
        {
            "text": "Carrera en Campo a Través",
            "race_type": "tr"
        },
        {
            "text": "Medio Maratón",
            "race_type": "h_mt"
        },
        {
            "text": "Criterium",
            "race_type": "crit"
        },
        {
            "text": "Maratón",
            "race_type": "mt"
        },
        {
            "text": "BTT Cross Country",
            "race_type": "cc"
        },
        {
            "text": "Maratón BTT (> 3h)\r\n",
            "race_type": "mtb_mt"
        },
        {
            "text": "Salida Organizada (>3hrs)",
            "race_type": "rtf"
        },
        {
            "text": "Carrera en Ciudad",
            "race_type": "cr"
        },
        {
            "text": "Maratón ciclismo en carretera (> 3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Carrera en Pavimento",
            "race_type": "sr"
        },
        {
            "text": "Carrera de Carretera\r\n",
            "race_type": "rr"
        },
        {
            "text": "Ultrafondo",
            "race_type": "ultra_run"
        },
        {
            "text": "Ciclismo Ultra Distancia",
            "race_type": "ultra"
        },
        {
            "text": "Prueba Cronometrada",
            "race_type": "tt"
        }
    ]
},
"Multisport_spinning": {
    "image": "/images/activity_icons/Multisport_spinning.svg",
    "text": "Cambios Spinning",
    "texti": "brick_spinning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Multisport": {
    "image": "/images/activity_icons/Multisport.svg",
    "text": "multisport",
    "texti": "multisport",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Competition": {
    "image": "/images/activity_icons/race.svg",
    "text": "Competicion",
    "texti": "RACE",
    "race_types": []
},
"No Time": {
    "image": "/images/activity_icons/No Time.svg",
    "text": "No hay tiempo",
    "texti": "NO_TIME",
    "race_types": []
},
"None": {
    "image": "/images/activity_icons/None.svg",
    "text": "ninguno",
    "texti": "none",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Triathlon": {
    "image": "/images/activity_icons/Triathlon.svg",
    "text": "Triatlón",
    "texti": "triathlon",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Iron Man",
            "race_type": "im"
        },
        {
            "text": "Medio Iron Man",
            "race_type": "h_im"
        },
        {
            "text": "Distancia olímpica",
            "race_type": "olympic"
        },
        {
            "text": "Distancia sprint",
            "race_type": "sprint"
        },
        {
            "text": "Triatlón de Corta Distancia",
            "race_type": "t_kd"
        },
        {
            "text": "Triatlón de Media Distancia\r\n\r\n",
            "race_type": "t_md"
        },
        {
            "text": "Triatlón de Larga Distancia\r\n",
            "race_type": "t_ld"
        },
        {
            "text": "Medio Maratón",
            "race_type": "h_mt"
        },
        {
            "text": "Maratón",
            "race_type": "mt"
        },
        {
            "text": "Maratón de Mountain Bike\r\n\r\n",
            "race_type": "mtb_mt"
        },
        {
            "text": "Maratón ciclismo en carretera (> 3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Carrera de 10 Km",
            "race_type": "10r"
        },
        {
            "text": "Carrera en Pavimento",
            "race_type": "sr"
        },
        {
            "text": "Carrera en Campo a Través",
            "race_type": "tr"
        },
        {
            "text": "Carrera de 5 Km",
            "race_type": "5r"
        },
        {
            "text": "Carrera en Ciudad",
            "race_type": "cr"
        },
        {
            "text": "Prueba en pista",
            "race_type": "bw"
        },
        {
            "text": "BTT Cross Country",
            "race_type": "cc"
        },
        {
            "text": "Carrera de Carretera\r\n",
            "race_type": "rr"
        },
        {
            "text": "Salida Organizada\r\n\r\nSalida en Bici Organizada\r\n\r\n\r\n",
            "race_type": "rtf"
        },
        {
            "text": "Prueba Cronometrada",
            "race_type": "tt"
        },
        {
            "text": "Bici-cross",
            "race_type": "cross"
        },
        {
            "text": "Carrera de montaña",
            "race_type": "hr"
        },
        {
            "text": "Subida < 10min",
            "race_type": "section10"
        },
        {
            "text": "Subida < 30min",
            "race_type": "section30"
        },
        {
            "text": "Subida < 60min",
            "race_type": "section60"
        },
        {
            "text": "Ultra Distance Triathlon",
            "race_type": "ultra_tri"
        },
        {
            "text": "Ciclismo Ultra Distancia",
            "race_type": "ultra"
        },
        {
            "text": "Ultrafondo",
            "race_type": "ultra_run"
        }
    ]
},
"Travel Day": {
    "image": "/images/activity_icons/Travel Day.svg",
    "text": "Día de viaje",
    "texti": "TravelDay",
    "race_types": []
},
"No Training": {
    "image": "/images/activity_icons/No Training.svg",
    "text": "Recuperación",
    "texti": "no_training",
    "race_types": []
},
"Duathlon": {
    "image": "/images/activity_icons/Duathlon.svg",
    "text": "Duatlón ",
    "texti": "duathlon",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Carrera de 10 Km",
            "race_type": "10r"
        },
        {
            "text": "Carrera de 5 Km",
            "race_type": "5r"
        },
        {
            "text": "Prueba en pista",
            "race_type": "bw"
        },
        {
            "text": "Subida < 10min",
            "race_type": "section10"
        },
        {
            "text": "Subida < 30min",
            "race_type": "section30"
        },
        {
            "text": "Subida < 60min",
            "race_type": "section60"
        },
        {
            "text": "Bici-cross",
            "race_type": "cross"
        },
        {
            "text": "Duatlón de Corta Distancia",
            "race_type": "d_kd"
        },
        {
            "text": "Duatlón de Larga Distancia",
            "race_type": "d_ld"
        },
        {
            "text": "Duatlón de Media Distancia",
            "race_type": "d_md"
        },
        {
            "text": "Carrera en Campo a Través",
            "race_type": "tr"
        },
        {
            "text": "Medio Maratón",
            "race_type": "h_mt"
        },
        {
            "text": "Criterium",
            "race_type": "crit"
        },
        {
            "text": "Maratón",
            "race_type": "mt"
        },
        {
            "text": "BTT Cross Country",
            "race_type": "cc"
        },
        {
            "text": "Maratón de Mountain Bike\r\n\r\n",
            "race_type": "mtb_mt"
        },
        {
            "text": "Salida Organizada\r\n\r\nSalida en Bici Organizada\r\n\r\n\r\n",
            "race_type": "rtf"
        },
        {
            "text": "Carrera en Ciudad",
            "race_type": "cr"
        },
        {
            "text": "Maratón ciclismo en carretera (> 3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Carrera en Pavimento",
            "race_type": "sr"
        },
        {
            "text": "Carrera de Carretera\r\n",
            "race_type": "rr"
        },
        {
            "text": "Ultrafondo",
            "race_type": "ultra_run"
        },
        {
            "text": "Ciclismo Ultra Distancia",
            "race_type": "ultra"
        },
        {
            "text": "Ultra Distance Duathlon",
            "race_type": "ultra_dua"
        },
        {
            "text": "Prueba Cronometrada",
            "race_type": "tt"
        }
    ]
},
"Long Cycling": {
    "image": "/images/activity_icons/Long Cycling.svg",
    "text": "Salida Larga",
    "texti": "LongCycling",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Mountain Biking": {
    "image": "/images/activity_icons/Mountain Biking.svg",
    "text": "BTT",
    "texti": "mtb",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Spinning": {
    "image": "/images/activity_icons/Spinning.svg",
    "text": "Spinning",
    "texti": "spinning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Running": {
    "image": "/images/activity_icons/Running.svg",
    "text": "Carrera a pies",
    "texti": "run",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Carrera de 10 Km",
            "race_type": "10r"
        },
        {
            "text": "Carrera de 5 Km",
            "race_type": "5r"
        },
        {
            "text": "Carrera de montaña",
            "race_type": "hr"
        },
        {
            "text": "Subida < 10min",
            "race_type": "section10"
        },
        {
            "text": "Subida < 30min",
            "race_type": "section30"
        },
        {
            "text": "Subida < 60min",
            "race_type": "section60"
        },
        {
            "text": "Bici-cross",
            "race_type": "cross"
        },
        {
            "text": "Carrera en Campo a Través",
            "race_type": "tr"
        },
        {
            "text": "Medio Maratón",
            "race_type": "h_mt"
        },
        {
            "text": "Criterium",
            "race_type": "crit"
        },
        {
            "text": "Maratón",
            "race_type": "mt"
        },
        {
            "text": "BTT Cross Country",
            "race_type": "cc"
        },
        {
            "text": "Maratón BTT (> 3h)\r\n",
            "race_type": "mtb_mt"
        },
        {
            "text": "Salida Organizada (>3hrs)",
            "race_type": "rtf"
        },
        {
            "text": "Carrera en Ciudad",
            "race_type": "cr"
        },
        {
            "text": "Maratón ciclismo en carretera (> 3h)",
            "race_type": "road_mt"
        },
        {
            "text": "Carrera en Pavimento",
            "race_type": "sr"
        },
        {
            "text": "Carrera de Carretera\r\n",
            "race_type": "rr"
        },
        {
            "text": "Maratón de prueba",
            "race_type": "t_mt"
        },
        {
            "text": "Ultrafondo",
            "race_type": "ultra_run"
        },
        {
            "text": "Ciclismo Ultra Distancia",
            "race_type": "ultra"
        },
        {
            "text": "Prueba Cronometrada",
            "race_type": "tt"
        }
    ]
},
"Long Running": {
    "image": "/images/activity_icons/Long Running.svg",
    "text": "Carrera Larga",
    "texti": "LongRunning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Trail Running": {
    "image": "/images/activity_icons/Trail Running.svg",
    "text": "Trail Running",
    "texti": "TrailRunning",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Treadmill": {
    "image": "/images/activity_icons/Treadmill.svg",
    "text": "Cinta de correr",
    "texti": "Treadmill",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Orienteering": {
    "image": "/images/activity_icons/Orienteering.svg",
    "text": "Carrera de orientación ",
    "texti": "Orienteering",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Swimming": {
    "image": "/images/activity_icons/Swimming.svg",
    "text": "nadar",
    "texti": "swim",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Open Water Swimming": {
    "image": "/images/activity_icons/Open Water Swimming.svg",
    "text": "Natación en aguas abiertas",
    "texti": "OpenWaterSwimming",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Joker": {
    "image": "/images/activity_icons/Joker.svg",
    "text": "Eligido por 2PEAK",
    "texti": "joker",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Gym": {
    "image": "/images/activity_icons/Gym.svg",
    "text": "Fuerza",
    "texti": "iron",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Yoga": {
    "image": "/images/activity_icons/Yoga.svg",
    "text": "Yoga/Pilates",
    "texti": "Yoga",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Gym Equipment": {
    "image": "/images/activity_icons/Gym Equipment.svg",
    "text": "Máquinas de cardio",
    "texti": "GymEquipment",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Indoor Rowing": {
    "image": "/images/activity_icons/Indoor Rowing.svg",
    "text": "Remo interior",
    "texti": "IndoorRowing",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"XC Skiing": {
    "image": "/images/activity_icons/XC Skiing.svg",
    "text": "Esqui",
    "texti": "skiing",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": [
        {
            "text": "Subida < 10min",
            "race_type": "section10"
        },
        {
            "text": "Subida < 30min",
            "race_type": "section30"
        },
        {
            "text": "Subida < 60min",
            "race_type": "section60"
        },
        {
            "text": "Medio Maratón",
            "race_type": "l_h_mt"
        },
        {
            "text": "Corta Distancia",
            "race_type": "l_kd"
        },
        {
            "text": "Maratón",
            "race_type": "l_mt"
        },
        {
            "text": "Distancia Sprint",
            "race_type": "l_sd"
        },
        {
            "text": "Ultrafondo",
            "race_type": "ultra_run"
        },
        {
            "text": "Ciclismo Ultra Distancia",
            "race_type": "ultra"
        },
        {
            "text": "Ultra Distancia Esqui de fondo",
            "race_type": "ultra_skiing"
        }
    ]
},
"Roller Skiing": {
    "image": "/images/activity_icons/Roller Skiing.svg",
    "text": "Roller Esqui",
    "texti": "RollerSkiing",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Ski Touring": {
    "image": "/images/activity_icons/Ski Touring.svg",
    "text": "Piel de Foca",
    "texti": "SkiTouring",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Snow Shoes": {
    "image": "/images/activity_icons/Snow Shoes.svg",
    "text": "Raquetas de nieve",
    "texti": "SnowShoes",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Sup": {
    "image": "/images/activity_icons/Sup.svg",
    "text": "SUP",
    "texti": "Sup",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Nordic Walking": {
    "image": "/images/activity_icons/Nordic Walking.svg",
    "text": "Marcha Nórdica",
    "texti": "nw",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Illness": {
    "image": "/images/activity_icons/Illness.svg",
    "text": "Enfermedad",
    "texti": "Illness",
    "race_types": []
},
"Walking": {
    "image": "/images/activity_icons/Walking.svg",
    "text": "Marcha",
    "texti": "Walking",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Alpine Hiking": {
    "image": "/images/activity_icons/Alpine Hiking.svg",
    "text": "Alpine Hiking",
    "texti": "AlpineHiking",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Hiking": {
    "image": "/images/activity_icons/Hiking.svg",
    "text": "Senderismo",
    "texti": "Hiking",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Skating": {
    "image": "/images/activity_icons/Skating.svg",
    "text": "Patinar",
    "texti": "skate",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Ice Skating": {
    "image": "/images/activity_icons/Ice Skating.svg",
    "text": "Patinaje sobre hielo",
    "texti": "IceSkating",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Other": {
    "image": "/images/activity_icons/Other.svg",
    "text": "otros deportes",
    "texti": "athlet",
    "transid": 2019,
    "aid": {
        "t2p": 5.95,
        "p2p": 6.1,
        "tr2p": 5.95,
        "b2p": 5.95,
        "r2p": 5.79,
        "x2p": 5.5
    },
    "race_types": []
},
"Brick Cycling-Running": {
    "image": "/images/activity_icons/brick.svg",
    "text": "Cambios",
    "texti": "brick",
    "race_types": []
},
"Brick Spinning-Running": {
    "image": "/images/activity_icons/brick_spinning.svg",
    "text": "Cambios",
    "texti": "brick_spinning",
    "race_types": []
}
}