import { CommonModule } from "@angular/common"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { RouteReuseStrategy } from "@angular/router"
import { OneSignal } from "@ionic-native/onesignal/ngx"
import { IonicModule, IonicRouteStrategy } from "@ionic/angular"
import { IonicStorageModule } from "@ionic/storage"
import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { AppComponent } from "./app.component"
import { SummarizeLengthPipe } from "./_pipes/summarize-length.pipe"
import { AppRoutingModule } from "./_router/app-routing.module"
import { AppleWatchModule } from "./_services/apple-watch/apple-watch.module"
import { AuthModule } from "./_services/auth/auth.module"
// import { BrowserTab } from "@ionic-native/browser-tab/ngx"
import { Health } from '@ionic-native/health/ngx';
import { APICONFIG, SHORT_NAME } from "./constants"
import { Network } from '@ionic-native/network/ngx';
import { CoachPageModule } from './coach/coach.module';
import { MySectionsPageModule } from './my-sections/my-sections.module';
/*Import separated menu */
import { CoachMenuComponentModule } from './menus/coach-menu/coach-menu.module';

@NgModule({
  declarations: [
    AppComponent,
    SummarizeLengthPipe,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot({
      // mode: "ios",
    }),
    AppRoutingModule,
    CoachPageModule,
    MySectionsPageModule,
    IonicStorageModule.forRoot({
      name: "__"+SHORT_NAME+"db",
      driverOrder: ["websql","indexeddb","localstorage"]
    }),
    HttpClientModule,
    AppleWatchModule.forRoot(),
    AuthModule.forRoot(APICONFIG),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoachMenuComponentModule
  ],
  providers: [
    OneSignal,
    Network,
    // BrowserTab,
    Health,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}
