export const ESZONE = {
    "Z1": "intensidad baja",
    "Z2_LOW": "muy fácil",
    "Z2": "muy fácil",
    "Z2_HIGH": "muy fácil",
    "Z3": "intensidad media",
    "Z4_LOW": "intensidad alta",
    "Z4_HIGH": "intensidad alta",
    "Z5": "intensidad más alta",
    "Z5_SHORT": "intensidad más alta"
}