import { Pipe, PipeTransform } from "@angular/core"
import { getLengthSummary, Length } from "../_interfaces/Length"

@Pipe({
  name: "summarizeLength"
})
export class SummarizeLengthPipe implements PipeTransform {

  transform(value: Length, args?: any): string {
    return getLengthSummary(value)
  }

}
