import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import { ActivityTypes } from "../../_interfaces/ActivityTypes";
import { ApiActivity } from "../../_interfaces/ApiActivity";
import { Dashboard } from "../../_interfaces/Dashboard";
import { Goals } from "../../_interfaces/Goals";
import { Race } from "../../_interfaces/Race";
import { Weekplan } from "../../_interfaces/Schedule";
import { TrainingMeter } from "../../_interfaces/TrainingMeter";
import { TrainingZones, ZoneNames } from "../../_interfaces/TrainingZones";
import { RegisteringUser, User } from "../../_interfaces/User";


const USER_KEY = "USER"
const MAIN_USER_KEY = "MAIN_USER"
const FUTURE_RACES = "FUTURE_RACES"
const ACTIVITY_TYPES = "ACTIVITY_TYPES"
const ZONE_NAMES = "ZONE_NAMES"
const WEEKPLAN = "WEEKPLAN"
const DAY_ACTIVITIES = "DAY_ACTIVITIES_"
const TRAINING_ZONES = "TRAINING_ZONES"
const TRAINING_METER = "TRAINING_METER"
const GOALS = "GOALS"
const REGISTERING_USER = "REGISTERING_USER"
const CREDENTIALS = "CREDENTIALS"
const MAIN_CREDENTIALS = "MAIN_CREDENTIALS"
const DASHBOARD = "DASHBOARD"
const CURRENT_OFFSET = "CURRENT_OFFSET"
const INREVIEW = "INREVIEW"
const VER = "VER"
const TRAINING_ELEMENTS = "TRAINING_ELEMENTS"
const TOUR_GUIDE = "appTourGuide";
const VISIT_COUNT = "VISIT_COUNT";
const SCACTIVITYOBJ = 'SCACTIVITYOBJ'

@Injectable({
  providedIn: "root"
})
export class StorageService {

  private user = new Subject<User>()
  private thisWeekActivities = new Subject<Array<ApiActivity>>()
  private futureRaces = new Subject<Array<Race>>()
  private weekplan = new Subject<Weekplan>()
  private dayActivities = {}
  private trainingElements = {}
  private trainingZones = new Subject<TrainingZones>()
  private trainingMeter = new Subject<TrainingMeter>()
  private dashboard = new Subject<Dashboard>()

  constructor(
    public storage: Storage,
  ) {
    const start = moment().startOf("isoWeek")
    for (let i = 0; i <= 6; i++) {
      const newStart = start.clone().add(i, "days")
      this.createNewDayObservable(newStart.format("YYYY-MM-DD"))
    }
  }

  public async clear(): Promise<void> {
    await this.storage.clear()
  }

  //  USER
  public async getUser() {
    return await this.storage.get(USER_KEY).then((val: User) => val)
  }

  public async setUser(user: User) {
    await this.storage.set(USER_KEY, user)
    this.user.next(user)
  }

  // Coach User
  public async getMainUser() {
    return await this.storage.get(MAIN_USER_KEY).then((val: User) => val)
  }
  public async setMainUser(user: User) {
    await this.storage.set(MAIN_USER_KEY, user)
    this.user.next(user)
  }
  public async removeMainUser() {
    await this.storage.remove(MAIN_USER_KEY)
  }


  public getUserObservable(): Observable<User> {
    return this.user.asObservable()
  }

  // DASHBOARD
  public async getDashboard() {
    return await this.storage.get(DASHBOARD).then((val: Dashboard) => val)
  }

  public async setDashboard(db: Dashboard) {
    await this.storage.set(DASHBOARD, db)
    this.dashboard.next(db)
  }

  //offset in order no to call the jetlag alert more than once
  public async setCurrentOffset(off) {
    await this.storage.set(CURRENT_OFFSET, off)
    this.dashboard.next(off)
  }

  public async getCurrentOffset() {
    return await this.storage.get(CURRENT_OFFSET)
  }

  public getDashboardObservable(): Observable<Dashboard> {
    return this.dashboard.asObservable()
  }

  // FUTURE RACES
  public async getFutureRaces() {
    return await this.storage.get(FUTURE_RACES)
  }

  public async setFutureRaces(races: Array<Race>) {
    await this.storage.set(FUTURE_RACES, races)
    this.futureRaces.next(races)
  }

  public getFutureRacesObservable(): Observable<Array<Race>> {
    return this.futureRaces.asObservable()
  }

  // WEEKPLAN
  public async getWeekplan(): Promise<Weekplan> {
    return await this.storage.get(WEEKPLAN)
  }

  public async setWeekplan(weekplan: Weekplan) {
    await this.storage.set(WEEKPLAN, weekplan)
    this.weekplan.next(weekplan)
  }

  public getWeekplanObservable(): Observable<Weekplan> {
    return this.weekplan.asObservable()
  }

  // DAY ACTIVITIES
  private createNewDayObservable(date: string): void {
    this.dayActivities[date] = new Subject<Array<ApiActivity>>()
  }

  public async getDayActivities(date: string): Promise<Array<ApiActivity>> {
    const activities = await this.storage.get(DAY_ACTIVITIES + date)
    return activities || []
  }

  public getDayActivitiesObservable(date: string): Observable<Array<ApiActivity>> {
    if (!this.dayActivities[date]) {
      this.createNewDayObservable(date)
    }
    return this.dayActivities[date].asObservable()
  }

  public async setDayActivities(date: string, activities: Array<ApiActivity>) {
    await this.storage.set(DAY_ACTIVITIES + date, activities)
    if (!this.dayActivities[date]) {
      this.createNewDayObservable(date)
    }
    this.dayActivities[date].next(activities)
  }

  // TRAINING ZONES
  public async getTrainingZones(): Promise<TrainingZones> {
    return await this.storage.get(ZONE_NAMES)
  }

  public async getTrainingZonesFull(): Promise<TrainingZones> {
    return await this.storage.get(TRAINING_ZONES)
  }

  public async setTrainingZones(trainingZones: TrainingZones) {
    await this.storage.set(TRAINING_ZONES, trainingZones)
    this.trainingZones.next(trainingZones)
  }

  public async setTrainingElements(trainingElem) {
    await this.storage.set(TRAINING_ELEMENTS, trainingElem)
    this.trainingZones.next(trainingElem)
  }

  public async getTrainingElements() {
    return await this.storage.get(TRAINING_ELEMENTS)
  }

  public getTrainingZonesObservable(): Observable<TrainingZones> {
    return this.trainingZones.asObservable()
  }

  // TRAINING METER
  public async getTrainingMeter(): Promise<TrainingMeter> {
    return await this.storage.get(TRAINING_METER)
  }

  public async setTrainingMeter(trainingMeter: TrainingMeter) {
    await this.storage.set(TRAINING_METER, trainingMeter)
    this.trainingMeter.next(trainingMeter)
  }

  public getTrainingMeterObservable(): Observable<TrainingMeter> {
    return this.trainingMeter.asObservable()
  }

  // ACTIVITY TYPES
  public async getActivityTypes(): Promise<ActivityTypes> {
    return await this.storage.get(ACTIVITY_TYPES)
  }

  public async setActivityTypes(activityTypes: ActivityTypes) {
    await this.storage.set(ACTIVITY_TYPES, activityTypes)
  }

  // ZONE NAMES
  public async getZoneNames(): Promise<ZoneNames> {
    return await this.storage.get(ZONE_NAMES)
  }

  public async setZoneNames(zoneNames: ZoneNames) {
    await this.storage.set(ZONE_NAMES, zoneNames)
  }

  // GOALS
  public async getGoals(): Promise<Goals> {
    return await this.storage.get(GOALS)
  }

  public async setGoals(goals: Goals) {
    await this.storage.set(GOALS, goals)
  }

  // CREDENTIALS
  public async getCredentials(): Promise<string> {
    return await this.storage.get(CREDENTIALS)
  }

  public async setCredentials(credentials: string) {
    await this.storage.set(CREDENTIALS, credentials)
  }
  
  

  // Main CREDENTIALS
  public async getMainCredentials(): Promise<string> {
    return await this.storage.get(MAIN_CREDENTIALS)
  }
  public async setMainCredentials(credentials) {
    await this.storage.set(MAIN_CREDENTIALS, credentials)
  }
  public async removeMainCredentials(){
    await this.storage.remove(MAIN_CREDENTIALS)
  }

  


  public async removeByKey() {
    await this.storage.remove(USER_KEY)
    await this.storage.remove(CREDENTIALS)
    await this.storage.remove(MAIN_USER_KEY)
    await this.storage.remove(MAIN_CREDENTIALS)
    await this.storage.remove(GOALS)
    await this.storage.remove(DASHBOARD)
    await this.storage.remove(WEEKPLAN)
    await this.storage.remove(FUTURE_RACES)
    await this.storage.remove(TRAINING_METER)
    await this.storage.remove(TRAINING_ZONES);
    return true;
  }


  public async setisUserLoggedIn(set: string) {
    console.log("isUserLoggedIn")
    await this.storage.set("isUserLoggedInNew", set)
  }

  public async getisUserLoggedIn() {
    return await this.storage.get("isUserLoggedInNew")
  }

  // REGIRSTERING USER
  public async getRegisteringUser(): Promise<RegisteringUser> {
    return await this.storage.get(REGISTERING_USER)
  }

  public async setRegisteringUser(ru: RegisteringUser) {
    await this.storage.set(REGISTERING_USER, ru)
  }

  public async removeRegisteringUser() {
    await this.storage.remove(REGISTERING_USER)
  }

  // INREVIEW (hide registration & shop etc...)
  public async removeInReview() {
    return await this.storage.remove(INREVIEW)
  }

  public async getInReview() {
    return await this.storage.get(INREVIEW)
  }

  public async setInReview(resp) {
    await this.storage.set(INREVIEW, resp)
  }


  public async setVersion(resp) {
    await this.storage.set(VER, resp)
  }

  public async getVersion() {
    return await this.storage.get(VER)
  }

  /* 
  * Set get and remove Activity Object
  * It made during add new workout in schedule section
  */
  public async setActivityObject(resp) {
    await this.storage.set(SCACTIVITYOBJ, resp)
  }
  public async getActivityObject() {
    return await this.storage.get(SCACTIVITYOBJ)
  }
  public async RemoveActivityObject() {
    return await this.storage.remove(SCACTIVITYOBJ)
  }

  



  /*
  * Set local database for track total visit in app
  * Smartdata
  */
  public setUserAppVisit() {
    let num = "0";
    if(JSON.parse(localStorage.getItem('visitCount'))){
      const visit_count =  JSON.parse(localStorage.getItem('visitCount'));
      num = visit_count + 1;
      localStorage.setItem('visitCount', num)
      return num;
    }else{
      num = "1";
      localStorage.setItem('visitCount', num)
    }
    return parseInt(num);
  }

  

  /*
  * Set local database for creating storage for tour guild
  * if user visit first time, it will stored
  * Smartdata
  */
  public async setTourStorage(data) {
    await this.storage.set(TOUR_GUIDE, data)
  }

  /*
  * Get storage for tour guild
  * if exit, return true else false
  * Smartdata
  */
  public async getTourStorage(type) {
    var checkExist = false;
    var data = [];
    await this.storage.get(TOUR_GUIDE).then(res => {
      if(res){
        data = res;
        res.forEach(data => {
          if(data==type){
            checkExist = true;
          }
        });
      }
    });
   
    return {isExist: checkExist, data: data};
  }

}
