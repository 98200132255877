export const ENZONE = {
    "Z1": "lowest intensity (very easy)",
    "Z2_LOW": "low intensity (easy)",
    "Z2": "low intensity (easy)",
    "Z2_HIGH": "low intensity (easy)",
    "Z3": "medium intensity",
    "Z4_LOW": "high intensity",
    "Z4_HIGH": "high intensity",
    "Z5": "highest intensity",
    "Z5_SHORT": "highest intensity"
}