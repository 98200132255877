export const DEPHASES = {
    "Start": "Start",
    "Regeneration": "Regeneration",
    "Intensity": "Intensitätsphase ",
    "Endurance": "Ausdauerphase",
    "Endurance before training camp": "Ausdauer vor TL",
    "Regeneration before training camp": "Erholung vor TL",
    "Training camp": "TRAININGSLAGER",
    "Regeneration after training camp": "Regeneration nach TL",
    "Regeneration before competition 1": "Erste Regeneration vor Wettkampf",
    "Endurance before competition": "Ausdauerblock vor WK",
    "Intensity before competition": "Intensitätsblock vor WK",
    "Regeneration before competition 2": "Zweite Regeneration vor WK",
    "3 Days before competition": "3 Tage vor WK",
    "2 Days before competition": "2 Tage vor WK",
    "1 Day before competition": "1 Tag vor WK",
    "Competition": "WETTKAMPF!",
    "Regeneration after competition 1": "Erste Regeneration nach WK",
    "Regeneration after competition 2": "Zweite Regeneration nach WK",
    "Illness": "Krankheit",
    "Regeneration after illness": "Regeneration nach Krankheit"
}