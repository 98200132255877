import { AlertController, ToastController, LoadingController, Events} from "@ionic/angular"
import { TranslateService } from "@ngx-translate/core"
import { ACTIVITY_OPTIONS, PRIMARY_COLOR_SHADE, LIGHT_COLOR_SHADE, BEATING_2P_OPTIONS, KOMMUNICATE_KEY, APP_NAME, SHORT_NAME, KOMMUNICATE_EMAIL, WO_CREATOR_DATE, VERSION } from "../constants"
import { getLengthSummary, Length, LengthUnit } from "../_interfaces/Length"
import { Workout } from "../_interfaces/Workout"
import { IntervalPart } from "./../_interfaces/Block"
import { Plugins, Capacitor, StatusBarStyle } from "@capacitor/core"
import { ApiService } from "../_services/api/api.service"
import { User } from "../_interfaces/User"
import * as moment from "moment"

declare var kommunicate: any

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

//"displayName" : "Some name",
export async function openChat(userId: number, plt : string) {
  var kmUser = {
    "userId" : userId.toString(),
    "applicationId" : KOMMUNICATE_KEY,
    "metadata": {
      "source": "APP: "+SHORT_NAME+" "+VERSION+" "+plt,
      "id": userId.toString()}
  }

  const conversationObject = {
     "appId" : KOMMUNICATE_KEY,
     "groupName" : APP_NAME + " Support",
     "kmUser" : JSON.stringify(kmUser)
  }

  kommunicate.conversationBuilder(conversationObject, (clientChannelKey) => {
     //The response will be a clientChannelKey which is used for launching a particular conversation.
     console.log("Kommunicate create conversation successful the clientChannelKey is : " + clientChannelKey);
  }, (error) =>{
      console.log("Kommunicate create conversation failed : " + error);
  })



}

export async function logoutChat() {
  kommunicate.logout((response) => {
    }, (response) => {
    })
}


export async function launchRecalculation(api: ApiService, loadingCtrl: LoadingController) {
  const beating2P = await loadingCtrl.create(BEATING_2P_OPTIONS)
  await beating2P.present()
  try {
    await api.runCalculation()
    beating2P.dismiss()
  } catch (e) {
    beating2P.dismiss()
    return
  }
}

export async function activateTrial(api: ApiService, loadingCtrl: LoadingController) {
  const beating2P = await loadingCtrl.create(BEATING_2P_OPTIONS)
  await beating2P.present()
  try {
    await api.activateTrial()
    await this.api.fetchUser()
    await this.api.fetchBattery()
    await this.api.fetchDashboard()
    beating2P.dismiss()
  } catch (e) {
    beating2P.dismiss()
    return
  }
}

export function isWOCreatorAppOnlyUtil(user: User, event: Events) {

  if(!user) {
    console.log("isWOCreatorAppOnlyUtil fct no user")
  }

  if (!user) {
    return false
  }
  else if (user.package_name == "Basic Fitness" && user.used_trial == "false" && (String(SHORT_NAME) == "p2p" || String(SHORT_NAME) == "pwo") && !moment(user.registration_date).isBefore(moment(WO_CREATOR_DATE)) ){
    return true
  }
  else {
    event.publish('isWOCreatorAppOnlyVar:changed', false);
    return false
  }
}

export function encodeParams(p: any): string {
  return Object.entries(p).map(kv => kv.map(encodeURIComponent).join(`=`)).join(`&`)
}

export function decodeParams(u: string): any {
  return JSON.parse(
    "{\"" + u.replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}",
    function(key, value) { return key === "" ? value : decodeURIComponent(value)})
}

export function setDarkStatusBar() {
  if (Capacitor.isPluginAvailable("StatusBar")) {
    Plugins.StatusBar.setBackgroundColor({ color: PRIMARY_COLOR_SHADE })
    Plugins.StatusBar.setStyle({ style: StatusBarStyle.Dark })
  }
}

export function setLightStatusBar() {
  if (Capacitor.isPluginAvailable("StatusBar")) {
    Plugins.StatusBar.setBackgroundColor({ color: LIGHT_COLOR_SHADE })
    Plugins.StatusBar.setStyle({ style: StatusBarStyle.Light })
  }
}

export function formatActivity(workout: Workout) {
  let cumulatedOffset = 0

  const intervals = []

  if (!workout.blocks) {
    return intervals
  }

  if (workout.warmup) {
    intervals.push(generateInterval({
      length: workout.warmup,
      zone: `Z2`,
    }, cumulatedOffset))
    cumulatedOffset += workout.warmup.value
  }
  workout.blocks.forEach((block, _) => {
    for (let s = 0; s < block.sets; s++) {
      for (let r = 0; r < block.reps; r++) {
        block.intervals.forEach((interval, __) => {
          intervals.push(generateInterval(interval.work, cumulatedOffset))
          cumulatedOffset += interval.work.length.value
          intervals.push(generateInterval(interval.rest, cumulatedOffset))
          cumulatedOffset += interval.rest.length.value
        })
      }
      if (block.betweenSetsRest) {
        intervals.push(generateInterval({
          length: block.betweenSetsRest,
          zone: `Z2`,
        }, cumulatedOffset))
        cumulatedOffset += block.betweenSetsRest.value
      }
    }
    if (block.afterRest) {
      intervals.push(generateInterval({
        length: block.afterRest,
        zone: `Z2`,
      }, cumulatedOffset))
      cumulatedOffset += block.afterRest.value
    }
  })
  return {
    intervals: intervals,
    duration: cumulatedOffset,
  }
}

export async function showNoInternetBanner(toast: ToastController, translate: TranslateService) {
  toast.create({
    duration: 1000,
    message: translate.instant("ERROR_REFRESH"),
  }).then(t => t.present())
}

export function getNiceDuration(duration: number): string {
  return getLengthSummary(<Length>{
    value: duration,
    unit: LengthUnit.seconds,
  })
}


export function getWODuration(duration: Length): string {
  if(duration != undefined && duration.unit == "s") {
    // Minutes and seconds
    let mins = ~~(duration.value / 60)
    let secs = duration.value % 60

    // Hours, minutes and seconds
    const hrs = ~~(duration.value / 3600)
    mins = ~~((duration.value % 3600) / 60)
    secs = duration.value % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = ""

    //if (hrs > 0) {
      ret += "" + hrs + "h" + (mins < 10 ? "0" : "")
      ret += "" + mins
    // } else if (mins > 0) {
    //   ret += "" + mins + "'"
    // } else if (secs > 0) {
    //   secs = Math.round(secs)
    //   ret += "" + (secs < 10 ? "0" : "") + secs + "\""
    // }
    return ret
  }
  else {
    return "0h00"
  }
  // return getLengthSummary(<Length>{
  //   value: duration,
  //   unit: LengthUnit.seconds,
  // })
}

export async function showDiscardDialog(alert: AlertController, goAhead: () => void, cancel: () => void, translate: TranslateService) {
  const a = await alert.create({
    header: translate.instant("WARNING"),
    message: translate.instant("DATA_WONT_SAVE"),
    buttons: [
      {
        text: translate.instant("CANCEL"),
        role: "cancel",
        cssClass: "secondary",
        handler: () => {
          cancel()
        }
      }, {
        text: translate.instant("I_KNOW"),
        handler: () => {
          goAhead()
        }
      }
    ]
  })
  a.present()
}


export async function showCancleDialog(alert: AlertController, routeLink, router, params, cancel: () => void, translate: TranslateService) {
  const a = await alert.create({
    header: translate.instant("WARNING"),
    message: translate.instant("DATA_WONT_SAVE"),
    buttons: [
      {
        text: translate.instant("CANCEL"),
        role: "cancel",
        cssClass: "secondary",
        handler: () => {
          cancel()
        }
      }, {
        text: translate.instant("I_KNOW"),
        handler: () => {
          router.navigate([routeLink], { queryParams: params })
        }
      }
    ]
  })
  a.present()
}

function generateInterval(ip: IntervalPart, cumulatedOffset: number) {
  return {
    starttime: cumulatedOffset,
    zone: ip.zone,
    duration: ip.length.value,
    length: ip.length.value / 60,
    // avg: {
    //   power: 0,
    //   pulse: 135,
    //   speed: 0
    // },
    interval_unit: ip.length.unit === LengthUnit.meters ? `min` : `sec`,
    // range: {
    //   cadence: [
    //     70,
    //     90
    //   ]
    // }
  }
}

export function formatIntervalsForServer(workout: Workout): any {
  const properIntervals = {}
  let cumulatedOffset = 0
  if (!workout.blocks) {
    return []
  }

  if (!workout.blocks.forEach) {
    return []
  }

  if (workout.warmup) {
    cumulatedOffset += workout.warmup.value
  }

  let index = 0
  workout.blocks.forEach((block, blockIndex) => {
    block.intervals.forEach((interval, intervalIndex) => {
      const properInterval = {
        Intervals: block.reps,
        Sets: block.sets,
        Interval: interval.work.zone,
        IntervalUnit: interval.work.length.unit === LengthUnit.seconds ? `sec` : `m`,
        IntervalLength: interval.work.length.value,
        Active: 1,
        TypeIV: "USER",
        IntervalAVG: null,
        IntervalAVGUnit: null,
        IntervalCadence: null,
        IntervalDistance: null,
        StartDistance: null,
        IntervalPulse: null,
        IntervalPower: null,
        IntervalSpeed: null,
        StarttimeForm: 0,
        Starttime: cumulatedOffset,

        BaseDuration: 0,
        Duration: 0,
      }
      if (interval.work.length.unit === LengthUnit.seconds) {
        properInterval.BaseDuration = block.sets * block.reps * interval.work.length.value * 2
        properInterval.Duration = properInterval.BaseDuration
        cumulatedOffset += properInterval.BaseDuration
      }
      if (interval.work.length.unit === LengthUnit.meters) {
        properInterval.IntervalDistance = interval.work.length.value
      }
      properIntervals[index] = properInterval
      index++

      if (block.afterRest) {
        cumulatedOffset += block.afterRest.value
      }
    })
  })

  return {
    [ACTIVITY_OPTIONS[workout.activity].texti]: properIntervals,
  }
}

export function toIso8601String(date: moment.Moment): string {
  const year = date.year()
  const month = date.month() + 1
  const day = date.date()
  const hour = date.hour() 
  const minute = date.minute()
  const second = date.second()
  const y =
    (year >= -9999 && year <= 9999) ? _fourDigits(year) : _sixDigits(year)
  const m = _twoDigits(month)
  const d = _twoDigits(day)
  const h = _twoDigits(hour)
  const min = _twoDigits(minute)
  const sec = _twoDigits(second)

  return `${y}-${m}-${d}T${h}:${min}:${sec}+0200`
}

export function toIso8601StringForBefore(date: moment.Moment): string {
  const year = date.year()
  const month = date.month() + 1
  const day = date.date()
  const hour = (date.hour() - 7)
  const minute = date.minute()
  const second = date.second()
  const y =
    (year >= -9999 && year <= 9999) ? _fourDigits(year) : _sixDigits(year)
  const m = _twoDigits(month)
  const d = _twoDigits(day)
  const h = _twoDigits(hour)
  const min = _twoDigits(minute)
  const sec = _twoDigits(second)

  return `${y}-${m}-${d}T${h}:${min}:${sec}+0200`
}

function _twoDigits(n: number): string {
  if (n >= 10) { return `${n}` }
  return `0${n}`
}

function _fourDigits(n: number): string {
  const absN = Math.abs(n)
  const sign = n < 0 ? `-` : ``
  if (absN >= 1000) { return `${n}` }
  if (absN >= 100) { return `${sign}0${absN}` }
  if (absN >= 10) { return `${sign}00${absN}` }
  return `${sign}000${absN}`
}

function _sixDigits(n: number): string {
  const absN = Math.abs(n)
  const sign = n < 0 ? `-` : `+`
  if (absN >= 100000) { return `${sign}${absN}` }
  return `${sign}0${absN}`
}

function _threeDigits(n: number): string {
  if (n >= 100) { return `${n}` }
  if (n >= 10) { return `0${n}` }
  return `00${n}`
}
