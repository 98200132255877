export const FRACTIVITYTYPE = {
    "Cycling": {
        "image": "/images/activity_icons/Cycling.svg",
        "text": "Cyclisme",
        "texti": "bike",
        "transid": 2019,
        "race_types": [
            {
                "text": "Course 10 km",
                "race_type": "10r"
            },
            {
                "text": "Course de 5 km",
                "race_type": "5r"
            },
            {
                "text": "Course en montagne",
                "race_type": "hr"
            },
            {
                "text": "Montée < 10min",
                "race_type": "section10"
            },
            {
                "text": "Montée < 30min",
                "race_type": "section30"
            },
            {
                "text": "Montée < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Course De Fond",
                "race_type": "tr"
            },
            {
                "text": "Semi-Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Critérium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "Cross Country VTT",
                "race_type": "cc"
            },
            {
                "text": "Marathon VTT (> 3h)",
                "race_type": "mtb_mt"
            },
            {
                "text": "Course Organisée (> 3h)",
                "race_type": "rtf"
            },
            {
                "text": "Course en ville",
                "race_type": "cr"
            },
            {
                "text": "Marathon cycliste (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Course sur Route",
                "race_type": "sr"
            },
            {
                "text": "Course sur Route",
                "race_type": "rr"
            },
            {
                "text": "Ultrafond",
                "race_type": "ultra_run"
            },
            {
                "text": "Cyclisme Ultra Distance",
                "race_type": "ultra"
            },
            {
                "text": "Contre la Montre",
                "race_type": "tt"
            }
        ]
    },
    "Multisport_spinning": {
        "image": "/images/activity_icons/Multisport_spinning.svg",
        "text": "Enchainement Spinning",
        "texti": "brick_spinning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Multisport": {
        "image": "/images/activity_icons/Multisport.svg",
        "text": "multisport",
        "texti": "multisport",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Competition": {
        "image": "/images/activity_icons/race.svg",
        "text": "COMPETITION",
        "texti": "RACE",
        "race_types": []
    },
    "No Time": {
        "image": "/images/activity_icons/No Time.svg",
        "text": "Pas de temps disponible",
        "texti": "NO_TIME",
        "race_types": []
    },
    "None": {
        "image": "/images/activity_icons/None.svg",
        "text": "aucun",
        "texti": "none",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Triathlon": {
        "image": "/images/activity_icons/Triathlon.svg",
        "text": "Triathlon",
        "texti": "triathlon",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "Iron Man",
                "race_type": "im"
            },
            {
                "text": "Semi Iron Man",
                "race_type": "h_im"
            },
            {
                "text": "Distance Olympique",
                "race_type": "olympic"
            },
            {
                "text": "Distance de Sprint",
                "race_type": "sprint"
            },
            {
                "text": "Triathlon Courte Distance",
                "race_type": "t_kd"
            },
            {
                "text": "Triathlon Moyenne Distance",
                "race_type": "t_md"
            },
            {
                "text": "Triathlon Longue Distance",
                "race_type": "t_ld"
            },
            {
                "text": "Semi-Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "Marathon VTT",
                "race_type": "mtb_mt"
            },
            {
                "text": "Marathon cycliste (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Course 10 km",
                "race_type": "10r"
            },
            {
                "text": "Course sur Route",
                "race_type": "sr"
            },
            {
                "text": "Course De Fond",
                "race_type": "tr"
            },
            {
                "text": "Course de 5 km",
                "race_type": "5r"
            },
            {
                "text": "Course en ville",
                "race_type": "cr"
            },
            {
                "text": "Suivre l'évènement",
                "race_type": "bw"
            },
            {
                "text": "Cross Country VTT",
                "race_type": "cc"
            },
            {
                "text": "Course sur Route",
                "race_type": "rr"
            },
            {
                "text": "Course Organisée",
                "race_type": "rtf"
            },
            {
                "text": "Contre la Montre",
                "race_type": "tt"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Course en montagne",
                "race_type": "hr"
            },
            {
                "text": "Montée < 10min",
                "race_type": "section10"
            },
            {
                "text": "Montée < 30min",
                "race_type": "section30"
            },
            {
                "text": "Montée < 60min",
                "race_type": "section60"
            },
            {
                "text": "Ultra Distance Triathlon",
                "race_type": "ultra_tri"
            },
            {
                "text": "Cyclisme Ultra Distance",
                "race_type": "ultra"
            },
            {
                "text": "Ultrafond",
                "race_type": "ultra_run"
            }
        ]
    },
    "Travel Day": {
        "image": "/images/activity_icons/Travel Day.svg",
        "text": "Jour de voyage",
        "texti": "TravelDay",
        "race_types": []
    },
    "No Training": {
        "image": "/images/activity_icons/No Training.svg",
        "text": "Récupération",
        "texti": "no_training",
        "race_types": []
    },
    "Duathlon": {
        "image": "/images/activity_icons/Duathlon.svg",
        "text": "Duathlon",
        "texti": "duathlon",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "Course 10 km",
                "race_type": "10r"
            },
            {
                "text": "Course de 5 km",
                "race_type": "5r"
            },
            {
                "text": "Suivre l'évènement",
                "race_type": "bw"
            },
            {
                "text": "Montée < 10min",
                "race_type": "section10"
            },
            {
                "text": "Montée < 30min",
                "race_type": "section30"
            },
            {
                "text": "Montée < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Duathlon Courte Distance",
                "race_type": "d_kd"
            },
            {
                "text": "Duathlon Longue Distance",
                "race_type": "d_ld"
            },
            {
                "text": "Duathlon Moyenne Distance",
                "race_type": "d_md"
            },
            {
                "text": "Course De Fond",
                "race_type": "tr"
            },
            {
                "text": "Semi-Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Critérium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "Cross Country VTT",
                "race_type": "cc"
            },
            {
                "text": "Marathon VTT",
                "race_type": "mtb_mt"
            },
            {
                "text": "Course Organisée",
                "race_type": "rtf"
            },
            {
                "text": "Course en ville",
                "race_type": "cr"
            },
            {
                "text": "Marathon cycliste (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Course sur Route",
                "race_type": "sr"
            },
            {
                "text": "Course sur Route",
                "race_type": "rr"
            },
            {
                "text": "Ultrafond",
                "race_type": "ultra_run"
            },
            {
                "text": "Cyclisme Ultra Distance",
                "race_type": "ultra"
            },
            {
                "text": "Ultra Distance Duathlon",
                "race_type": "ultra_dua"
            },
            {
                "text": "Contre la Montre",
                "race_type": "tt"
            }
        ]
    },
    "Long Cycling": {
        "image": "/images/activity_icons/Long Cycling.svg",
        "text": "Long Vélo",
        "texti": "LongCycling",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Mountain Biking": {
        "image": "/images/activity_icons/Mountain Biking.svg",
        "text": "VTT",
        "texti": "mtb",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Spinning": {
        "image": "/images/activity_icons/Spinning.svg",
        "text": "Spinning",
        "texti": "spinning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Running": {
        "image": "/images/activity_icons/Running.svg",
        "text": "Course à pied",
        "texti": "run",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "Course 10 km",
                "race_type": "10r"
            },
            {
                "text": "Course de 5 km",
                "race_type": "5r"
            },
            {
                "text": "Course en montagne",
                "race_type": "hr"
            },
            {
                "text": "Montée < 10min",
                "race_type": "section10"
            },
            {
                "text": "Montée < 30min",
                "race_type": "section30"
            },
            {
                "text": "Montée < 60min",
                "race_type": "section60"
            },
            {
                "text": "Cyclo cross",
                "race_type": "cross"
            },
            {
                "text": "Course De Fond",
                "race_type": "tr"
            },
            {
                "text": "Semi-Marathon",
                "race_type": "h_mt"
            },
            {
                "text": "Critérium",
                "race_type": "crit"
            },
            {
                "text": "Marathon",
                "race_type": "mt"
            },
            {
                "text": "Cross Country VTT",
                "race_type": "cc"
            },
            {
                "text": "Marathon VTT (> 3h)",
                "race_type": "mtb_mt"
            },
            {
                "text": "Course Organisée (> 3h)",
                "race_type": "rtf"
            },
            {
                "text": "Course en ville",
                "race_type": "cr"
            },
            {
                "text": "Marathon cycliste (> 3h)",
                "race_type": "road_mt"
            },
            {
                "text": "Course sur Route",
                "race_type": "sr"
            },
            {
                "text": "Course sur Route",
                "race_type": "rr"
            },
            {
                "text": "test marathon",
                "race_type": "t_mt"
            },
            {
                "text": "Ultrafond",
                "race_type": "ultra_run"
            },
            {
                "text": "Cyclisme Ultra Distance",
                "race_type": "ultra"
            },
            {
                "text": "Contre la Montre",
                "race_type": "tt"
            }
        ]
    },
    "Long Running": {
        "image": "/images/activity_icons/Long Running.svg",
        "text": "Longue Course",
        "texti": "LongRunning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Trail Running": {
        "image": "/images/activity_icons/Trail Running.svg",
        "text": "Trail Running",
        "texti": "TrailRunning",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Treadmill": {
        "image": "/images/activity_icons/Treadmill.svg",
        "text": "Tapis roulant",
        "texti": "Treadmill",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Orienteering": {
        "image": "/images/activity_icons/Orienteering.svg",
        "text": "Course d'orientation",
        "texti": "Orienteering",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Swimming": {
        "image": "/images/activity_icons/Swimming.svg",
        "text": "natation",
        "texti": "swim",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Open Water Swimming": {
        "image": "/images/activity_icons/Open Water Swimming.svg",
        "text": "Nage en eau libre",
        "texti": "OpenWaterSwimming",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Joker": {
        "image": "/images/activity_icons/Joker.svg",
        "text": "Choisit par 2PEAK",
        "texti": "joker",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Gym": {
        "image": "/images/activity_icons/Gym.svg",
        "text": "Musculation",
        "texti": "iron",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Yoga": {
        "image": "/images/activity_icons/Yoga.svg",
        "text": "Yoga",
        "texti": "Yoga",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Gym Equipment": {
        "image": "/images/activity_icons/Gym Equipment.svg",
        "text": "Machines cardio",
        "texti": "GymEquipment",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Indoor Rowing": {
        "image": "/images/activity_icons/Indoor Rowing.svg",
        "text": "Rameur",
        "texti": "IndoorRowing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "XC Skiing": {
        "image": "/images/activity_icons/XC Skiing.svg",
        "text": "ski",
        "texti": "skiing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": [
            {
                "text": "Montée < 10min",
                "race_type": "section10"
            },
            {
                "text": "Montée < 30min",
                "race_type": "section30"
            },
            {
                "text": "Montée < 60min",
                "race_type": "section60"
            },
            {
                "text": "Semi-Marathon",
                "race_type": "l_h_mt"
            },
            {
                "text": "Courte Distance",
                "race_type": "l_kd"
            },
            {
                "text": "Marathon",
                "race_type": "l_mt"
            },
            {
                "text": "Distance de Sprint",
                "race_type": "l_sd"
            },
            {
                "text": "Ultrafond",
                "race_type": "ultra_run"
            },
            {
                "text": "Cyclisme Ultra Distance",
                "race_type": "ultra"
            },
            {
                "text": "Ultra Distance Ski de fond",
                "race_type": "ultra_skiing"
            }
        ]
    },
    "Roller Skiing": {
        "image": "/images/activity_icons/Roller Skiing.svg",
        "text": "Ski à Roulette",
        "texti": "RollerSkiing",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Ski Touring": {
        "image": "/images/activity_icons/Ski Touring.svg",
        "text": "Peau de Phoque",
        "texti": "SkiTouring",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Snow Shoes": {
        "image": "/images/activity_icons/Snow Shoes.svg",
        "text": "Raquettes à neige",
        "texti": "SnowShoes",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Sup": {
        "image": "/images/activity_icons/Sup.svg",
        "text": "SUP",
        "texti": "Sup",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Nordic Walking": {
        "image": "/images/activity_icons/Nordic Walking.svg",
        "text": "Marche Nordique",
        "texti": "nw",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Illness": {
        "image": "/images/activity_icons/Illness.svg",
        "text": "Maladie",
        "texti": "Illness",
        "race_types": []
    },
    "Walking": {
        "image": "/images/activity_icons/Walking.svg",
        "text": "Marche à pied",
        "texti": "Walking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Alpine Hiking": {
        "image": "/images/activity_icons/Alpine Hiking.svg",
        "text": "Alpine Hiking",
        "texti": "AlpineHiking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Hiking": {
        "image": "/images/activity_icons/Hiking.svg",
        "text": "Randonnée",
        "texti": "Hiking",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Skating": {
        "image": "/images/activity_icons/Skating.svg",
        "text": "Patin à Glace",
        "texti": "skate",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Ice Skating": {
        "image": "/images/activity_icons/Ice Skating.svg",
        "text": "Patin sur glace",
        "texti": "IceSkating",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Other": {
        "image": "/images/activity_icons/Other.svg",
        "text": "autres sports",
        "texti": "athlet",
        "transid": 2019,
        "aid": {
            "t2p": 5.95,
            "p2p": 6.1,
            "tr2p": 5.95,
            "b2p": 5.95,
            "r2p": 5.79,
            "x2p": 5.5
        },
        "race_types": []
    },
    "Brick Cycling-Running": {
        "image": "/images/activity_icons/brick.svg",
        "text": "ENCHAINEMENT",
        "texti": "brick",
        "race_types": []
    },
    "Brick Spinning-Running": {
        "image": "/images/activity_icons/brick_spinning.svg",
        "text": "ENCHAINEMENT",
        "texti": "brick_spinning",
        "race_types": []
    }
}