import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit {
  
  @Input() icon: boolean = false;
  @Input() number: number;
  @Input() message: string;
  totalNum = [];

  constructor() { }

  ngOnInit() {    
    for(let i=0; i<=this.number; i++){
      this.totalNum.push(i);
    }
  }

}
