export const FRTRAININGELEMENT = {
    "EMPHASIS_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_strength.gif",
        "text": "accent sur la force",
        "description": "Lorsque vous voyez ce symbole, choisissez une grande vitesse et gardez une cadence de 50 à 70 RPM max. Les parcours vallonnés ou les montées régulières sont particulièrement adaptés."
    },
    "EMPHASIS_CLIMBING": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_climbing.gif",
        "text": "ascension",
        "description": "Si vous n'intégrez pas volontairement d'ascension à votre entraînement, vous n'aurez ni la force ni la compétence nécessaires aux courses sur route ou aux marathons vélo sur des parcours montagneux. En pratique : rouler avec le haut du corps détendu, en tenant le haut du cintre et grimper aussi en danseuse."
    },
    "EMPHASIS_CADENCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/emphasis_cadence.gif",
        "text": "cadence élevée",
        "description": "Exercices de vitesse. Essayer de faire les intervalles prévus ou, dans l'idéal, la totalité de l'entraînement à une cadence \"inconfortablement\" élevée. Si c'est trop pénible, alternez les périodes à cadence élevée avec des périodes de récupération. La fréquence élevée développe vos capacités de coordination et ceci est la base pour augmenter la puissance/vitesse. Cyclistes : 100-120 RPM (rév. par minute)- et plus encore sur de courtes périodes. Coureurs : > 90 pas par minute."
    },
    "TECHNIQUE_FOCUS": {
        "image": "https://2peak.com/images/peak_icons/training_content/technique_focus.gif",
        "text": "technique",
        "description": "L'entraînement technique augmente votre efficacité motrice. Exécuter le mouvement avec attention et concentration sur des segments particuliers au ralenti. La qualité du mouvement doit être l'objectif principal de l'entraînement."
    },
    "STRETCH": {
        "image": "https://2peak.com/images/peak_icons/training_content/stretch.gif",
        "text": "Étirement",
        "description": "S'étirer après le travail améliore et diminue le temps de la récupération."
    },
    "FARTLEK": {
        "image": "https://2peak.com/images/peak_icons/training_content/fartlek.gif",
        "text": "Fartlek",
        "description": "Entraînement sous forme libre (Fartlek signifie jeu de vitesse) dans lequel vous \"jouez\" à l'extérieur à différentes intensités et travaillez à la vitesse qui vous convient. Faites alterner de libres intervalles avec de l'endurance de base. Les terrains montagneux avec de nombreux changements de direction sont idéaux - forcer l'allure en montée, accélérer  en descente ou vitesse de croisière."
    },
    "PYRAMID": {
        "image": "https://2peak.com/images/peak_icons/training_content/pyramid.gif",
        "text": "Pyramide",
        "description": "Séquence fractionnée - en augmentant - puis en diminuant la durée"
    },
    "PULL_BUOY": {
        "image": "https://2peak.com/images/peak_icons/training_content/pull_buoy.gif",
        "text": "bouée traction",
        "description": "Concentration sur les bras qui tiennent la bouée pour soutenir les jambes"
    },
    "PADDLES": {
        "image": "https://2peak.com/images/peak_icons/training_content/paddles.gif",
        "text": "pagaies",
        "description": "Exercices de force pour les bras avec les  pagaies - principalement avec la bouée pour stabiliser. Le but ici est d'augmenter la résistance dans les mains et de sentir \"la saisie\" et l'entrée dans l'eau à chaque coup. La vitesse n'est pas plus élevée que d'habitude - les bras travaillent à une fréquence inférieure."
    },
    "KICK_BOARD": {
        "image": "https://2peak.com/images/peak_icons/training_content/kick_board.gif",
        "text": "planche de natation",
        "description": "Exercices pour les jambes avec la planche de natation comme aide flottante pour le haut du corps. A utiliser face vers le bas et en supination. Se concentrer sur le battement des hanches."
    },
    "WETSUIT": {
        "image": "https://2peak.com/images/peak_icons/training_content/wetsuit.gif",
        "text": "combinaison",
        "description": "Entraînement avec une combinaison quand c'est possible en eau libre. Pour se familiariser avec une flottabilité plus importante et améliorer le sens de l'orientation dans l'eau."
    },
    "SPRINT_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_climb.gif",
        "text": "montée en sprint",
        "description": "Les exercices de sprint augmentent votre accélération et votre force max."
    },
    "SPRINT_DESCEND": {
        "image": "https://2peak.com/images/peak_icons/training_content/sprint_descend.gif",
        "text": "descente en sprint",
        "description": "Utilisez l'énergie potentielle de la pente pour augmenter l'accélération. Ceci améliore votre vitesse et votre cadence max. La pente vous force à pédaler plus rapidement, développant votre habileté motrice. Commencez sur une pente modérée."
    },
    "SPINT_DESCEND_CLIMB": {
        "image": "https://2peak.com/images/peak_icons/training_content/spint_descend_climb.gif",
        "text": "montée en sprint",
        "description": "Cet exercice est exécuté en fin de descente quand vous accélérez à votre vitesse maximale. Au moment où vous recommencez à monter, laissez votre chaîne sur le grand plateau et essayez de garder la vitesse et la cadence - même si la charge augmente et que les jambes sont douloureuses. Ceci augmente votre tolérance lactique et la force de votre volonté. Les pratiquants de VTT en particulier, bénéficient de cet entrainement sur les trails, la ligne de départ et les ascensions techniques. Les coureurs sur route peuvent mieux gérer cette douloureuse contre-ascension qui arrive dans les longues descentes. La capacité à repousser la formation d'acide lactique pendant les accélérations est entraînée."
    },
    "TRACKTRAINING": {
        "image": "https://2peak.com/images/peak_icons/training_content/tracktraining.gif",
        "text": "entrainement sur piste",
        "description": "La piste est idéale pour les entraînements fractionnés intensifs, depuis que vous pouvez vous entraînez avec des distances précises. Les entraînements techniques y sont aussi mieux réalisés, en tirant partie de la surface souple ou l'herbe (meilleure) quand c'est possible."
    },
    "RUNNING_TECHNIQUE": {
        "image": "https://2peak.com/images/peak_icons/training_content/running_technique.gif",
        "text": "exercices de course à pied",
        "description": "Les exercices de course sont utilisés pour travailler la coordination neuromusculaire et la capacité statique/mécanique. Inclure des exercices de course dans votre entraînement régulier conduit à un style de course plus dynamique et efficace."
    },
    "SOFTSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/softsurface.gif",
        "text": "surface souple",
        "description": "Courir régulièrement sur des surfaces souples et accidentées permet de renforcer les muscles des jambes et des pieds, ce qui aide à réduire les blessures. De plus, courir sur les surfaces souples et la course trail est moins traumatisant pour votre corps qui récupère alors plus rapidement."
    },
    "HARDSURFACE": {
        "image": "https://2peak.com/images/peak_icons/training_content/hardsurface.gif",
        "text": "surface dure",
        "description": "Courir sur les surfaces dures aide votre corps à s'adapter aux traumatismes causés par les courses sur le macadam."
    },
    "OPEN_WATER": {
        "image": "https://2peak.com/images/peak_icons/training_content/open_water.gif",
        "text": "eau libre",
        "description": "Nager en eau libre avec ou sans combinaison. En tant que tri-athlète vous devriez vous entraîner à nager en eau libre pour aiguiser votre sens de l'orientation et être à l'aise sans les lignes d'eau de la piscine..."
    },
    "CIRCUIT_STRENGTH": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_strength.gif",
        "text": "séance de musculation",
        "description": "Les exercices de musculation aérobie sous forme de circuit.<br> Exercices sélectionnées (par exemple une série de squats, d'extension et de relever de jambes) en alternance avec des exercices correspondant au sport principal. Ces exercices sont réalisés à un poids très faible mais avec 30-40 répétitions sans pause entre les exercices. La translation dans le sport principal est un travail d'endurance de 3-4 minutes sur le vélo ou le tapis roulant à une intensité Z2-Z3. Après le dernier circuit, passer 10 minutes sur le vélo statique, en pédalant à une cadence élevée : <br><br>Exemple : 10' de pédalage dans la Z2 à 90 rpm + 10'exercices de résistance pysique + 4 circuits suivis de 3' de course sur tapis roulant à une intensité Z2-3 + 5-10' d'exercices de résistance pysique + 10' de vélo spinning dans la Z2 à 90-100 rpm. "
    },
    "CIRCUIT_ENDURANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/circuit_endurance.gif",
        "text": "séance d'endurance",
        "description": "Cet forme d'entraînement en circuit force utilise le poids du corps pour les exercices. Associer la coordination avec le travail de force aérobie en utilisant de simples exercices, comme les pompes, sauter avec un minimum de temps de contact avec le sol, les sauts allongés, les abdos etc. ou utiliser la description dans le menu sous > Tutoriels  > Outils. L'exercice est effectué sur  une minute entière.<br><br> Exemple : 6 x (exercice 30 sec / récupération 30 sec) - un circuit complet est répété 4-6 fois."
    },
    "TORSO": {
        "image": "https://2peak.com/images/peak_icons/training_content/torso.gif",
        "text": "résistance physique",
        "description": "Pour éviter tout déséquilibre musculaire, les groupes musculaires du tronc doivent être entraînés."
    },
    "LANCE": {
        "image": "https://2peak.com/images/peak_icons/training_content/lance.gif",
        "text": "Lance-style pédalage ascension",
        "description": "Avance rapide : travail en montée en s'aidant des bras."
    },
    "SKIING_XC_1_1": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_1.gif",
        "text": "ski de fond 1-1",
        "description": "1 : 1"
    },
    "SKIING_XC_1_2_ASYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_asymmetric.gif",
        "text": "ski de fond 1-2 asymétrique",
        "description": "1 : 2 Asymétrique"
    },
    "SKIING_XC_1_2_SYMMETRIC": {
        "image": "https://2peak.com/images/peak_icons/training_content/skiing_xc_1_2_symmetric.gif",
        "text": "ski de fond 1-2 symétrique",
        "description": "1 : 2 Symétrique"
    },
    "MP_TEST": {
        "image": "https://2peak.com/images/peak_icons/training_content/mp_test.gif",
        "text": "Test fitness",
        "description": "Aujourd'hui est un jour idéal pour un test de forme. Si vous décidez de ne pas le faire, entraînez-vous dans la zone d'endurance de base pour la durée proposée."
    },
    "RESPIRATION": {
        "image": "https://2peak.com/images/peak_icons/training_content/respiration.gif",
        "text": "respiration",
        "description": "Exercices spécifiques pour améliorer vos muscles respiratoires (aide du coach requise)."
    },
    "MENTAL": {
        "image": "https://2peak.com/images/peak_icons/training_content/mental.gif",
        "text": "mental",
        "description": "Entraînement mental. Une fois que vous êtes physiquement prêt, c'est votre volonté qui va faire la différence. Le cerveau est l'un des plus forts et des plus importants \"muscles\" et il a besoin de temps et d'entraînement pour améliorer : volonté, capacité intellectuelle et émotions."
    }
}