import { HttpHeaders,  HttpClient, HttpErrorResponse, } from "@angular/common/http"
import { Injectable } from "@angular/core"
import * as moment from "moment"
import 'moment-timezone'
import { ActivityTypes } from "../../_interfaces/ActivityTypes"
import { Dashboard } from "../../_interfaces/Dashboard"
import { Goals } from "../../_interfaces/Goals"
import { ConnectedServices } from "../../_interfaces/ConnectedServices"
import { convertRaceToApiFormat, Race } from "../../_interfaces/Race"
import { Weekplan, WeekplanEntry } from "../../_interfaces/Schedule"
import { TrainingMeter } from "../../_interfaces/TrainingMeter"
import { TrainingZones, ZoneNames } from "../../_interfaces/TrainingZones"
import { User } from "../../_interfaces/User"
import { convertToApiFormat, Workout } from "../../_interfaces/Workout"
import { encodeParams, toIso8601String, toIso8601StringForBefore } from "../../_utils/utils"
import { AuthData, AuthService } from "../auth/auth.service"
import { ApiActivity } from "./../../_interfaces/ApiActivity"
import { RegisteringUserData } from "./../../_interfaces/RegisteringUserData"
import { Zones } from "./../../_interfaces/TrainingZones"
import { UserExistance } from "./../../_interfaces/UserExistance"
import { StorageService } from "./../storage/storage.service"
import { AthleteBattery } from "./../../_interfaces/AthleteBattery"

/* Get activity type  */
import { ENACTIVITYTYPE } from "../../_services/constant/activity_types/en";
import { FRACTIVITYTYPE } from "../../_services/constant/activity_types/fr";
import { ITACTIVITYTYPE } from "../../_services/constant/activity_types/it";
import { ESACTIVITYTYPE } from "../../_services/constant/activity_types/es";
import { DEACTIVITYTYPE } from "../../_services/constant/activity_types/de";
  
/* Get zone type  */
import { ENZONE } from "../../_services/constant/zones/en";
import { FRZONE } from "../../_services/constant/zones/fr";
import { ITZONE } from "../../_services/constant/zones/it";
import { ESZONE } from "../../_services/constant/zones/es";
import { DEZONE } from "../../_services/constant/zones/de";

/* Get training element   */
import { ENTRAININGELEMENT } from "../../_services/constant/training_element/en";
import { FRTRAININGELEMENT } from "../../_services/constant/training_element/fr";
import { ITTRAININGELEMENT } from "../../_services/constant/training_element/it";
import { ESTRAININGELEMENT } from "../../_services/constant/training_element/es";
import { DETRAININGELEMENT } from "../../_services/constant/training_element/de";

/* Get phases element   */
import { ENPHASES } from "../../_services/constant/phases/en";
import { FRPHASES } from "../../_services/constant/phases/fr";
import { ITPHASES } from "../../_services/constant/phases/it";
import { ESPHASES } from "../../_services/constant/phases/es";
import { DEPHASES } from "../../_services/constant/phases/de";

import { APICONFIG } from './../../constants';

@Injectable({
  providedIn: "root"
})
export class  ApiService {

    constructor(
      private storageService: StorageService,
      private auth: AuthService,
      private http: HttpClient,
    ) { }

  public async checkUserExistance(email: string): Promise<boolean> {
    return this.auth.post<UserExistance>("athlete/existance", {
      email: email,
    })
      .then((userExistance: UserExistance) => userExistance.member)
  }

  public async  getAthleteSavedWorkout(athlete) {
    const tokenObj = await this.storageService.getMainCredentials();
    if(tokenObj){
      const reqHeaders = new HttpHeaders().set("Authorization", `Bearer ${tokenObj['access_token']}`)
      return await this.http.get(`${APICONFIG.apiUrl}/teams/${athlete}/saved_workouts_library`, {headers: reqHeaders})
      .toPromise();
    }
  }

  public async insertRace(race) {
    return this.auth.post("athlete/competition", race)
  }

  public async get(url: string): Promise<any> {
    return this.auth.request<any>("GET", url, {}, new HttpHeaders(), true)
  }

  public async updateRace(race){
    return this.auth.put<Race>("athlete/competition/" + race.id, race)
  }

  public async updateBattery(level: number): Promise<void> {
    const params = {
      battery_level: level + ""
    }
    return this.auth.put<AthleteBattery>("athlete/battery/", params)
      .then(async (b: AthleteBattery) => {
        const user = await this.storageService.getUser()
        user.battery_level = b.battery_level
        await this.storageService.setUser(user)
        return
      })
  }

  public async insertWorkout(workout: Workout): Promise<ApiActivity> {
    return this.auth.post<ApiActivity>("activities", convertToApiFormat(workout, "PLAN"))
      .then((aa: ApiActivity) => {
        this.fetchActivities()
        return aa
      })
  }

  public async insertBlocksWorkout(workout: Workout, mode: string): Promise<ApiActivity> {
    return this.auth.post<ApiActivity>("activities/activity_block", convertToApiFormat(workout, mode))
      .then((aa: ApiActivity) => {
        this.fetchActivities()
        return aa
      })
  }

  public async insertBlocksWorkoutForQuickLog(workout: Workout, mode: string): Promise<ApiActivity> {
    return this.auth.post<ApiActivity>("activities/activity_block", convertToApiFormat(workout, mode))
      .then((aa: ApiActivity) => {
        return aa
      })
  }


  public async editBlocksWorkout(workout: Workout): Promise<ApiActivity> {
    return this.auth.put<ApiActivity>("activities/activity_block", convertToApiFormat(workout, "LOGGED"))
      .then((aa: ApiActivity) => {
        this.fetchActivities()
        return aa
      })
  }

  public async editDashboardBlocksWorkout(workout: Workout): Promise<ApiActivity> {
    return this.auth.post<ApiActivity>("activities/activity_block", convertToApiFormat(workout, workout['save_mode']))
      .then((aa: ApiActivity) => {
        this.fetchActivities()
        return aa
      })
  }

  public async logIn(email: string, password: string): Promise<AuthData> {
    return this.auth.login(email, password)
  }

  public async signUp(d: RegisteringUserData): Promise<AuthData> {
    return this.auth.register(d)
  }

  public async swapActivity(docuid: number, altid: number) {
    const params = { docuid, altid }
    return this.auth
      .get<ApiActivity>("activities/activity_swap?" + encodeParams(params))
      .then(a => {
        this.fetchActivities()
        return a
      })
  }

  public async fetchUser(data?: string): Promise<User> {
    return this.auth.get<User>("athlete")
      .then(async (user: User) => {
        if (user){
            await moment.tz.setDefault(user.timezone)
            this.storageService.setUser(user)
        }
        return user
      })
  }

  public getBattery(){
    return this.auth.get<AthleteBattery>("athlete/battery")
  }

  public async fetchBattery(): Promise<AthleteBattery> {
    return this.auth.get<AthleteBattery>("athlete/battery")
      .then(async (b: AthleteBattery) => {
          const user = await this.storageService.getUser()
          if(user == null) {
            await this.fetchUser().then(value => {
              value.battery_level = b.battery_level
              moment.tz.setDefault(value.timezone)
            })
          }
          else {
            user.battery_level = b.battery_level
            moment.tz.setDefault(user.timezone)
          }

          await this.storageService.setUser(user)
          return user
      });
  }


  /*
  * Remove the local storage data
  * All activity type set in separated file rather than storage
  * Smartdata: 24.02.2020
  */
  public async getVirtualRace(sport, all?:any) {
    return this.auth.get(
      `res/virtual_races?sport=${sport}&all=${all}`
    ).then(async (notification) => {
        return notification
    })
  }

  public async fetchActivities(weekNumber: number = moment().week()): Promise<Array<ApiActivity>> {

    const week = moment().week(weekNumber)
    const params = {
      after: toIso8601String(week.startOf("isoWeek")),
      before: toIso8601String(week.endOf("isoWeek")),
    }
    return this.auth.get<Array<ApiActivity>>("activities?" + encodeParams(params))
  }


  public async getFewActivities() {
    const params = {
      after: moment(new Date()).add(-1, 'days').format(),
      before: moment(new Date()).add(1,'days').format(),
    }

    return this.auth.get<Array<ApiActivity>>("activities/apps_activities?" + encodeParams(params)).then(async (activities) => {
      let correctDate = activities[0].start_date
      const start = moment(correctDate)
      for (let i = 0; i <= 6; i++) {
        const newStart = start.clone().add(i, "days")
        activities.filter(act => {
          return moment(act.start_date.substr(0, 10)).date() === newStart.date()
        })
      }
      return activities
    })
  }

  public async fetchWeekActivities(weekNumber: number = moment().week(), year: number = +moment().format("YYYY") ): Promise<Array<ApiActivity>> {
    let week = moment().week(weekNumber)

    if(weekNumber > 40 && weekNumber <= 52) {
      week = moment(year,"YYYY").isoWeek(weekNumber)
    }

    /* Get previous week start date */
    /* let addedWeek = weekNumber + 1;
    let nextWeek = moment().week(addedWeek) */

    const params = {
      after: toIso8601String(week.startOf("isoWeek")),
      //before: toIso8601String(week.endOf("isoWeek")),
      before: toIso8601StringForBefore(week.endOf("isoWeek")),
    }

    return this.auth.get<Array<ApiActivity>>("activities/apps_activities?" + encodeParams(params)).then(async (activities) => {
      let correctDate = activities[0].start_date
      const start = moment(correctDate)
      for (let i = 0; i <= 6; i++) {
        const newStart = start.clone().add(i, "days")
        activities.filter(act => {
          return moment(act.start_date.substr(0, 10)).date() === newStart.date()
        })
      }

      // await this.storageService.setActivity(activities)
      return activities
    })
  }

  public async  fetchPrevNextWeekActivities(weekNumber: number = moment().week(), year: number = +moment().format("YYYY") ): Promise<Array<ApiActivity>> {
    let week = moment().week(weekNumber)

    if(weekNumber > 40 && weekNumber <= 52) {
      week = moment(year,"YYYY").isoWeek(weekNumber)
    }

    /* Get previous week start date */
    let substractedWeek = weekNumber - 1;
    let prevWeek = moment().week(substractedWeek)

    const params = {
      after: toIso8601String(prevWeek.startOf("isoWeek")),
      before: toIso8601StringForBefore(week.endOf("isoWeek")),
    }
    return this.auth.get<Array<ApiActivity>>("activities/apps_activities?" + encodeParams(params)).then(async (activities) => {
      let correctDate = activities[0].start_date
      const start = moment(correctDate)
      for (let i = 0; i <= 6; i++) {
        const newStart = start.clone().add(i, "days")
        activities.filter(act => {
          return moment(act.start_date.substr(0, 10)).date() === newStart.date()
        })
      }
      return activities
    })
  }


  public async gettingWeeklyActivities(params): Promise<Array<ApiActivity>> {
    return this.auth.get<Array<ApiActivity>>("activities?" + encodeParams(params))
    .then(async (activities) => {
      let correctDate = activities[0].start_date
      const start = moment(correctDate)
      for (let i = 0; i <= 6; i++) {
        const newStart = start.clone().add(i, "days")
        const dayActivities = activities.filter(act => {
          return moment(act.start_date.substr(0, 10)).date() === newStart.date()
        })
      }
      return activities
    })
  }


  public async fetchDayActivities(date: string): Promise<Array<ApiActivity>> {
    const day = moment(date)
    const params = {
      after: toIso8601String(day.startOf("day")),
      before: toIso8601String(day.endOf("day")),
    }
    return this.auth.get<Array<ApiActivity>>("activities?" + encodeParams(params))
      .then(async (activities) => {
        await this.storageService.setDayActivities(date, activities)
        return activities
      })
  }

  /*
  * Remove the local storage data
  * All activity type set in separated file rather than storage
  * Smartdata: 24.02.2020
  */
  public async fetchActivityTypes(language: string) {
    return this.getLangulageConversion(language);
  }

  /*
  * Remove the local storage data
  * fetch zone from separated file rather than storage
  * Smartdata: 24.02.2020
  */
  public async fetchZoneNames(language: string) {
    return this.getZonesType(language);
  }

   /*
  * Remove the local storage data
  * fetch training element from separated file rather than storage
  * Smartdata: 24.02.2020
  */
  public async fetchTrainingElement(language: string) {
    return this.getTrainingElement(language);
  }

  public async fetchGoals(language: string): Promise<Goals> {
    return this.auth.get<Goals>(
      `res/goals?language=${language}`
    )
      .then(async (goals: Goals) => {
        await this.storageService.setGoals(goals)
        return goals
      })
  }

  public async fetchDashboard(): Promise<Dashboard> {
    return this.auth.get<Dashboard>("athlete/app_dashboard")
      .then(async (db: Dashboard) => {
        await this.storageService.setDashboard(db)
        return db
      })
  }

  

  public async fetchWeekplan(): Promise<Weekplan> {
    return this.auth.get<Weekplan>(
      `athlete/weekplan`
    )
      // .toPromise()
      .then(async (weekplan: Weekplan) => {
        await this.storageService.setWeekplan(weekplan)
        return weekplan
      })
  }

  public async updateWeekplan(weekplan: Weekplan, specificDate: string = ""): Promise<void> {

    const params = {
      weekplan: JSON.stringify(weekplan)
    }
    if (specificDate !== "" && specificDate !== "Invalid date") {
      params["date"] = specificDate
    }

    return this.auth.put<void>("athlete/weekplan", params).then(a => {
      this.fetchWeekplan()
      return a
    })
  }

  public async deleteActivity(activity: ApiActivity | number): Promise<void> {
    const id = typeof activity === "number" ? activity : activity.id
    return this.auth.delete<void>(`activities/${id}`)
      .then(a => {
        this.fetchActivities()
        return a
      })
  }

  public async deleteActivityCoach(activity: ApiActivity | number, activity_code: string = "", activity_id: number): Promise<void> {
    const id = typeof activity === "number" ? activity : activity.id
    const p_activity_code = typeof activity_code === "string" ? activity_code : ""
    const p_user = typeof activity_id === "number" ? activity_id : ""
    return this.auth.delete<void>(`activities/${id}/${p_activity_code}/${p_user}`)
      .then(a => {
        this.fetchActivities()
        return a
      })
  }

  public async deleteActityById(activity: ApiActivity | number): Promise<void> {
    const id = typeof activity === "number" ? activity : activity.id
    return this.auth.delete<void>(`activities/${id}`)
      .then(a => {
        return a
      })
  }

  public async deleteRace(activity: Race): Promise<void> {
    return this.auth.delete<void>(`athlete/competition/${activity.id}`)
  }

  public async deleteRaceID(id: any): Promise<void> {
    return this.auth.delete<void>(`athlete/competition/${id}`)
  }

  public async activateRaceID(id: any): Promise<void> {
    return this.auth.get<void>(`athlete/competition_activate/${id}`)
  }

  public async runCalculation(isQuickLog?:boolean): Promise<any> {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    return this.auth.post<void>("athlete/calculation", {})
    .then(async (res : any) => {
      if(res.message == "ERROR_15SEC_MIN") {
        await delay(10000);
        this.runCalculation()
      }
      else {
        return
      }
    })
    // .then(() => {
    //   if(!isQuickLog){
    //     this.fetchDashboard()
    //   }
    //   return
    // })
  }

  public async activateTrial(): Promise<void> {
    return this.auth.post<void>("athlete/activate_trial", {})
    .then(() => {
      this.fetchDashboard()
      return
    })
  }

  public async updateAthleteSettings(obj: any): Promise<void> {
    return this.auth.put<User>("athlete", obj)
      .then(async (user: User) => {
        const oldUser = await this.storageService.getUser()
        if(oldUser != undefined)
          user.battery_level = oldUser.battery_level
        await this.storageService.setUser(user)
        return
      })
  }

  public async updateJetlag(state: any, offset: any): Promise<void> {
    return this.auth.post<void>("athlete/jetlag", {
      adjust: state,
      tz_difference: offset,
    })
  }

  public async updateBuyInApp(state: any): Promise<void> {
    return this.auth.post<void>("athlete/inapp", {
      receipt: state,
    })
  }

  public async fetchTrainingZones(): Promise<TrainingZones> {
    return this.auth.get<TrainingZones>(
      `athlete/zones`
    )
      .then(async (trainingZones: TrainingZones) => {
        await this.storageService.setTrainingZones(trainingZones)
        return trainingZones
      })
  }

 

  public async fetchTrainingMeter(): Promise<TrainingMeter> {
    return this.auth.get<TrainingMeter>(
      `athlete/trainingmeter`
    )
      .then(async (trainingMeter: TrainingMeter) => {
        await this.storageService.setTrainingMeter(trainingMeter)
        return trainingMeter
      })
  }

  public async getRace(id: number): Promise<Race> {
    return this.auth.get<Array<Race>>(
      `athlete/competition/` + id
    )
      .then(async (raceArray: Array<Race>) => raceArray[0])
  }

  public async getSpecificWeekplan(date: string): Promise<Weekplan> {
    return this.auth.get<Weekplan>(
      `athlete/weekplan/` + moment(date).startOf("isoWeek").format("YYYY-MM-DD")
    ).then(we => we)
  }

  public async getActivity(id: number): Promise<ApiActivity> {
    const params = { get_gps_data: false }
    return this.auth.get<ApiActivity>(
      `activities/${id}?` + encodeParams(params)
    )
      .then(async (act: ApiActivity) => {
        return act
      })
  }

  public async getAppleWatchWorkoutSummary(): Promise<any> {
    return this.auth.get<any>("activities/woiq?responseFormat=json_aw")
      .then((act) => act)
  }

  public async logSomething(params: any): Promise<void> {
    return this.auth.post<void>("activities", params)
      .then(a => {
        this.fetchActivities()
        return a
      })
  }

  public async updateTrainingZones(sport: string, type: string, zones: Zones): Promise<void> {
    return this.auth.put<void>(`athlete/zones/${sport}/${type}`, {
      sport,
      type,
      zones: [zones],
    })
  }

  public async fetchFutureRaces(): Promise<Array<Race>> {
    return this.auth.get<Array<Race>>(
      `athlete/competition?past=false`
    ).then(async (races: Array<Race>) => {
        await this.storageService.setFutureRaces(races)
        return races
      })
  }

  public async fetchPastRaces(): Promise<Array<Race>> {
    return this.auth.get<Array<Race>>(
      `athlete/competition?past=true`
    ).then(async (races: Array<Race>) => {
        return races
      })
  }

  public async fetchConnectedServices(): Promise<ConnectedServices> {
    return this.auth.get<ConnectedServices>(
      `athlete/connectedservices`
    )
      .then(async (connectedservices: ConnectedServices) => {
        return connectedservices
      })
  }

  

  public isAuthenticated(): boolean {
    return this.auth.isAuthenticated()
  }

  public async getUserToken(forceRefresh = false): Promise<string> {
    if (!this.isAuthenticated()) {
      return null
    }
    if (forceRefresh) {
      await this.fetchUser()
    }
    return this.auth.getAccessToken()
  }

  /*
  * Endpoints for fetching notification
  * Date: 26.12.2019
  * Smartdata
  */
  public async fetchNotification(language: string) {
    return this.auth.get(
      `athlete/analysismessages`
    ).then(async (notification) => {
        return notification
      })
  }

  /*
  * Endpoints for fetching athelete
  * Date: 07.01.2020
  * Smartdata
  */
  public async getAthlete(): Promise<User> {
    return this.auth.get<User>("athlete")
      .then(async (user: User) => {
        return user
    })
  }

  /*
  * Get conversion based on user setting
  * Smartdata
  */
  getFormatedUnitConversion(distance, distance_unit_format) {
    if (distance_unit_format === "m" ) {
      return (distance/1000).toFixed(2) + " km"
    } else {
      const numb = distance / 1609
      return numb.toFixed(2) + " miles"
    }
  }

   /*
  * Evevation conversion based on user setting
  * distance : km | miles
  * elevation: m | ft
  * Smartdata
  */

  getElevatorConversion(distance, distance_unit_format) {
    if (distance_unit_format === "ft" ) {
      return `${(distance * 3.281).toFixed(0)} ft`
    } else {
      return distance + " m"
    }
  }

  /*
    * get simple metric conversion
    * Smartdata
  */
  getMetricCoversion(distance, distance_unit_format, type='distance') {
    if(type=='distance'){
      if (distance_unit_format === "ft" ) {
        return `${(distance * 1.609).toFixed(2)}`;
      } else {
        return distance;
      }
    } else{
      if (distance_unit_format === "ft" ) {
        return `${(distance/3.281).toFixed(2)}`;
      } else {
        return distance;
      }
    }
  }

  /* Getting language conversion */
  getLangulageConversion(lang){
    switch(lang){
      case 'en':
        return ENACTIVITYTYPE;
      break;

      case 'fr':
        return FRACTIVITYTYPE;
      break;

      case 'it':
        return ITACTIVITYTYPE;
      break;

      case 'es':
        return ESACTIVITYTYPE;
      break;

      case 'de':
        return DEACTIVITYTYPE;
      break;
    }
  }

  /* Getting zone conversion */
  getZonesType(lang){
    switch(lang){
      case 'en':
        return ENZONE;
      break;

      case 'fr':
        return FRZONE;
      break;

      case 'it':
        return ITZONE;
      break;

      case 'es':
        return ESZONE;
      break;

      case 'de':
        return DEZONE;
      break;
    }
  }

  /* Getting training element conversion */
  getTrainingElement(lang){
    switch(lang){
      case 'en':
        return ENTRAININGELEMENT;
      break;

      case 'fr':
        return FRTRAININGELEMENT;
      break;

      case 'it':
        return ITTRAININGELEMENT;
      break;

      case 'es':
        return ESTRAININGELEMENT;
      break;

      case 'de':
        return DETRAININGELEMENT;
      break;
    }
  }

  /* Getting phase conversion */
  getPhases(lang){
    switch(lang){
      case 'en':
        return ENPHASES;
      break;

      case 'fr':
        return FRPHASES;
      break;

      case 'it':
        return ITPHASES;
      break;

      case 'es':
        return ESPHASES;
      break;

      case 'de':
        return DEPHASES;
      break;
    }
  }

  /* Getting home equipment data */
  public async getEquipement(type) {
    return await this.auth.get(
      `athlete/equipement?sport=${type}`
    ).then(async (equipement) => {
      return equipement; 
    });
  }
  
  /* Enpoints for creating new equipment */
  public async getEquipmentBrand(type){
    return await this.auth.get(
      `athlete/equipement/brands?sport=${type}`
    ).then(async (goals: Goals) => {
      return goals; 
    });
  }

  /* Getting home equipment data */
  public async updateEquipement(data) {
    return await this.auth.post(
      `athlete/equipement`, data
    ).then(async (goals: Goals) => {
      return goals; 
    });
  }

  /* Getting ranking data for race and goals */
  public async getRanking(id) {
    return await this.auth.get(
      `athlete/competition/rankings?id=${id}`
    ).then(async (ranking) => {
      return ranking; 
    });
  }

  /* Getting participants data for race and goals */
  public async getParticipants(id) {
    return await this.auth.get(
      `athlete/competition/participants?id=${id}`
    ).then(async (ranking) => {
      return ranking; 
    });
  }

 
  /*
  * Preparing api for caoch section 
  * Created Date: 08.05.2020
  */

   /* Getting group users for trainer */
   public async fetchGroups(group) {
    if(group)
      return this.auth.get(`teams/${group}`)
        .then(async (res) => {
          return res;
      })
  }


  /* Created and update group */
  public async updateGroup(user, data, id?:number){
    if(user){
      let apiUrl = `teams/${user}/groups`;
      if(id)
        apiUrl = `teams/${user}/groups/${id}`;

      return await this.auth.post(
        apiUrl, data
      ).then(async (groupData) => {
        return groupData; 
      });
    }
  }
  
  /* Remove existing group */
  public async deleteGroup(user, id) {
    if(user)
      return this.auth.delete(`teams/${user}/groups/${id}`)
        .then(res => {
          return res
        })
  }

  /* check if athlete alraedy exist in particular group */
  public async checkMailForAddingAthlete(user, email) {
    if(user)
      return this.auth.get(`teams/${user}/member?email=${email}`)
        .then(res => {
          return res
        })
  }


  /* check if athlete alraedy exist in particular group */
  public async getCoachDashboard(user) {
    if(user)
      return this.auth.get(`teams/${user}/dashboard`)
      .then(res => {
        return res
      })
  }

   /* Add athlete to particular group */
   public async addAthleteToGroup(user, groupId, data) {
    if(user && groupId)
      return this.auth.post(`teams/${user}/groups/${groupId}/athletes`, data)
        .then(res => {
          return res
      })
    }

  /* Remove athlete from existing group */
  public async deleteAthleteFromGroup(user, groupId, athleteId) {
    if(user && groupId)
      return this.auth.delete(`teams/${user}/groups/${groupId}/athletes/${athleteId}`)
        .then(res => {
          return res
        })
  }

  /* Add workout library in coach section  */
  public async addWorkoutLibrary(user, data) {
    if(user)
      return this.auth.post(`teams/${user}/saved_workouts_library`, data)
        .then(res => {
          return res
      })
  }

  /* update workout library in coach section  */
  public async updateWorkoutLibrary(user, data) {
    if(user)
      return this.auth.put(`teams/${user}/saved_workouts_library`, data)
        .then(res => {
          return res
      })
  }

  /* get all workout library in coach section */
  public async getWorkoutLibrary(user) {
    if(user)
      return this.auth.get(`teams/${user}/saved_workouts_library`)
      .then(res => {
        return res
    })
  }
  /* get all workout library in coach section */
  public async deleteWorkoutLibrary(user, id) {
    if(user)
      return this.auth.delete(`teams/${user}/saved_workouts_library/${id}`)
      .then(res => {
        return res
    })
  }

   /* Add planning library in coach section  */
   public async addPlanningWorkout(user, data) {
    if(user)
      return this.auth.post(`teams/${user}/activity_block`, data)
        .then(res => {
          return res
      })
  }

  async getViewType(){
    let type = "";
    if(localStorage.getItem("userRole")){
      let role = localStorage.getItem("userRole");
      if(role==='trainee' || role==='coachToTraineeView')
        type = 'athleteView'
      else
       type = 'coachView';
    }
    return type;
  }

  /* registration coach by auth token */
  async signUpCoach(data){
    return await this.http.post(`${APICONFIG.apiOauthUrl}/token.php`, data).toPromise();
  }

  /* registration coach by auth token */
   async checkCoachTeamAvailability(team){
    return await this.auth.get(`teams/availability?subdomain=${team}`)
    .then(res => {
      return res
    })
  }

  /* registration coach by auth token */
  async createCoachTeam(data){
    return await this.auth.post(`teams`, data)
    .then(res => {
      return res
    })
  }

  /* Implement my section and leaderboard api */

  async getAthleteSection(){
    return await this.auth.get(`athlete/section`)
    .then(res => {
      return res
    })
  }
 
  async getLeaderboard(){
    return await this.auth.get(`athlete/leaderboard`)
    .then(res => {
      return res
    })
  }

  async getSectionDetail(id){
    return await this.auth.get(`athlete/section/${id}`)
    .then(res => {
      return res
    })
  }

  async getLeaderboardDetail(id){
    return await this.auth.get(`athlete/leaderboard/${id}`)
    .then(res => {
      return res
    })
  }

}




export interface AthleteBattery {
  battery_level: number
}
