import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingController, ToastController, AlertController, Platform } from "@ionic/angular"
import { TranslateService } from "@ngx-translate/core"
import * as moment from "moment"
import { User } from "../../_interfaces/User"
import { ApiService } from "../../_services/api/api.service"
import { StorageService } from "../../_services/storage/storage.service"
import { toIso8601String, showDiscardDialog } from "../../_utils/utils"
import { BEATING_2P_OPTIONS } from "../../constants"
import { Location } from "@angular/common"

@Component({
  selector: "app-log-workout",
  templateUrl: "./log-workout.page.html",
  styleUrls: ["./log-workout.page.scss"],
})

export class LogWorkoutPage implements OnInit {

  static ACTIVITY = "activity"
  static NO_TRAINING = "no-training"

  user: User

  date: string

  time: string
  sport: string
  duration: string
  elevation: number
  distance: number
  recovery: boolean

  comments: string
  reason: string

  type: string

  saving: boolean
  id = 0
  illness = 0
  loading = false

  constructor(
    private toast: ToastController,
    private storage: StorageService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private location: Location,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    const id = parseInt(this.route.snapshot.queryParamMap.get("id") || "0", 10)
    const dateStr = this.route.snapshot.queryParamMap.get("date")
    const type = this.route.snapshot.queryParamMap.get("type")
    this.setThingsUp(id, dateStr, type)
  }

  async setThingsUp(id: number, dateStr: string, type: string) {
    this.id = id
    this.type = type
    this.user = await this.storage.getUser()

    // Presets
    //this.date = moment(dateStr).add(1, 'days').toDate().toISOString()
    this.date = moment(dateStr).add(12, 'hours').toDate().toISOString()
    this.duration = "01:00"
    this.sport = this.user.main_activity_type
    this.time = moment().startOf("hour").format("HH:mm")

    // Logging planned wk
    if (id !== 0) {
      this.loading = true

      const current = await this.api.getActivity(id)
      this.time = moment(current.start_date).format("HH:mm")
      this.sport = current.type

      const hrs = Math.floor(current.duration / 3600)
      const min = Math.floor((current.duration - hrs * 3600) / 60)
      this.duration = moment().hour(hrs).minute(min).format("HH:mm")

      this.distance = current.distance
      this.loading = false
    }
  }

  isActivity(): boolean {
    return this.type === LogWorkoutPage.ACTIVITY
  }

  async log() {
    if (!this.type || !this.date) {
      const tt = await this.toast.create({
        message: this.translateService.instant("DATE_MANDATORY"),
        duration: 2000
      })
      tt.present()
    }
    this.saving = true
    let params: any
    this.date = this.date.replace("22:00", this.time);
    let start_date = moment(this.date)
    start_date = start_date.subtract(2, 'hour')
    if (this.type === LogWorkoutPage.ACTIVITY) {
      const durDate = moment(this.duration, "HH:mm")
      params = {
        type: this.sport,
        start_date: toIso8601String(start_date),
        duration: durDate.hour() * 3600 + durDate.minute() * 60,
        recovery: this.recovery,
        distance: (this.distance * 1000),
        climb: this.elevation,
        comment: this.comments,
        documentation_type: "TRAINING",
      }
      if (this.id !== 0) {
        params.docid = this.id
      }
    } else {
      params = {
        type: this.reason,
        start_date: toIso8601String(start_date),
        comment: this.comments,
        documentation_type: "NO_TRAINING",
      }
      if (this.reason === "Illness") {
        params.illness = this.illness
      }
    }
    const beating2P = await this.loadingCtrl.create(BEATING_2P_OPTIONS)
    // await beating2P.present()
    // await this.api.runCalculation()
    // beating2P.dismiss()
    try {
      await this.api.logSomething(params)
      await beating2P.present()
      await this.api.runCalculation()
      beating2P.dismiss()
      this.saving = false
      const toast = await this.toast.create({
        message: this.translateService.instant("SUCCESFULLY_LOGGED"),
        duration: 2000
      })
      toast.present()
      this.router.navigate(["training-plan"], { queryParams: {
        specificDate: start_date.format("YYYY-MM-DD")
      } })
    } catch (e) {
      const toast = await this.toast.create({
        message: e["error"]["error"]["message"],
        duration: 2000
      })
      toast.present()
      this.saving = false
      return
    }
  }

  handleBack() {
    showDiscardDialog(this.alertCtrl, () => this.location.back(), () => { },this.translateService)
  }

}
