import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CoachMenuComponent } from './coach-menu.component';
import { TranslateModule } from "@ngx-translate/core"

@NgModule({
  imports: [ CommonModule, IonicModule, TranslateModule],
  declarations: [CoachMenuComponent],
  exports: [CoachMenuComponent]
})
export class CoachMenuComponentModule {}